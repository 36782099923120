import { config, useSpring } from '@react-spring/web'
import type {
    AnimationConfig,
    AnimationConfigWithPath,
    AnimationItem,
    LottiePlayer,
} from 'lottie-web'
import type { RefObject } from 'react'
import { createRef, useEffect, useRef, useState } from 'react'
import useMeasure from 'react-use-measure'
import ResizeObserver from 'resize-observer-polyfill'
import type { LiteralUnion } from 'type-fest'

import { extendConsole } from '../../shared/logging/console'
import { useFirstInteraction } from './useFirstInteraction'

export type AnimationName = LiteralUnion<'stepButtonPress', string>

type Animations = { [key in AnimationName]: any }

export type AnimationStatus =
    | 'loading'
    | 'ready'
    | 'playing'
    | 'complete'
    | 'error'

const logger = extendConsole('animation')

type Lottie = { player: LottiePlayer; animations: Animations }

const loadLottie = async (): Promise<Lottie | null> => {
    try {
        const [{ default: player }, stepButtonPress] = await Promise.all([
            import('lottie-web'),
            import('../../assets/animations/button-press.json'),
        ])
        return {
            player,
            animations: {
                stepButtonPress,
            },
        }
    } catch (e) {
        logger.warn(e, 'Failed to load animation player')
        return null
    }
}

async function loadAnimation(
    animationData: any,
    options: AnimationConfig<'svg'>
) {
    const lottie = await loadLottie()

    if (!lottie) return null

    return lottie.player.loadAnimation({
        animationData,
        ...options,
    })
}

async function loadAnimationByName(
    name: AnimationName,
    options: AnimationConfig<'svg'>
) {
    const lottie = await loadLottie()

    if (!lottie) return null

    return lottie.player.loadAnimation({
        animationData: lottie.animations[name],
        ...options,
    })
}

/** Hook to load a finite animation into the container.
 * Starts when you call play().
 * JSON for this animation is loaded lazily. Exposes animation status for easy styling.
 * Powered by Lottie. */
export function useFiniteAnimation(
    name: AnimationName,
    { resetTimeout = 100 } = {}
) {
    const container = createRef<HTMLDivElement>()
    const animation = useRef<AnimationItem | null>(null)
    const [status, setStatus] = useState<AnimationStatus>('loading')

    useEffect(() => {
        if (status === 'complete') {
            setTimeout(() => setStatus('ready'), resetTimeout)
        }
    }, [status])

    useEffect(() => {
        return () => {
            if (!animation?.current || !container.current) return
            animation.current.destroy()
        }
    }, [])

    useFirstInteraction(() => {
        if (!container.current) return

        loadAnimationByName(name, {
            autoplay: false,
            loop: false,
            container: container.current,
        }).then((a) => {
            if (!a) return
            animation.current = a
            a.addEventListener('complete', () => setStatus('complete'))
        })

        setStatus('ready')
    })

    const play = () => {
        if (!animation?.current) return
        setStatus('playing')
        animation.current.goToAndPlay(0)
    }

    return [container, play, status] as const
}

/** Hook to perform pretty dropdown animation.
 * Attach ref to the content node, that won't change sise.
 * Wrap that element into an animated.div. Pass wrapper style to that div. */
export const useDropdownAnimation = (open: boolean) => {
    const [contentRef, { height }] = useMeasure({ polyfill: ResizeObserver })

    const wrapperStyle = useSpring({
        pointerEvents: open ? 'all' : 'none',
        opacity: open ? 1 : 0,
        height: open ? height : 0,
        overflow: 'hidden',
        config: config.stiff,
    })

    return [contentRef as any, wrapperStyle]
}

/** Hook to load custom json animation into the container. Powered by Lottie. */
export const useJSONAnimation = <E extends HTMLElement>({
    path,
    ...restOfOptions
}: Partial<Omit<AnimationConfigWithPath, 'container'>>): RefObject<E> => {
    const animation = useRef<AnimationItem | null>(null)
    const container = createRef<E>()

    useEffect(() => {
        if (!path || !container.current) return

        if (animation.current) animation.current.destroy()

        loadAnimation(path, {
            container: container.current,
            ...restOfOptions,
        }).then((a) => (animation.current = a))
    }, [path])

    return container
}
