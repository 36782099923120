import type { FunctionComponent } from 'react';
import { forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import { TEXT } from '../../../lib/utils/text';
import { COUNTRY_CODE, COUNTRY_PREFIX, PHONE_FORMAT } from '../../../shared/util/phone';
import type { TextInputProps } from '../text-input';
import { TextInput } from '../text-input';

/** US telephone input with mask and formatting. */
export const TelephoneInput: FunctionComponent<React.PropsWithChildren<TelephoneInputProps>> = forwardRef<HTMLInputElement, TelephoneInputProps>(({
  size,
  placeholder = TEXT.phoneNumber,
  onFocus,
  onBlur,
  ...restOfProps
}, ref) => <NumberFormat {...restOfProps} size={size} type='tel' getInputRef={ref} prefix={COUNTRY_PREFIX} format={PHONE_FORMAT} customInput={TextInput} placeholder={placeholder} allowEmptyFormatting={false} onFocus={onFocus} onBlur={onBlur} />);
export const CountryCode: FunctionComponent = () => <>
        ({COUNTRY_CODE})<b>&nbsp;{COUNTRY_PREFIX}</b>
    </>;
export type TelephoneInputProps = Omit<TextInputProps, 'type'> & {
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};