import type { ServerResponse } from 'http'
import type { NextRouter } from 'next/router'
import type { ParsedUrlQuery } from 'querystring'

import { isBrowser } from '../../shared/util/env'
import type { LocalLink } from '../../shared/util/link'
import type { RequireProps } from '../../types/require'

export class PageError extends Error {
    constructor(
        public statusCode: number,
        public description?: string,
        message?: string
    ) {
        super(message)
    }

    /**
     * Executes `fn()` on the client and server, catching and handling RedirectErrors.
     * Will rethrow any other errors.
     */
    static async try(context: ErrorContext, fn: () => Promise<void>) {
        try {
            await fn()
        } catch (error) {
            if (error instanceof PageError && context.res) {
                context.res.statusCode = error.statusCode
            }

            if (!(error instanceof RedirectError)) {
                throw error
            }

            if (isBrowser()) {
                error.handleOnClient(
                    context as RequireProps<ErrorContext, 'router'>
                )
                return
            }

            if (context.res)
                error.handleOnServer(
                    context as RequireProps<ErrorContext, 'res'>
                )
        }
    }
}

export class NotFoundError extends PageError {
    constructor(description?: string, message?: string) {
        super(404, description, message)
    }

    toNextProps() {
        return { notFound: true } as const
    }
}

export class BadRequestError extends PageError {
    constructor(description?: string, message?: string) {
        super(400, description, message)
    }
}

export class ServerTimeoutError extends PageError {
    constructor(description?: string, message?: string) {
        super(504, description, message)
    }
}

export class ServerError extends PageError {
    constructor(
        description = 'Internal server error',
        message?: string,
        stack?: string
    ) {
        super(500, description, message)
        this.stack = stack
    }
}

export class RedirectError extends PageError {
    constructor(
        statusCode: 301 | 302,
        public link: LocalLink,
        message?: string
    ) {
        super(statusCode, undefined, message)
    }

    get permanent() {
        return this.statusCode === 301
    }

    handleOnClient({ router }: RequireProps<ErrorContext, 'router'>) {
        this.link.query = { ...router.query, ...this.link.query }
        router.replace(this.link.serialize(router))
    }

    handleOnServer({ res, query }: RequireProps<ErrorContext, 'res'>) {
        res.statusCode = this.statusCode
        this.link.query = { ...query, ...this.link.query }
        res.writeHead(this.statusCode, {
            Location: encodeURI(this.link.serialize()),
        })
        res.end()
    }

    toNextProps() {
        return {
            redirect: {
                destination: this.link.serialize(),
                permanent: this.permanent,
            },
        }
    }
}

interface ErrorContext {
    router?: NextRouter
    res?: ServerResponse
    query?: ParsedUrlQuery
}
