import type { ReferralInfo } from '@getstep/sdk/dist/http/models/Referrals';
import { when } from 'mobx';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { AppsFlyerLinker } from '../linking/AppsFlyerLinker';
import { useStores } from '../useStores';
export const useClickTracker = (props?: ClickTrackerProps) => {
  const {
    query
  } = useRouter();
  const {
    linkingStore
  } = useStores();
  const {
    feature
  } = props || {};
  useEffect(() => when(() => linkingStore.loaded, () => linkingStore.update({
    channel: query['utm_source'] as string,
    campaign: query['utm_campaign'] as string,
    feature: feature ?? query['utm_medium'] as string,
    data: AppsFlyerLinker.appsFlyerParams(query)
  }, 'feature')), [feature]);
  return null;
};
interface ClickTrackerProps {
  feature?: string;
  referralInfo?: ReferralInfo;
  params?: Record<string, unknown>;
}