import classNames from 'classnames';
import { observer } from 'mobx-react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FunctionComponent } from 'react';
import React, { useEffect, useRef } from 'react';
import { useBodyLock } from '../../../lib/hooks/useBodyLock';
import { navigationStore } from '../../../lib/store/ui/navigation';
import { LocalLink } from '../../../shared/util/link';
import { HamburgerButton } from '../../hamburger-button';
import { Logo } from '../../icons/logo';
import { Desktop, Mobile } from '../../responsive';
import { Text } from '../../text';
import { DropdownMenu } from '../dropdown-menu';
import { Container } from '../grid';
import { MAIN_NAVIGATION_LINKS } from '../navigation-links';
import HeaderActionButton from './actions/header-action-button';
import styles from './header.module.scss';
import { TeamEnvironmentBanner } from './team-env-banner';
const Sidebar = dynamic(() => import('./sidebar'), {
  ssr: false
});
export const Header: FunctionComponent<{
  hasBanner?: boolean;
}> = observer(({
  hasBanner = false
}) => {
  const outerRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const router = useRouter();

  // close mobile nav when route changes
  useEffect(() => {
    navigationStore.set({
      isOpen: false
    });
  }, [router.pathname]);

  // lock scroll when mobile nav is open
  useBodyLock(navigationStore.isOpen);

  // handle header position when banner is present
  useEffect(() => {
    const handleScroll = () => {
      if (!hasBanner || !outerRef.current || !ref.current) return;
      const outerRect = outerRef.current.getBoundingClientRect();
      const outerTop = outerRect.top + window.scrollY;
      const refDistanceToTop = outerTop - window.scrollY;
      if (refDistanceToTop > 0 && refDistanceToTop < outerTop) {
        ref.current.style.top = `${refDistanceToTop}px`;
      } else if (refDistanceToTop <= 0) {
        ref.current.style.top = '0px';
      } else {
        ref.current.style.top = `${outerTop}px`;
      }
    };
    if (hasBanner) {
      handleScroll();
      window.addEventListener('scroll', handleScroll);
    } else {
      // account for when user manually closes banner
      if (ref.current) ref.current.style.top = '0px';
      window.removeEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasBanner]);
  const handleCloseClick = () => {
    navigationStore.set({
      isOpen: false
    });
  };
  return <header ref={outerRef}>
                <TeamEnvironmentBanner />
                <div ref={ref} className={styles.wrapper} data-testid='layout.header'>
                    <nav className={styles.header}>
                        <Container>
                            <div className={styles.inner}>
                                <Link href='/' passHref>
                                    <Logo width='52' height='42' alt='Step logo' preset='color' />
                                </Link>
                                <div className={styles.right}>
                                    <Desktop visible={navigationStore.showLinks}>
                                        <div className={classNames(styles.list)}>
                                            {MAIN_NAVIGATION_LINKS.map(route => {
                    const {
                      label,
                      href,
                      items
                    } = route;
                    if (items && items?.length > 0) {
                      return <DropdownMenu key={label} id={label} items={items.map(({
                        label,
                        href
                      }) => ({
                        text: label,
                        url: new LocalLink({
                          base: href
                        })
                      }))} label={label} />;
                    }
                    const isActive = router.pathname === href;
                    return <Link key={label} href={href} passHref className={styles.link}>
                                                            <Text tag='span' typeStyle={{
                        _: 'body-2xs',
                        md: 'body-xs'
                      }} bold color={isActive ? 'primary' : 'text'}>
                                                                {label}
                                                            </Text>
                                                        </Link>;
                  })}
                                        </div>
                                    </Desktop>

                                    <div className={styles.buttons}>
                                        <div className={styles['action-button']}>
                                            <HeaderActionButton />
                                        </div>
                                        {navigationStore.showLinks && <Mobile>
                                                <HamburgerButton open={navigationStore.isOpen} onClick={() => navigationStore.set({
                    isOpen: !navigationStore.isOpen
                  })} />
                                            </Mobile>}
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </nav>

                    {navigationStore.showLinks && <Sidebar links={MAIN_NAVIGATION_LINKS} open={navigationStore.isOpen} onOverlayClick={handleCloseClick} />}
                </div>
            </header>;
});
export default Header;