import type { Config } from '@getstep/sdk/dist/http/HttpClient'
import type { RpcLoggerFactory } from '@getstep/sdk/dist/http/interceptor/LoggingInterceptor'
import { consoleRpcLoggerFactory } from '@getstep/sdk/dist/http/interceptor/LoggingInterceptor'
import { mustExist } from '@getstep/sdk/dist/util/Assert'
import type { IncomingMessage } from 'http'
import noop from 'lodash/noop'

import { inspectEnv, isBrowser } from '../../shared/util/env'

/**
 * Loads huge chunks of the SDK on demand
 */
export class SdkClientStore {
    private constructor(
        private request?: IncomingMessage,
        public teamEnvironment?: string
    ) {}

    get config(): Config {
        const { request, teamEnvironment } = this
        const baseURL = mustExist(
            process.env.NEXT_PUBLIC_STEP_API_BASE,
            `env.NEXT_PUBLIC_STEP_API_BASE base url not configured. ENV:\n${inspectEnv()}`
        )

        // forward client ip to api if call is being made during SSR
        const clientIp =
            request?.headers['x-forwarded-for'] ??
            request?.socket?.remoteAddress
        return {
            headers: clientIp ? { ['x-forwarded-for']: clientIp } : {},
            baseURL,
            metadata: {
                appVersion: process.env.npm_package_version ?? '',
                platform: 'web',
                bundleId: '',
                teamEnvironment: () => teamEnvironment,
            },
            loggerFactory: isBrowser()
                ? nullLoggerFactory
                : consoleRpcLoggerFactory,
        }
    }

    static create(request?: IncomingMessage, teamEnvironment?: string) {
        return new SdkClientStore(request, teamEnvironment)
    }

    async createHttpClient() {
        const { HttpClient } = await import('@getstep/sdk/dist/http/HttpClient')
        return new HttpClient(this.config)
    }

    async createStripeHttpClient() {
        const { StripeHttpClient } = await import(
            '@getstep/sdk/dist/http/StripeHttpClient'
        )
        return new StripeHttpClient({
            appVersion: process.env.npm_package_version ?? '',
            platform: 'web',
            bundleId: '',
        })
    }

    async createPublicApi() {
        const [{ PublicApi }, client] = await Promise.all([
            import('@getstep/sdk/dist/http/PublicApi'),
            this.createHttpClient(),
        ])

        return new PublicApi(client)
    }

    restore(state: SdkStoreState) {
        this.teamEnvironment = state.teamEnvironment
    }

    toJSON() {
        return { teamEnvironment: this.teamEnvironment }
    }
}

const nullLoggerFactory: RpcLoggerFactory = (_) => {
    return {
        request: noop,
        response: noop,
        httpError: noop,
        unexpectedError: noop,
    }
}

export interface SdkStoreState {
    teamEnvironment?: string
}
