import classNames from 'classnames';
import type { ElementType, PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import { usePersonalizations } from '../../lib/hooks/usePersonalizations';
import type { OptionallyResponsiveProp } from '../../lib/utils/responsiveProps';
import { setupResponsivePropToClass } from '../../lib/utils/responsiveProps';
import { renderTemplate } from '../../lib/utils/text';
import colorStyles from './text-colors.module.scss';
import typeStyles from './type-styles.module.scss';
export const Text = forwardRef<any, PropsWithChildren<TextProps>>(({
  color,
  typeStyle = 'body-md',
  bold = false,
  tag: Tag = 'p',
  className,
  children,
  ...rest
}, ref) => {
  const personalizations = usePersonalizations();
  const colorClass = color ? colorStyles[color] : false;
  const typeStyleClass = responsivePropToClass(typeStyle);
  const boldClass = bold ? typeStyles['bold'] : false;
  return <Tag ref={ref} className={classNames(colorClass, typeStyleClass, boldClass, className)} {...rest}>
                {typeof children === 'string' ? renderTemplate(children, personalizations) : children}
            </Tag>;
});
export interface TextProps {
  /** Color key used to append the typestyle className to the rest of classNames. */
  color?: keyof typeof colorStyles;
  /** Typestyle key used to append the typestyle className to the rest of classNames. */
  typeStyle?: OptionallyResponsiveProp<keyof typeof typeStyles>;
  /**
   * Boolean used to append the 'bold' className to the rest of classNames.
   * Note: The 'bold' option is not optionally responsive. This is on purpose.
   */
  bold?: boolean;
  /** The HTML <tag> to render in DOM. */
  tag?: ElementType;
  /** Extra classNames to text element */
  className?: string;
}
const responsivePropToClass = setupResponsivePropToClass(typeStyles);