import type { IconComponent } from '../types';

/** Company logo */
export const Logo: IconComponent<Props> = ({
  width,
  height,
  preset = 'color',
  className,
  alt,
  ...restOfProps
}) => {
  const getLogoFromPreset = (preset: Props['preset']) => {
    switch (preset) {
      case 'line':
        return <svg width={width} height={height} className={className} viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...restOfProps}>
                        {alt && <title>{alt}</title>}
                        <desc>Step line outline logo</desc>
                        <path d='M8.75051 2.62296L4.74774 5.37453L7.49931 9.37731L11.5021 6.62574L8.75051 2.62296Z' stroke='currentColor' strokeLinejoin='round' />
                        <path d='M11.5005 6.6227L7.49774 9.37427L10.2493 13.377L14.2521 10.6255L11.5005 6.6227Z' stroke='currentColor' strokeLinejoin='round' />
                        <path d='M10.2482 13.3674L7.49823 9.36743L11.4982 6.61742L10.2482 13.3674Z' stroke='currentColor' strokeLinejoin='round' />
                        <path d='M4.7507 5.37289L0.747925 8.12446L3.4995 12.1272L7.50227 9.37566L4.7507 5.37289Z' stroke='currentColor' strokeLinejoin='round' />
                        <path d='M7.49817 9.36743L3.49817 12.1174L4.74817 5.36742L7.49817 9.36743Z' stroke='currentColor' strokeLinejoin='round' />
                    </svg>;
      case 'white':
        return <svg width={width} height={height} className={className} viewBox='0 0 55 46' fill='none' xmlns='http://www.w3.org/2000/svg' {...restOfProps}>
                        {alt && <title>{alt}</title>}
                        <desc>All white Step logo</desc>
                        <path fillRule='evenodd' clipRule='evenodd' d='M9.96155 29.6468C9.15226 28.8162 8.26568 28.2656 7.15177 28.2656C6.26518 28.2656 5.72414 28.7117 5.72414 29.4C5.72414 29.9981 6.06513 30.4443 6.95171 30.862L8.20658 31.4268C10.6072 32.4996 11.8347 33.7812 11.8347 35.6893C11.8347 38.4898 9.52054 40.4882 6.32429 40.4882C3.7282 40.4882 1.41399 39.2968 0 36.9092L2.65974 34.9631C3.86913 36.3823 5.08762 37.2367 6.32429 37.2367C7.46548 37.2367 8.15202 36.7573 8.15202 36.0453C8.15202 35.4805 7.86558 35.1529 6.83805 34.6735L5.49681 34.0754C3.09622 33.0312 2.04141 31.5692 2.04141 29.5709C2.04141 26.8273 4.06919 25.0378 7.15177 25.0378C9.61601 25.0378 11.3574 26.0774 12.5577 27.7387L9.96155 29.6468Z' fill='white' />
                        <path d='M19.5229 28.7395L18.5227 34.4639C18.4954 34.6111 18.4636 34.9101 18.4636 35.1475C18.4636 36.5477 19.4911 36.9939 21.4052 36.9939C21.9463 36.9939 22.2327 36.9939 22.6055 36.9654L22.0326 40.3355C21.4916 40.4257 21.0051 40.4827 20.3186 40.4827C16.9496 40.4827 14.5762 39.0207 14.5762 35.8927C14.5762 35.3563 14.6354 35.0003 14.7172 34.4592L15.6902 28.7347H13.4896L14.0307 25.3646H16.2585L16.845 21.8996L20.8187 21.1733L20.0913 25.3646H24.1468L23.6058 28.7347H19.5229V28.7395Z' fill='white' />
                        <path d='M37.4729 37.8212C37.3365 38.0443 37.1729 38.2437 36.9819 38.4051C35.4315 39.7484 33.2128 40.655 30.7485 40.5268C26.743 40.4224 23.6922 37.0001 23.8923 32.7946C24.0651 29.1444 26.6384 26.0828 29.9347 25.2711C33.6902 24.5117 37.1683 26.1587 37.9549 30.3216C38.1231 31.2234 38.1004 32.13 37.9139 33.0081L27.9569 34.8404C28.3161 35.8229 29.2573 37.0665 31.6488 37.0665C33.4401 37.0665 34.945 36.2169 35.8907 35.4574L37.4729 37.8212ZM34.2585 30.6158C33.913 28.5226 32.4308 28.0052 30.5167 28.3612C28.6026 28.7124 27.6205 30.3405 27.5796 31.8452L34.2585 30.6158Z' fill='white' />
                        <path fillRule='evenodd' clipRule='evenodd' d='M48.8303 25.0468C52.3721 25.0468 55 28.0277 55 32.0528C55 36.8232 51.7174 40.4924 47.43 40.4924C45.4022 40.4924 43.8018 39.7187 42.8879 38.3754L41.6876 45.5001H37.8322L41.1875 25.6069L44.8384 24.9329L44.5156 26.9264C45.5431 25.7303 47.0572 25.0468 48.8303 25.0468ZM47.1435 36.8802C49.3441 36.8802 51.0581 34.9435 51.0581 32.4373C51.0581 30.2016 49.6578 28.6495 47.6573 28.6495C45.4295 28.6495 43.7427 30.5861 43.7427 33.1825C43.7473 35.3612 45.143 36.8802 47.1435 36.8802Z' fill='white' />
                        <path d='M22.735 5.67689L27.428 12.6881L34.233 7.86123L29.54 0.850055L22.735 5.67689Z' fill='white' />
                        <path d='M27.4248 12.6843L32.1178 19.6954L38.9228 14.8686L34.2298 7.85743L27.4248 12.6843Z' fill='white' />
                        <path d='M15.9337 10.5005L20.6267 17.5117L27.4317 12.6848L22.7387 5.67365L15.9337 10.5005Z' fill='white' />
                        <path d='M22.735 5.67689L27.428 12.6881L34.233 7.86123L29.54 0.850055L22.735 5.67689Z' stroke='#121314' strokeLinejoin='round' />
                        <path d='M27.4248 12.6843L32.1178 19.6954L38.9228 14.8686L34.2298 7.85743L27.4248 12.6843Z' stroke='#121314' strokeLinejoin='round' />
                        <path d='M15.9337 10.5005L20.6267 17.5117L27.4317 12.6848L22.7387 5.67365L15.9337 10.5005Z' stroke='#121314' strokeLinejoin='round' />
                    </svg>;
      case 'color':
      default:
        return <svg width={width} height={height} className={className} viewBox='0 0 65 50' fill='none' xmlns='http://www.w3.org/2000/svg' {...restOfProps}>
                        {alt && <title>{alt}</title>}
                        <desc>
                            Step logo with colored accents and white text
                        </desc>
                        <path d='M11.633 32.273c-.946-.933-1.98-1.54-3.28-1.54-1.033 0-1.666.5-1.666 1.267 0 .666.4 1.166 1.433 1.633l1.467.633c2.8 1.2 4.233 2.634 4.233 4.767 0 3.133-2.7 5.367-6.433 5.367-3.034 0-5.734-1.334-7.38-4l3.106-2.174c1.414 1.587 2.834 2.54 4.28 2.54 1.334 0 2.134-.533 2.134-1.333 0-.633-.334-1-1.534-1.533l-1.566-.667c-2.8-1.166-4.034-2.8-4.034-5.033 0-3.067 2.367-5.067 5.967-5.067 2.88 0 4.913 1.16 6.313 3.02l-3.04 2.12z' fill='var(--theme-text)' />
                        <path d='M22.787 31.267l-1.167 6.4c-.033.166-.067.5-.067.766 0 1.567 1.2 2.067 3.434 2.067.633 0 .966 0 1.4-.033l-.667 3.766c-.633.1-1.2.167-2 .167-3.933 0-6.7-1.633-6.7-5.133 0-.6.067-1 .167-1.6l1.133-6.4h-2.567l.634-3.767h2.6l.68-3.873 4.633-.814-.853 4.687h4.733l-.633 3.767h-4.76zM43.74 41.413a2.71 2.71 0 01-.574.653c-1.813 1.5-4.4 2.514-7.273 2.374-4.673-.114-8.24-3.94-8.006-8.647.206-4.08 3.2-7.5 7.053-8.413 4.387-.847 8.447.993 9.36 5.646a7.249 7.249 0 01-.053 3.007l-11.62 2.053c.42 1.1 1.52 2.487 4.313 2.487 2.087 0 3.846-.947 4.953-1.8l1.847 2.64zm-3.747-8.053c-.4-2.34-2.133-2.92-4.367-2.52-2.233.393-3.38 2.213-3.433 3.893l7.8-1.373z' fill='var(--theme-text)' />
                        <path d='M57 27.133c4.133 0 7.2 3.334 7.2 7.833 0 5.334-3.834 9.434-8.834 9.434-2.366 0-4.233-.867-5.3-2.367L48.666 50h-4.5l3.914-22.24 4.266-.754-.38 2.227c1.2-1.333 2.967-2.1 5.034-2.1zm-1.967 13.233c2.567 0 4.567-2.166 4.567-4.966 0-2.5-1.634-4.234-3.967-4.234-2.6 0-4.567 2.167-4.567 5.067 0 2.433 1.634 4.133 3.967 4.133z' fill='var(--theme-text)' />
                        <path d='M26.778 5.33l5.412 7.337 7.448-5.33L34.226 0l-7.448 5.33z' fill='#16B4DC' />
                        <path d='M32.186 12.663L37.598 20l7.448-5.33-5.411-7.337-7.449 5.33z' fill='#fcd411' />
                        <path d='M19.333 10.658l5.412 7.336 7.449-5.33-5.412-7.337-7.449 5.33z' fill='#F6629D' />
                        <path d='M26.78 5.333l-2.034 12.663-5.411-7.333 7.445-5.33z' fill='#FFCCE8' />
                        <path d='M39.636 7.337l5.411 7.333L37.602 20l2.034-12.663z' fill='#FCF2B7' />
                    </svg>;
    }
  };
  return getLogoFromPreset(preset);
};
interface Props {
  /**
   * Affects icon design.
   * @default 'color'
   */
  preset?: 'color' | 'line' | 'white';
}