import classNames from 'classnames';
import type { InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { tracker } from '../../../lib/store/tracker/useTracker';
import type { LabelProps } from './label';
import { Label } from './label';
import { PRESETS } from './presets';

/** Home for all text-based inputs. */
export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({
  analyticsKey,
  preset = 'rounded',
  type = 'text',
  adornment,
  label,
  valid,
  error,
  size,
  validationCount,
  className,
  'data-testid': testId,
  onFocus,
  name,
  fullWidth,
  ...restOfProps
}, ref) => {
  const styles = PRESETS[preset];
  return <Label size={size} preset={preset} adornment={adornment} validationCount={validationCount} valid={valid} error={error} label={label} data-testid={testId} className={className} fullWidth={fullWidth}>
                <input aria-invalid={!valid} type={type} ref={ref} name={name} onFocus={e => {
      const inputKey = analyticsKey ?? name;
      if (inputKey) tracker.inputFocused(inputKey);
      onFocus?.(e);
    }} className={classNames(styles.input, styles[`input-${size}`], {
      [styles['input-with-adornment']]: adornment,
      [styles['input-with-error']]: !valid
    })} {...restOfProps} />
            </Label>;
});
TextInput.displayName = 'TextInput';

/** Core input for all text-based inputs */
export type TextInputProps = LabelProps & Pick<InputHTMLAttributes<HTMLInputElement>, 'alt' | 'autoComplete' | 'autoFocus' | 'disabled' | 'inputMode' | 'maxLength' | 'minLength' | 'name' | 'onBlur' | 'onChange' | 'onChangeCapture' | 'onFocus' | 'pattern' | 'placeholder' | 'readOnly' | 'required' | 'data-testid'> & {
  /**
   * Type of input usually affects design, autocomplete and keyboard type.
   */
  type?: 'text' | 'url' | 'tel' | 'search' | 'password' | 'email' | 'color';

  /**
   * Value for controlled version.
   */
  value?: string;

  /**
   * Default value for uncontrolled version.
   */
  defaultValue?: string;

  /**
   * Identifier for analytics. If not provided, name will be used.
   */
  analyticsKey?: string;
};