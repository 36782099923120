import { useEffect, useState } from 'react'

export type CookieBot = {
    consent: {
        preferences: boolean
        statistics: boolean
        marketing: boolean
    }
    doNotTrack: boolean
    hasResponse: boolean
    hide(): void
    renew(): void
    runScripts(): void
    show(): void
    submitCustomConsent(
        optinPreferences?: boolean,
        optinStatistics?: boolean,
        optinMarketing?: boolean
    ): void
}

export const useCookieConsent = (
    callback: (consent: CookieBot['consent']) => void
) => {
    const [, setConsent] = useState<CookieBot['consent'] | undefined>()

    const handleConsentChange = () => {
        const newConsent = { ...window.Cookiebot?.consent }
        setConsent(newConsent)

        callback(newConsent)
    }

    useEffect(() => {
        // add listeners for consent changes
        window.addEventListener('CookiebotOnAccept', handleConsentChange)
        window.addEventListener('CookiebotOnDecline', handleConsentChange)

        // if consent already exists on first load, call the callback to handle it
        // this handles the the case where the event listeners are added after
        // the consent script has already been loaded
        if (window?.Cookiebot?.consent) {
            handleConsentChange()
        }

        return () => {
            window.removeEventListener('CookiebotOnAccept', handleConsentChange)
            window.removeEventListener(
                'CookiebotOnDecline',
                handleConsentChange
            )
        }
    }, [])
}
