import type { ReferralInfo } from '@getstep/sdk/dist/http/models/Referrals'
import { asPossessive } from '@getstep/sdk/dist/util/Names'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { computed, observable, reaction, runInAction } from 'mobx'
import type { DeepPartial } from 'react-hook-form'

import { extendConsole } from '../../../shared/logging/console'
import { LINK } from '../../../shared/util/link'
import { formatAsAmount } from '../../utils/number'
import { TEXT } from '../../utils/text'
import type { DeviceStore } from '../DeviceStore'
import {
    makePersistentObservable,
    PersistentStore,
} from '../persistence/PersistentStore'
import { tracker } from '../tracker/useTracker'
import type { AppLinker } from './AppLinker'
import { nullLinker } from './NullLinker'
import type { AppLinkParameters, SMSTemplate } from './parameters'
import { DEFAULT_PARAMETERS, mergeParameters } from './parameters'

const logger = extendConsole('LinkingStore')

/**
 * Generates personalized app links, stores them, and sends SMS.
 * The parameters are stored in LocalStorage.
 */
export class LinkingStore extends PersistentStore {
    static readonly className = 'LinkingStore'
    static readonly persistentFields = ['personalizedLink', 'parameters']

    linker: AppLinker = nullLinker

    loaded = false

    personalizedLink?: string

    parameters: AppLinkParameters = DEFAULT_PARAMETERS

    constructor(private deviceStore: DeviceStore) {
        super()
        makePersistentObservable(this, {
            defaultLink: computed,
            appDownloadLink: computed,
            data: computed,
            loaded: observable,
            hasLinker: computed,
            linker: observable,
        })
    }

    /**
     * Returns the link for the current user.
     * If the linker is unavailable, returns the default non-personalized link.
     */
    get appDownloadLink() {
        if (!this.defaultLink) return
        // for some magic voodoo reason, this line fixes reconciliaiton issues with header button
        // my guess is that it's because branch linker loads faster than the button,
        // so when react is reconciling, the link is already changed outside the render cycle
        // just don't remove this ok
        if (!this.hasLinker) return this.defaultLink
        return this.personalizedLink ?? this.defaultLink
    }

    /**
     * Link data with defaults applied.
     */
    get data() {
        return this.parameters.data
    }

    /**
     * Returns true if linking provider has been assigned.
     */
    get hasLinker() {
        return this.linker !== nullLinker
    }

    /**
     * Picks the right non-personalized link for the platform.
     */
    get defaultLink(): string | void {
        const { os, userAgent } = this.deviceStore
        if (!os) return
        if (os === 'Android') return LINK.app.GooglePlay()
        if (os === 'iOS') return LINK.app.AppStore()
        logger.error(`No download link for userAgent`, userAgent)
        // We're on desktop, unsupported mobile OS, or something's wrong with the sniffer.
        return
    }

    /**
     * Values for PersonalizationProvider.
     */
    getPersonalizations(mounted = false) {
        const data = mounted ? this.data : DEFAULT_PARAMETERS.data

        return {
            ...data,
            amountFormatted: formatAsAmount(
                data['amount_value'],
                data['amount_currency']
            ),
            expiresAt: TEXT.after7Days,
            sponseeName: data['sponsee_full_name'],
            pictureUrl: data['picture_url'],
            sponseeNamePossessive: asPossessive(
                data['sponsee_full_name'] ??
                    DEFAULT_PARAMETERS.data['sponsee_full_name']
            ),
            firstName: data['sender_first_name'],
            fullName: data['sender_full_name'],
            name: data['sender_full_name'],
            phoneSuffix: data['sender_phone_suffix'],
            maskedCardNumber: '',
            referredAwardFormatted: data['referred_award_formatted'],
        }
    }

    /**
     * Swaps the linking provider for a different one.
     */
    setLinker = async (linker: AppLinker) => {
        await runInAction(async () => {
            if (linker === this.linker) return
            this.loaded = false
            this.linker = linker
            await this.load()
            logger.debug('changed linker', linker)
        })
    }

    /**
     * Texts the parametrized link to the phoneNumber
     */
    sendDownloadLinkInSMS = async (
        phoneNumber: string,
        { customMessage, templateId }: SMSOptions = {}
    ) => {
        tracker.identify({ phone: phoneNumber }).then()
        await this.updateUserId(tracker.userId)
        await this.linker.sendSMS(
            phoneNumber,
            mergeParameters(this.parameters, {
                data: {
                    $custom_sms_text: customMessage,
                },
                templateId,
            }),
            !!customMessage
        )

        tracker.sendSMS(phoneNumber, customMessage).then()
    }

    /**
     * Updates the link with the desktop url.
     */
    updateCanonicalUrl = async (url: string) => {
        await this.update(
            {
                data: {
                    $fallback_url: url,
                    $desktop_url: url,
                    $canonical_url: url,
                },
            },
            'canonical'
        )
    }

    /**
     * Updates the link with the userId.
     */
    updateUserId = async (userId?: string) => {
        await this.update({ data: { segment_user_id: userId } }, 'user')
    }

    /**
     * Updates the link with the referral code.
     */
    updateReferralInfo = async (
        code: string,
        info?: ReferralInfo,
        options: { expedited_onboarding?: boolean } = {}
    ) => {
        const { referredAward: award, firstName, fullName } = info ?? {}

        await this.update(
            {
                data: {
                    ...info,
                    ...options,
                    code,
                    sender_first_name: firstName,
                    sender_full_name: fullName,
                    referred_award_formatted:
                        award && formatAsAmount(award.value, award.currency),
                },
            },
            'referral'
        )
    }

    /**
     * Updates the link with the given parameters.
     */
    update = async (
        input: DeepPartial<AppLinkParameters>,
        cause?:
            | 'initial'
            | 'referral'
            | 'user'
            | 'feature'
            | 'sms'
            | 'canonical'
    ) => {
        const oldParameters = this.parameters
        const newParameters = mergeParameters(input, oldParameters)

        if (this.deviceStore.mobile) {
            newParameters.data['$fallback_url'] = undefined
        }

        logger.debug(
            cause,
            'update',
            cloneDeep({ oldParameters, newParameters })
        )

        if (isEqual(newParameters, oldParameters) && cause !== 'initial') return

        this.parameters = newParameters
        this.personalizedLink =
            (await this.linker.link(newParameters)) || undefined

        logger.debug('update link', this.personalizedLink, { newParameters })

        this.persist()
    }

    load = async () => {
        if (this.loaded || !this.hasLinker) return

        await runInAction(async () => {
            const { linker } = this

            const parameters = await linker.init()
            if (parameters) await this.update(parameters, 'initial')
            this.loaded = true
        })

        reaction(
            () => tracker.userId,
            (userId) => this.updateUserId(userId)
        )
    }
}

interface SMSOptions {
    customMessage?: string
    templateId?: SMSTemplate
}
