import classNames from 'classnames'

export interface ResponsiveProp<T> {
    xl?: T
    lg?: T
    md?: T
    sm?: T
    xs?: T
    _: T
}

export type OptionallyResponsiveProp<T> = T | ResponsiveProp<T>

type classNameTemplateType = (value: string | number) => string

export class ResponsivePropError extends Error {
    constructor(propValue: OptionallyResponsiveProp<any>) {
        super(
            `No default value defined in responsive prop: ${JSON.stringify(
                propValue
            )}`
        )
        this.name = 'ResponsivePropError'
    }
}

const defaultClassNameTemplate: classNameTemplateType = (value) =>
    value.toString()

export const setupResponsivePropToClass = (classes: Record<string, string>) => {
    // map an optionally responsive prop to responsive classnames. if prop is non-responsive, return the undecorated classname
    return (
        prop: OptionallyResponsiveProp<any>,
        classNameTemplate = defaultClassNameTemplate
    ): string | false => {
        if (!prop) return false
        if (prop._ || prop.xs || prop.sm || prop.md || prop.lg || prop.xl) {
            if (prop._ === undefined) throw new ResponsivePropError(prop)
            return classNames(
                prop._ !== undefined && classes[classNameTemplate(prop._)],
                prop.xs !== undefined &&
                    classes[`${classNameTemplate(prop.xs)}--xs`],
                prop.sm !== undefined &&
                    classes[`${classNameTemplate(prop.sm)}--sm`],
                prop.md !== undefined &&
                    classes[`${classNameTemplate(prop.md)}--md`],
                prop.lg !== undefined &&
                    classes[`${classNameTemplate(prop.lg)}--lg`],
                prop.xl !== undefined &&
                    classes[`${classNameTemplate(prop.xl)}--xl`]
            )
        }
        return classes[classNameTemplate(prop)]
    }
}
