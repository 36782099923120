import { mustExist } from '@getstep/sdk/dist/util/Assert';
import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';
import { useDidMount } from '../hooks/useDidMount';
import { PersonalizationProvider } from '../hooks/usePersonalizations';
import { LinkingLoader } from './LinkingLoader';
import type { RootStoreState } from './RootStore';
import { RootStore } from './RootStore';
import { SessionContext } from './session/context';
const RootStoreContext = createContext<RootStore | undefined>(undefined);

/**
 * Linking for the server.
 */
export const RootStoreProvider: FunctionComponent<{
  value?: RootStore | RootStoreState;
  children: ReactNode;
}> = ({
  children,
  value = {}
}) => {
  const mounted = useDidMount();
  const outer = useContext(RootStoreContext);
  const [store] = useState(() => {
    if (outer) return outer;
    if (value instanceof RootStore) return value;
    return RootStore.fromState(value);
  });
  const {
    linkingStore,
    sdk
  } = store;
  return <RootStoreContext.Provider value={store} data-sentry-element="unknown" data-sentry-component="RootStoreProvider" data-sentry-source-file="useStores.tsx">
            <PersonalizationProvider value={store.linkingStore.getPersonalizations(mounted)} data-sentry-element="PersonalizationProvider" data-sentry-source-file="useStores.tsx">
                {mounted && <LinkingLoader store={linkingStore} sdk={sdk} />}
                {children}
            </PersonalizationProvider>
        </RootStoreContext.Provider>;
};
export const useStores = () => {
  const rootStore = useContext(RootStoreContext);
  return mustExist(rootStore, "RootStore is not initialized, make sure you're calling this from within RootStoreContext.Provider and that you're not trying to use it from SSG page");
};

/**
 * Returns session-specific stores.
 * Must be called within withSession(...) from "./withSession.tsx"
 */
export const useSession = () => {
  const session = useContext(SessionContext);
  return mustExist(session, "Session is not initialized, make sure you're calling this from within SessionProvider");
};

/**
 * Returns stores for the logged in user.
 * Must be called within authorized(...) from "./authorized.tsx"
 */
export const useAuthorizedStores = () => {
  const {
    authorizedStore
  } = useSession();
  return mustExist(authorizedStore, "AuthorizedStore is not available, make sure you're logged in and calling this from within SessionProvider");
};