import { useRouter } from 'next/router';
import type { FunctionComponent } from 'react';
import { DownloadApp } from '../../../contentful-components/forms/download-app';
import { isFundingPage } from '../../../lib/utils/page';
import { TEXT } from '../../../lib/utils/text';
import { Logo } from '../../icons/logo';
import styles from './footer-cta.module.scss';

/*
 * This CTA is appended above the footer links on every page.
 */
export const FooterCta: FunctionComponent = () => {
  const router = useRouter();
  const isFundingFlow = isFundingPage(router.pathname);
  return <div className={styles.container} data-sentry-component="FooterCta" data-sentry-source-file="footer-cta.tsx">
            <div className={styles.logoContainer}>
                <Logo className={styles.logo} alt='Step logo' width='150' height='116' preset='color' data-sentry-element="Logo" data-sentry-source-file="footer-cta.tsx" />
            </div>
            <div className={styles.headingContainer}>
                <h3 data-test='footerHeading'>Banking That Builds Credit</h3>
            </div>
            {!isFundingFlow && <div className={styles.downloadApp}>
                    <DownloadApp title={TEXT.getStarted} forceExpanded placement='section' preset='futureFuscia' />
                </div>}
        </div>;
};