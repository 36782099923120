import { observer } from 'mobx-react';
import type { ComponentType } from 'react';
import { useMemo } from 'react';
import { navigationStore } from '../../../../lib/store/ui/navigation';
import { DownloadStepAction } from './download-step-action';
import { FundingSkipAction } from './funding-skip-action';
import { HelpCenterAction } from './help-center-action';
import { PayOnStepAction } from './pay-on-step-action';
export type HeaderActionType = 'PAY_ON_STEP' | 'FUNDING_SKIP' | 'FUNDING_SKIP_WITH_WARNING' | 'HELP_CENTER' | 'DOWNLOAD_STEP' | 'NONE';
const headerActions: Record<HeaderActionType, ComponentType> = {
  PAY_ON_STEP: PayOnStepAction,
  FUNDING_SKIP: FundingSkipAction,
  FUNDING_SKIP_WITH_WARNING: () => <FundingSkipAction warnBeforeSkip />,
  HELP_CENTER: HelpCenterAction,
  DOWNLOAD_STEP: DownloadStepAction,
  NONE: () => null
};
const HeaderActionButton = observer(() => {
  const ActionComponent = useMemo(() => {
    return headerActions[navigationStore?.action ?? 'DOWNLOAD_STEP'];
  }, [navigationStore?.action]);
  return <ActionComponent />;
});
export default HeaderActionButton;