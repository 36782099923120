import Cookies from 'cookies'
import type { NextPageContext } from 'next'

export const getOrConfigureTeamEnvironment = (
    context: NextPageContext
): string | undefined => {
    const { req, res, query } = context
    if (!req || !res) return
    const cookies = new Cookies(req, res)
    const teamEnvironment = query[TEAM_ENVIRONMENT_QUERY_PARAM]
    if (teamEnvironment && typeof teamEnvironment === 'string') {
        cookies.set(TEAM_ENVIRONMENT_COOKIE, teamEnvironment, {
            httpOnly: false,
        })
        return teamEnvironment
    }
    return cookies.get(TEAM_ENVIRONMENT_COOKIE)
}

export const TEAM_ENVIRONMENT_COOKIE = 'X-Step-Environment'
export const TEAM_ENVIRONMENT_QUERY_PARAM = 'team-env'
