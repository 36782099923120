export enum PaymentIntentStage {
    // The user is filling the card and contact information.
    Input = 'input',
    // The user is shown a confirmation screen.
    Confirmation = 'confirmation',
    // We're processing the payment, and the user is shown a loading screen.
    Payment = 'payment',
    // The payment was successful. Yay us. Success screen is shown.
    Success = 'success',
    // Something went wrong with the payment.
    Fail = 'fail',
    // The 3DSecure flow is complete, but we dont know if the payment was successful.
    Complete = 'complete',
}

export const INTENT_STAGE_REGEX = new RegExp(
    Object.values(PaymentIntentStage).join('|')
)
