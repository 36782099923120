import classNames from 'classnames';
import type { FunctionComponent } from 'react';
import type { BrandColor } from '../../types/color';
import styles from './spinner.module.scss';

/** Displays a colourful (or not, up to you) spinner,
 * so loading stuff is less boring for the user.
 * If you know the size and form of your content, use skeleton instead.  */
export const Spinner: FunctionComponent<React.PropsWithChildren<Props>> = ({
  size = 'medium',
  color = 'multi',
  ...restOfProps
}) => <>
        &nbsp;
        <div data-testid='progress.wrapper' aria-busy='true' title='Loading…' className={classNames(styles.spinner, styles[`spinner-${size}`])} {...restOfProps}>
            <svg className={styles.inner} viewBox='25 25 50 50' data-sentry-element="svg" data-sentry-source-file="index.tsx">
                <circle data-testid='progress.circle' className={classNames(styles.circle, styles[`circle-${color}`])} fill='none' cx='50' cy='50' r='20' data-sentry-element="circle" data-sentry-source-file="index.tsx" />
            </svg>
        </div>
    </>;
interface Props {
  /** Color of the spinner. `multi` option rotates primary brand colors. */
  color?: BrandColor | 'multi';
  size?: 'small' | 'medium' | 'large';
}