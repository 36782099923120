import type { Analytics } from '@segment/analytics-next'
import { makeObservable, observable, runInAction } from 'mobx'

export class AnalyticsStore {
    readonly className = 'AnalyticsStore'

    analytics?: Analytics

    loaded = false

    private constructor() {
        makeObservable(this, {
            loaded: observable,
            analytics: observable,
        })
    }

    get anonymousId(): string | undefined {
        return this.analytics?.user()?.anonymousId()?.toString()
    }

    static create(): AnalyticsStore {
        return new AnalyticsStore()
    }

    async load(consent = false) {
        if (!process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY) return

        // dont reload analytics if it's already loaded with the same consent
        if (consent && this.analytics?.integrations?.All === true) return

        const { AnalyticsBrowser } = await import('@segment/analytics-next')

        const [analytics] = await AnalyticsBrowser.load(
            {
                writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
            },
            {
                integrations: {
                    All: !!consent,
                },
            }
        )

        runInAction(() => {
            this.analytics = analytics
            this.loaded = true
        })
    }
}
