import type { KycStatus } from '@getstep/proto/common'
import type { IntercomScreen } from '@getstep/sdk/dist/store/intercom/Screens'
import { INTERCOM_SCREENS } from '@getstep/sdk/dist/store/intercom/Screens'

import type { ArticleKind } from '../../../graphql/hooks/article-page'
import type { PaymentIntentStage } from '../../../lib/store/p2p/PaymentIntentStage'
import { FundingStage, userIdToParam } from '../../../lib/utils/funding'
import { compact } from '../../../lib/utils/omit'
import { PAGE_OPTIONS } from '../../../lib/utils/page'
import type { QRCodeExtension } from '../../../types/image'
import type { SponsorshipOfferOptions } from '../../../types/sponsorship'
import type { SsoType } from '../../../types/sso'
import { LocalLink } from './local-link'

export const WEBVIEW_PREFIX = '/webview'

const makeWebviewLink = (link: string, isWebview = false): string =>
    isWebview ? `${WEBVIEW_PREFIX}${link}` : link

export const LINK = {
    admin: {
        user: (id: string) => `https://admin.dev.step.com/user/${id}`,
    },
    help: (screen: IntercomScreen) => {
        const base = 'https://help.step.com'
        const config = INTERCOM_SCREENS[screen]

        if ('articleId' in config) {
            return `${base}/articles/${config.articleId}`
        }

        if ('collectionId' in config) {
            return `${base}/collections/${config.collectionId}`
        }

        return base
    },
    profile: (username: string): LocalLink =>
        new LocalLink({ base: `/$/${username}` }),
    qrcode: (username: string, extension: QRCodeExtension): LocalLink =>
        new LocalLink({ base: `/$/${username}/qrcode.${extension}` }),
    home: (): LocalLink => new LocalLink({ base: '/' }),
    about: (): LocalLink => new LocalLink({ base: '/about' }),
    benefits: (variation: string): LocalLink =>
        new LocalLink({
            base: `/benefits/${variation}`,
        }),
    accountAgreement: (): LocalLink =>
        new LocalLink({ base: '/accountagreement' }),
    careers: (): LocalLink => new LocalLink({ base: '/careers' }),
    job: (id: number): LocalLink =>
        new LocalLink({
            base: `/careers/${id}`,
            route: '/careers/[id]',
        }),
    login: (next?: string): LocalLink =>
        new LocalLink({ base: '/login', query: next ? { next } : {} }),
    verifyOTP: (next?: string): LocalLink =>
        new LocalLink({ base: '/login/verify', query: next ? { next } : {} }),
    sso: (type?: SsoType, next?: string): LocalLink =>
        new LocalLink({
            base: type ? `/sso/${type}` : '/sso',
            query: next ? { next } : {},
        }),
    verifySSO: (type: SsoType, next?: string): LocalLink =>
        new LocalLink({
            base: `/sso/${type}/verify`,
            query: next ? { next } : {},
        }),
    verifySSOResult: (
        type: SsoType,
        result: 'success' | 'failed' | 'loading',
        code?: string
    ): LocalLink =>
        new LocalLink({
            base: `/sso/${type}/verify/${result}`,
            query: code ? { code } : {},
        }),
    kyc: (): LocalLink => new LocalLink({ base: '/identity' }),
    kycCheck: (): LocalLink => new LocalLink({ base: '/identity/check' }),
    kycResult: (result: KycStatus): LocalLink =>
        new LocalLink({ base: `/identity/${result.toLowerCase()}` }),
    eca: (): LocalLink => new LocalLink({ base: '/eca' }),
    faq: (): LocalLink => new LocalLink({ base: '/faq' }),
    mobileTerms: (): LocalLink => new LocalLink({ base: '/mobileterms' }),
    coppa: (): LocalLink =>
        new LocalLink({ base: '/policies/coppa', route: '/policies/[slug]' }),
    privacy: (): LocalLink =>
        new LocalLink({ base: '/policies/privacy', route: '/policies/[slug]' }),
    policy: (slug = '', isWebview = false): LocalLink =>
        new LocalLink({
            base: makeWebviewLink(`/policies/${slug}`, isWebview),
            route: '/policies/[slug]',
        }),
    policyAttachment: (slug = ''): LocalLink =>
        new LocalLink({
            base: `/policies/pdf/${slug}`,
            route: '/policies/pdf/[slug]',
        }),
    policies: (isWebview = false): LocalLink => {
        return new LocalLink({
            base: makeWebviewLink('/policies/', isWebview),
        })
    },
    press: (): LocalLink => new LocalLink({ base: '/press' }),
    security: (): LocalLink => new LocalLink({ base: '/security' }),
    stepSquad: (): LocalLink => new LocalLink({ base: '/stepsquad' }),
    sponsorRequest: (isWebApprovalFlow = false, inviteId?: string): LocalLink =>
        new LocalLink({
            base: '/sponsorrequest',
            query: compact({
                invite_id: inviteId,
                flow: isWebApprovalFlow ? 'web' : undefined,
            }),
        }),
    sponsorRequestImage: (inviteId: string): LocalLink =>
        new LocalLink({
            base: `/sponsorrequest/${encodeURIComponent(inviteId)}/image.png`,
            route: '/sponsorrequest/[id]/image.png',
        }),
    sponsorshipOffer: (options: SponsorshipOfferOptions): LocalLink =>
        new LocalLink({
            base: `/sponsorshipoffer`,
            query: options,
        }),
    terms: (): LocalLink => new LocalLink({ base: '/terms' }),
    cms: ({ generalizedPath = '', anchor = '', path = '' }): LocalLink =>
        new LocalLink({
            hash: anchor && `#${anchor}`,
            base: path,
            route: generalizedPath || undefined,
        }),
    money101: (isWebview = false): LocalLink =>
        new LocalLink({
            base: makeWebviewLink('/money-101/', isWebview),
            route: '/money-101',
        }),
    money101Topic: (slug: string, isWebview = false): LocalLink =>
        new LocalLink({
            base: makeWebviewLink(`/money-101/${slug}`, isWebview),
            route: '/money-101/[slug]',
        }),
    money101Post: (slug: string, isWebview = false): LocalLink =>
        new LocalLink({
            base: makeWebviewLink(`/money-101/post/${slug}`, isWebview),
            route: '/money-101/post/[slug]',
        }),
    money101Video: (slug: string, isWebview = false): LocalLink =>
        new LocalLink({
            base: makeWebviewLink(`/money-101/video/${slug}`, isWebview),
            route: '/money-101/video/[slug]',
        }),
    pressPost: (slug: string, isWebview = false): LocalLink =>
        new LocalLink({
            base: makeWebviewLink(`/press/${slug}`, isWebview),
            route: '/press/[slug]',
        }),

    post: (slug: string, kind: ArticleKind, isWebview = false): LocalLink => {
        if (kind === 'Money 101') return LINK.money101Post(slug, isWebview)
        if (kind === 'Policy') return LINK.policy(slug, isWebview)
        if (kind === 'Video') return LINK.money101Video(slug, isWebview)

        return LINK.pressPost(slug, isWebview)
    },
    referral: (code: string): LocalLink =>
        new LocalLink({
            base: `/r/${code.toUpperCase()}`,
            route: '/r/[code]',
        }),
    referralImage: (code: string): LocalLink =>
        new LocalLink({
            base: `/r/${code.toUpperCase()}/image.png`,
            route: '/r/[code]/image.png',
        }),
    offers: (code: string): LocalLink =>
        new LocalLink({
            base: `/o/${code.toUpperCase()}`,
            route: '/o/[code]',
        }),
    features: (name: string): LocalLink =>
        new LocalLink({
            base: `/features/${name.toLowerCase()}`,
            route: '/features/[name]',
        }),
    p2pImage: (transferId: string) =>
        new LocalLink({
            base: `/tr/${transferId}/image.png`,
        }),
    sendMoney: (transferId?: string) =>
        new LocalLink({
            base: `/${PAGE_OPTIONS.SEND_MONEY.slug}`,
            query: transferId ? { tr: transferId } : undefined,
        }),
    requestMoney: (transferId?: string) =>
        new LocalLink({
            base: `/${PAGE_OPTIONS.REQUEST_MONEY.slug}`,
            query: transferId ? { tr: transferId } : undefined,
        }),
    pay: (transferId: string, flow: string) =>
        new LocalLink({
            base: `/pay/${transferId}`,
            query: flow ? { flow } : undefined,
        }),
    paymentIntent: (transferId: string, stage?: PaymentIntentStage) =>
        new LocalLink({
            base: stage ? `/pay/${transferId}/${stage}` : `/pay/${transferId}`,
            query: { flow: 'direct' },
        }),
    profilePay: (username: string, stage?: PaymentIntentStage) =>
        new LocalLink({
            base: stage ? `/$/${username}/pay/${stage}` : `/$/${username}/pay`,
        }),
    funding: (userId?: string, stage: FundingStage = FundingStage.Amount) => {
        if (!userId) {
            return LINK.fundingExpired()
        }

        return new LocalLink({
            base: `/funding/${userIdToParam(userId)}/${stage}`,
        })
    },
    fundingExpired: () => {
        return new LocalLink({
            base: `/funding/expired`,
        })
    },
    schoolRequest: (status?: 'success' | 'fail'): LocalLink => {
        if (!status) {
            return new LocalLink({
                base: `/${PAGE_OPTIONS.SCHOOL_REQUEST.slug}`,
            })
        }

        return new LocalLink({
            base:
                status === 'success'
                    ? `/${PAGE_OPTIONS.SCHOOL_REQUEST_WAITLIST_SUCCESS.slug}`
                    : `/${PAGE_OPTIONS.SCHOOL_REQUEST_WAITLIST_FAIL.slug}`,
        })
    },
    rewardsPage: (): LocalLink => new LocalLink({ base: '/rewards' }),

    mail: {
        hello: 'mailto:hello@step.com',
        support: 'mailto:support@step.com',
    },

    social: {
        Instagram: 'https://www.instagram.com/stepmobile',
        Facebook: 'https://www.facebook.com/step',
        Twitter: 'https://twitter.com/step',
        LinkedIn: 'https://www.linkedin.com/company/stepmobile',
        TikTok: 'https://www.tiktok.com/@step',
        Discord: 'https://discord.com/invite/step',
        YouTube: 'https://youtube.com/c/Stepmobile',
    },

    app: {
        GooglePlay: () =>
            process.env.NEXT_PUBLIC_STEP_APP_ANDROID_DOWNLOAD_LINK,
        AppStore: () => process.env.NEXT_PUBLIC_STEP_APP_IOS_DOWNLOAD_LINK,
    },

    status: 'https://status.step.com',
}
