import classNames from 'classnames';
import type { PropsWithChildren } from 'react';
import React, { forwardRef } from 'react';
import type { OptionallyResponsiveProp } from '../../../../lib/utils/responsiveProps';
import { setupResponsivePropToClass } from '../../../../lib/utils/responsiveProps';
import styles from './flex.module.scss';
const responsivePropToClass = setupResponsivePropToClass(styles);
export const Flex = forwardRef<HTMLDivElement, PropsWithChildren<FlexProps>>((props, ref) => {
  const {
    direction = 'row',
    order,
    align,
    content,
    justify,
    wrap,
    className,
    ...rest
  } = props;
  return <div ref={ref} className={classNames(styles.flex, responsivePropToClass(direction, v => `flex-${v}`), responsivePropToClass(order, v => `order-${v}`), responsivePropToClass(align, v => `align-${v}`), responsivePropToClass(content, v => `content-${v}`), responsivePropToClass(justify, v => `justify-${v}`), responsivePropToClass(wrap, v => `wrap-${v}`), className)} {...rest} />;
});
export interface FlexProps {
  /**
   * The `direction` prop sets the flex-direction of this element.
   */
  direction?: OptionallyResponsiveProp<'row' | 'row-reverse' | 'column' | 'column-reverse'>;
  /**
   * The `order` prop sets the flex order of this element.
   */
  order?: OptionallyResponsiveProp<number>;
  /**
   * The align prop sets the `align-items` css rule.
   */
  align?: OptionallyResponsiveProp<'flex-start' | 'center' | 'flex-end' | 'baseline' | 'stretch'>;
  /**
   * The content prop sets the `align-content` css rule.
   */
  content?: OptionallyResponsiveProp<'flex-start' | 'center' | 'flex-end' | 'space-around' | 'space-between'>;
  /**
   * The content prop sets the `align-content` css rule.
   */
  wrap?: OptionallyResponsiveProp<'nowrap' | 'wrap' | 'wrap-reverse'>;
  /**
   * The justify prop sets the `justify-content` css rule.
   */
  justify?: OptionallyResponsiveProp<'flex-start' | 'center' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly'>;

  /**
   * Allow other classes to be passed to Flex.
   */
  className?: string;
}