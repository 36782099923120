import { makeAutoObservable } from 'mobx'

import { tracker } from '../tracker/useTracker'

export const SNACKBAR_DURATION = 2000

export const snackbarStore = makeAutoObservable(
    {
        content: '',

        visible: false,

        show(content: string, name: string, duration = SNACKBAR_DURATION) {
            this.visible = true
            this.content = content
            setTimeout(this.hide, duration)
            tracker.snackbarShown(name)
        },

        hide() {
            this.visible = false
        },
    },
    {},
    { autoBind: true }
)
