import { useEffect, useState } from 'react'

const INTERACTIONS = ['mouseover', 'touchstart', 'scroll']

/** Executes callback on first user interaction */
export const useFirstInteraction = (callback?: () => unknown): boolean => {
    const [interacted, setInteracted] = useState(false)

    const unsubscribeFromInteractions = (): void => {
        for (const name of INTERACTIONS) {
            window.removeEventListener(name, onFirstInteraction)
        }
    }

    const onFirstInteraction = () => {
        unsubscribeFromInteractions()
        callback?.()
        setInteracted(true)
    }

    useEffect(() => {
        for (const name of INTERACTIONS) {
            window.addEventListener(name, onFirstInteraction)
        }

        return unsubscribeFromInteractions
    }, [])

    return interacted
}
