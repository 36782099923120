import noop from 'lodash/noop'

import { isPrd } from '../util/env'

const enabled = !isPrd()

export const extendConsole = (className: string) => {
    const wrap = (method: string) => {
        if (!enabled && method !== 'error') return noop
        return console[method].bind(console, `[${className}]`) // eslint-disable-line no-console
    }

    return {
        debug: wrap('debug'),
        error: wrap('error'),
        info: wrap('info'),
        warn: wrap('warn'),
        diff(message: string, one: unknown, two: unknown) {
            if (!enabled) return

            Promise.all([import('json-diff'), import('ansicolor')]).then(
                ([jsonDiff, { parse }]) => {
                    // eslint-disable-next-line no-console
                    console.log(
                        ...parse(
                            `[${className}] ${message}\n${jsonDiff.diffString(
                                one,
                                two,
                                {
                                    color: true,
                                }
                            )}`
                        ).asChromeConsoleLogArguments
                    )
                }
            )
        },
    }
}
