import classNames from 'classnames';
import type { PropsWithChildren } from 'react';
import React, { forwardRef } from 'react';
import { Flex } from '../flex';
import type { FlexProps } from '../flex';
import styles from './row.module.scss';
export const Row = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(({
  className,
  ...rest
}, ref) => <Flex ref={ref} className={classNames(styles.row, className)} {...rest} />);
interface Props extends FlexProps {
  /**
   * Allow other classes to be passed to Row.
   */
  className?: string;
}