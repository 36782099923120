export enum CustomFieldId {
    AddressLine2 = 'address-line2',
    Amount = 'amount',
    AppLinkPhone = 'app-link.phone',
    City = 'city',
    Date = 'date',
    Email = 'email',
    Frequency = 'frequency',
    Interval = 'Interval',
    FullName = 'full-name',
    KycAddressLine1 = 'kyc.address-line1',
    KycFirstname = 'kyc.firstname',
    KycDateOfBirth = 'kyc.dob',
    KycLastname = 'kyc.lastname',
    KycSsn = 'kyc.ssn',
    Last4ssn = 'last4ssn',
    LoginEmail = 'login.email',
    LoginEmailOtp = 'login.email-otp',
    LoginOtp = 'login.otp',
    LoginPhone = 'login.phone',
    Note = 'note',
    State = 'state',
    ZipCode = 'zip-code',
    PaymentFullName = 'payment.full-name',
    PaymentPhone = 'payment.phone',
    PaymentEmail = 'payment.email',
    Phone = 'phone',
    SchoolRequestFullName = 'school-request.full-name',
    SchoolRequestPhone = 'school-request.phone',
    SchoolRequestEmail = 'school-request.email',
    SchoolRequestSchool = 'school-request.school',
    SchoolRequestCityState = 'school-request.city-state',
    SchoolRequestFacultyAdvisor = 'school-request.faculty-advisor',
    SSOPhone = 'sso.phone',
    SsoOtp = 'sso.otp',
}

export enum ValidationErrorCode {
    Required = 'required',
    InvalidCode = 'invalidCode',
    Min = 'min',
    Max = 'max',
    MaxLength = 'maxLength',
    MinLength = 'minLength',
    Pattern = 'pattern',
    Validate = 'validate',
    Adblocker = 'adblocker',
    ConnectionFailure = 'connectionFailure',
    ResourceExhausted = 'resourceExhausted',
}

export interface CustomField {
    label?: string
    placeholder?: string
    customId: CustomFieldId
    errors: {
        items: {
            code: ValidationErrorCode
            message: string
        }[]
    }
}

export const CustomFields: Record<CustomFieldId, CustomField> = {
    // generic email field
    [CustomFieldId.Email]: {
        label: 'Email Address',
        placeholder: 'name@mail.com',
        customId: CustomFieldId.Email,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Required,
                    message: 'Enter a valid email',
                },
            ],
        },
    },
    // generic full name field
    [CustomFieldId.FullName]: {
        label: 'Full Name',
        placeholder: 'John Smith',
        customId: CustomFieldId.FullName,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Required,
                    message: 'Enter a valid name',
                },
            ],
        },
    },
    // generic phone number field
    [CustomFieldId.Phone]: {
        label: 'Phone Number',
        placeholder: '(555) 555-5555',
        customId: CustomFieldId.Phone,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Required,
                    message: 'Enter a valid phone number',
                },
                {
                    code: ValidationErrorCode.Validate,
                    message: 'Enter a valid phone number',
                },
            ],
        },
    },
    [CustomFieldId.KycSsn]: {
        label: 'Social Security Number',
        placeholder: 'xxx-xx-xxxx',
        customId: CustomFieldId.KycSsn,
        errors: { items: [] },
    },
    [CustomFieldId.Amount]: {
        label: 'Amount',
        placeholder: '$0',
        customId: CustomFieldId.Amount,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Required,
                    message: 'Amount is required',
                },
                {
                    code: ValidationErrorCode.Min,
                    message: 'Amount does not meet the minimum requirement.',
                },
                {
                    code: ValidationErrorCode.Max,
                    message: 'Amount exceeds the maximum allowed value.',
                },
            ],
        },
    },
    [CustomFieldId.ZipCode]: {
        label: 'Zip Code',
        placeholder: '12345',
        customId: CustomFieldId.ZipCode,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Required,
                    message: 'Zip code is required',
                },
                {
                    code: ValidationErrorCode.Min,
                    message: 'Enter a valid zip code',
                },
                {
                    code: ValidationErrorCode.Min,
                    message: 'Enter a valid zip code',
                },
            ],
        },
    },
    [CustomFieldId.Note]: {
        label: 'Add a note',
        placeholder: "What's this for?",
        customId: CustomFieldId.Note,
        errors: { items: [] },
    },
    [CustomFieldId.SsoOtp]: {
        placeholder: 'Enter code',
        customId: CustomFieldId.SsoOtp,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.MinLength,
                    message: 'Must be exactly 4 digits',
                },
                {
                    code: ValidationErrorCode.MaxLength,
                    message: 'Must be exactly 4 digits',
                },
                {
                    code: ValidationErrorCode.Validate,
                    message: 'Must be only numbers',
                },
                {
                    code: ValidationErrorCode.ConnectionFailure,
                    message: 'Connection failure',
                },
                {
                    code: ValidationErrorCode.ResourceExhausted,
                    message:
                        'You attempted this operation too many times, please try again later',
                },
                {
                    code: ValidationErrorCode.InvalidCode,
                    message: 'Invalid or expired code',
                },
                {
                    code: ValidationErrorCode.Required,
                    message: 'Enter code from Step App',
                },
            ],
        },
    },
    [CustomFieldId.SSOPhone]: {
        placeholder: '(555) 555-5555',
        customId: CustomFieldId.SSOPhone,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Validate,
                    message: 'Enter a valid mobile phone number',
                },
                {
                    code: ValidationErrorCode.Required,
                    message: 'Make sure to enter a mobile phone number',
                },
            ],
        },
    },
    [CustomFieldId.SchoolRequestSchool]: {
        label: 'School Name',
        placeholder: 'Cesar Chavez High School',
        customId: CustomFieldId.SchoolRequestSchool,
        errors: { items: [] },
    },
    [CustomFieldId.SchoolRequestFacultyAdvisor]: {
        label: 'Name of Faculty Advisor (optional)',
        placeholder: 'John Smith',
        customId: CustomFieldId.SchoolRequestFacultyAdvisor,
        errors: { items: [] },
    },
    [CustomFieldId.SchoolRequestCityState]: {
        label: 'City and State',
        placeholder: 'San Francisco, CA',
        customId: CustomFieldId.SchoolRequestCityState,
        errors: { items: [] },
    },
    [CustomFieldId.SchoolRequestEmail]: {
        label: 'Email Address',
        placeholder: 'name@mail.com',
        customId: CustomFieldId.SchoolRequestEmail,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Required,
                    message: 'Enter a valid email',
                },
            ],
        },
    },
    [CustomFieldId.SchoolRequestPhone]: {
        label: 'Phone number',
        placeholder: '(555) 555-5555',
        customId: CustomFieldId.SchoolRequestPhone,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Validate,
                    message: 'Enter a valid mobile phone number',
                },
                {
                    code: ValidationErrorCode.Required,
                    message: 'Make sure to enter a mobile phone number',
                },
            ],
        },
    },
    [CustomFieldId.SchoolRequestFullName]: {
        label: 'Full Name',
        placeholder: 'John Smith',
        customId: CustomFieldId.SchoolRequestFullName,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Required,
                    message: 'Enter a valid name',
                },
                {
                    code: ValidationErrorCode.MinLength,
                    message: 'Enter at least 2 characters',
                },
            ],
        },
    },
    [CustomFieldId.AppLinkPhone]: {
        label: 'Phone number',
        placeholder: 'Your number',
        customId: CustomFieldId.AppLinkPhone,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Required,
                    message: 'Make sure to enter a mobile phone number',
                },
                {
                    code: ValidationErrorCode.Validate,
                    message: 'Enter a valid mobile phone number',
                },
                {
                    code: ValidationErrorCode.Adblocker,
                    message: 'Make sure you have ad-blockers turned off',
                },
                {
                    code: ValidationErrorCode.ResourceExhausted,
                    message:
                        'You attempted this operation too many times, please try again later',
                },
            ],
        },
    },
    [CustomFieldId.PaymentPhone]: {
        label: 'Phone number',
        placeholder: '(555) 555-5555',
        customId: CustomFieldId.PaymentPhone,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Validate,
                    message: 'Enter a valid mobile phone number',
                },
                {
                    code: ValidationErrorCode.Required,
                    message: 'Make sure to enter a mobile phone number',
                },
            ],
        },
    },
    [CustomFieldId.PaymentEmail]: {
        label: 'Email Address',
        placeholder: 'name@mail.com',
        customId: CustomFieldId.PaymentEmail,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Required,
                    message: 'Enter a valid email',
                },
            ],
        },
    },
    [CustomFieldId.PaymentFullName]: {
        label: 'Full name',
        placeholder: 'John Smith',
        customId: CustomFieldId.PaymentFullName,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Required,
                    message: 'Enter a valid name',
                },
                {
                    code: ValidationErrorCode.MinLength,
                    message: 'Enter at least 2 characters',
                },
            ],
        },
    },
    [CustomFieldId.LoginPhone]: {
        placeholder: '(555) 555-5555',
        customId: CustomFieldId.LoginPhone,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Validate,
                    message: 'Enter a valid mobile phone number',
                },
                {
                    code: ValidationErrorCode.Required,
                    message: 'Make sure to enter a mobile phone number',
                },
            ],
        },
    },
    [CustomFieldId.KycDateOfBirth]: {
        label: 'Date of Birth',
        placeholder: 'MM / DD / YYYY',
        customId: CustomFieldId.KycDateOfBirth,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Validate,
                    message: 'Invalid date',
                },
                {
                    code: ValidationErrorCode.Min,
                    message:
                        'You must be at least 18 years old to sponsor someone',
                },
                {
                    code: ValidationErrorCode.Max,
                    message: 'Please enter a valid date of birth',
                },
                {
                    code: ValidationErrorCode.Required,
                    message: 'Enter date of birth on your ID',
                },
            ],
        },
    },
    [CustomFieldId.Last4ssn]: {
        label: 'Last 4 Digits of SSN',
        placeholder: '1234',
        customId: CustomFieldId.Last4ssn,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.MinLength,
                    message: 'Must be exactly 4 numbers',
                },
                {
                    code: ValidationErrorCode.MaxLength,
                    message: 'Must be exactly 4 numbers',
                },
                {
                    code: ValidationErrorCode.Required,
                    message: 'Must be exactly 4 numbers',
                },
            ],
        },
    },
    [CustomFieldId.Date]: {
        label: 'Month / Day / Year',
        placeholder: 'MM / DD / YYYY',
        customId: CustomFieldId.Date,
        errors: { items: [] },
    },
    [CustomFieldId.KycFirstname]: {
        label: 'Legal First Name',
        placeholder: 'Jane',
        customId: CustomFieldId.KycFirstname,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Required,
                    message: 'First name is required',
                },
                {
                    code: ValidationErrorCode.MinLength,
                    message: 'Enter at least 2 characters',
                },
                {
                    code: ValidationErrorCode.Validate,
                    message: 'Enter a valid first name',
                },
            ],
        },
    },
    [CustomFieldId.KycLastname]: {
        label: 'Legal Last Name',
        placeholder: 'Smith',
        customId: CustomFieldId.KycLastname,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.MinLength,
                    message: 'Enter at least 2 characters',
                },
                {
                    code: ValidationErrorCode.Required,
                    message: 'Last name is required',
                },
                {
                    code: ValidationErrorCode.Validate,
                    message: 'Enter a valid last name',
                },
            ],
        },
    },
    [CustomFieldId.LoginOtp]: {
        label: 'Enter code',
        placeholder: 'Enter code',
        customId: CustomFieldId.LoginOtp,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.MinLength,
                    message: 'Must be exactly 6 digits',
                },
                {
                    code: ValidationErrorCode.MaxLength,
                    message: 'Must be exactly 6 digits',
                },
                {
                    code: ValidationErrorCode.ConnectionFailure,
                    message: 'Connection failure',
                },
                {
                    code: ValidationErrorCode.ResourceExhausted,
                    message:
                        'You attempted this operation too many times, please try again later',
                },
                {
                    code: ValidationErrorCode.Validate,
                    message: 'Enter the code you received In SMS',
                },
            ],
        },
    },
    [CustomFieldId.LoginEmailOtp]: {
        label: 'Enter code',
        placeholder: 'Enter code',
        customId: CustomFieldId.LoginEmailOtp,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.MinLength,
                    message: 'Must be exactly 6 digits',
                },
                {
                    code: ValidationErrorCode.MaxLength,
                    message: 'Must be exactly 6 digits',
                },
                {
                    code: ValidationErrorCode.Validate,
                    message: 'Enter the code you received in the email',
                },
                {
                    code: ValidationErrorCode.ConnectionFailure,
                    message: 'Connection failure',
                },
                {
                    code: ValidationErrorCode.ResourceExhausted,
                    message:
                        'You attempted this operation too many times, please try again later',
                },
            ],
        },
    },
    [CustomFieldId.LoginEmail]: {
        label: 'Email Address',
        placeholder: 'name@mail.com',
        customId: CustomFieldId.LoginEmail,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Required,
                    message: 'Enter a valid email',
                },
            ],
        },
    },
    [CustomFieldId.City]: {
        label: 'City',
        placeholder: 'Anywhere',
        customId: CustomFieldId.City,
        errors: { items: [] },
    },
    [CustomFieldId.State]: {
        label: 'State',
        placeholder: 'CA',
        customId: CustomFieldId.State,
        errors: { items: [] },
    },
    [CustomFieldId.AddressLine2]: {
        label: 'Address Line #2',
        placeholder: 'Apartment 123',
        customId: CustomFieldId.AddressLine2,
        errors: { items: [] },
    },
    [CustomFieldId.KycAddressLine1]: {
        label: 'Address Line #1',
        placeholder: '123 Any Street',
        customId: CustomFieldId.KycAddressLine1,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Required,
                    message: 'Enter residential address on your ID',
                },
            ],
        },
    },
    [CustomFieldId.Frequency]: {
        label: 'Frequency',
        placeholder: 'Weekly',
        customId: CustomFieldId.Frequency,
        errors: { items: [] },
    },
    [CustomFieldId.Interval]: {
        label: 'Schedule',
        placeholder: 'Weekly',
        customId: CustomFieldId.Interval,
        errors: {
            items: [
                {
                    code: ValidationErrorCode.Required,
                    message: 'Schedule is required',
                },
            ],
        },
    },
}
