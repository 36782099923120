import type { LiteralUnion } from 'type-fest'

import type { ColorFragment } from '../graphql/fragments/ColorFragment.graphql'

export const BRAND_COLORS = [
    'step-before-midnight',
    'step-coal-mine-canary-down',
    'step-coal-mine-canary',
    'step-cold-steel',
    'step-cyanora-down',
    'step-cyanora',
    'step-future-fuscia-down',
    'step-future-fuscia',
    'step-midnight',
    'step-angry',
    'step-off-white',
    'step-springtime',
    'step-right-before-midnight',
    'step-white',
    'step-linear-gradient-cyanora-to-future-fuscia',
    'step-vertical-gradient-cyanora-to-future-fuscia',
    'step-vertical-gradient-cyanora-to-violet',
] as const

export const NEUTRAL_COLORS: BrandColor[] = [
    'step-before-midnight',
    'step-midnight',
    'step-right-before-midnight',
    'step-cold-steel',
]

export type BrandColor = LiteralUnion<(typeof BRAND_COLORS)[number], string>

export function asColor(something: unknown) {
    return (something as ColorFragment)?.label
        ? (something as ColorFragment)
        : null
}

export function asColorLabel(something: unknown) {
    return asColor(something)?.label as BrandColor | undefined
}

export function asCSSVariable(something: unknown) {
    return `var(--${asColorLabel(something)})`
}
