import { mustExist } from '@getstep/sdk/dist/util/Assert'
import { makeAutoObservable } from 'mobx'

import { snackbarStore } from './snackbar'

export const devMenuStore = makeAutoObservable(
    {
        loading: false,
        visible: false,

        map: new Map<DevActionName, DevAction | DevLink>(),

        get groups() {
            const groups = {
                [DevActionGroup.EXTERNAL]: new Set<DevActionName>(),
                [DevActionGroup.PAGE]: new Set<DevActionName>(),
                [DevActionGroup.USER]: new Set<DevActionName>(),
            }

            for (const [key, value] of this.map.entries()) {
                groups[value.group].add(key)
            }

            return Object.entries(groups)
                .map(([group, names]) => [group, [...names].sort()] as const)
                .filter(([, names]) => names.length)
        },

        reset() {
            this.map = new Map()
            this.loading = false
        },

        open() {
            this.visible = true
        },

        close() {
            this.visible = false
        },

        add(item: DevLink | DevAction) {
            this.map.set(item.name, this.wrapAction(item))
        },

        remove(item: DevLink | DevAction) {
            this.map.delete(item.name)
        },

        byName(name: DevActionName) {
            return mustExist(
                this.map.get(name),
                `expected devMenuStore.byName(${name}) to be defined, but it wasn't`
            )
        },

        wrapAction(item: DevAction | DevLink) {
            const { close } = this

            if ('href' in item) return item

            const { onActivate, message, ...rest } = item

            const store = makeAutoObservable({
                ...rest,
                loading: false as boolean,
                async onActivate() {
                    store.loading = true

                    try {
                        await onActivate()
                        if (message)
                            snackbarStore.show(message, 'devMenu.action')
                    } finally {
                        store.loading = false
                        close()
                    }
                },
            })

            return store
        },
    },
    {},
    { autoBind: true }
)

type DevMenuItem = {
    group: DevActionGroup
    name: DevActionName
    loading?: boolean
}

export interface DevLink extends DevMenuItem {
    href: string
}

export interface DevAction extends DevMenuItem {
    message?: string
    onActivate: () => void | Promise<void>
}

export enum DevActionGroup {
    EXTERNAL = 'EXTERNAL',
    PAGE = 'PAGE',
    USER = 'USER',
}

export enum DevActionName {
    LOGOUT = 'LOGOUT',
    FILL_EMAIL = 'FILL_EMAIL',
    FILL_FORM = 'FILL_FORM',
    FILL_OTP = 'FILL_OTP',
    FILL_PHONE = 'FILL_PHONE',
    GENERATE_INVITE = 'GENERATE_INVITE',
    OPEN_IN_ADMIN = 'OPEN_IN_ADMIN',
    CREATE_TRANSFER = 'CREATE_TRANSFER',
    VIEW_SOCIAL_IMAGE = 'VIEW_SOCIAL_IMAGE',
    STRIPE_TESTING_DOCS = 'STRIPE_TESTING_DOCS',
    CREATE_SPONSOR_REQUEST = 'CREATE_SPONSOR_REQUEST',
    TOGGLE_APPROVAL_FLOW = 'TOGGLE_APPROVAL_FLOW',
}
