export function PlusIcon() {
  return <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' data-sentry-element="svg" data-sentry-component="PlusIcon" data-sentry-source-file="index.tsx">
            <path d='M18 9L-7.86805e-07 9M9 -1.35896e-06L9 18' stroke='currentColor' strokeWidth='2' data-sentry-element="path" data-sentry-source-file="index.tsx" />
        </svg>;
}
export function MinusIcon() {
  return <svg width='18' height='10' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' data-sentry-element="svg" data-sentry-component="MinusIcon" data-sentry-source-file="index.tsx">
            <path d='M18 9L1.90735e-06 9' stroke='currentColor' strokeWidth='2' data-sentry-element="path" data-sentry-source-file="index.tsx" />
        </svg>;
}
export function ArrowIcon() {
  return <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' data-sentry-element="svg" data-sentry-component="ArrowIcon" data-sentry-source-file="index.tsx">
            <path d='M0 1H15M15 1V16M15 1L1 15' stroke='currentColor' strokeWidth='2' data-sentry-element="path" data-sentry-source-file="index.tsx" />
        </svg>;
}
export function ChevronIcon() {
  return <svg width='16' height='43' viewBox='0 0 16 43' fill='none' xmlns='http://www.w3.org/2000/svg' data-sentry-element="svg" data-sentry-component="ChevronIcon" data-sentry-source-file="index.tsx">
            <path d='M2 35L13.5 23.5L2 12' stroke='currentColor' strokeWidth='4' strokeLinecap='round' strokeLinejoin='round' data-sentry-element="path" data-sentry-source-file="index.tsx" />
        </svg>;
}
export const CloseIcon = ({
  ...rest
}) => <svg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest} data-sentry-element="svg" data-sentry-component="CloseIcon" data-sentry-source-file="index.tsx">
        <path d='M2 2L18 18M18 2L2 18' stroke='currentColor' strokeWidth='3' data-sentry-element="path" data-sentry-source-file="index.tsx" />
    </svg>;
export const OpenIcon = ({
  ...rest
}) => <svg width='24' height='18' viewBox='0 0 24 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest} data-sentry-element="svg" data-sentry-component="OpenIcon" data-sentry-source-file="index.tsx">
        <line x1='1' y1='1' x2='23' y2='1' stroke='currentColor' strokeWidth='3' strokeLinecap='round' data-sentry-element="line" data-sentry-source-file="index.tsx" />
        <line x1='1' y1='9' x2='23' y2='9' stroke='currentColor' strokeWidth='3' strokeLinecap='round' data-sentry-element="line" data-sentry-source-file="index.tsx" />
        <line x1='1' y1='17' x2='23' y2='17' stroke='currentColor' strokeWidth='3' strokeLinecap='round' data-sentry-element="line" data-sentry-source-file="index.tsx" />
    </svg>;
export const ExternalLinkIcon = ({
  size = 24
}) => <svg fill='none' height={size} viewBox='0 0 21 21' width={size} xmlns='http://www.w3.org/2000/svg' xlinkTitle='ExternalLinkIcon' data-sentry-element="svg" data-sentry-component="ExternalLinkIcon" data-sentry-source-file="index.tsx">
        <clipPath id='a' data-sentry-element="clipPath" data-sentry-source-file="index.tsx">
            <path d='m0 0h21v21h-21z' data-sentry-element="path" data-sentry-source-file="index.tsx" />
        </clipPath>
        <clipPath id='b' data-sentry-element="clipPath" data-sentry-source-file="index.tsx">
            <path d='m.5.5h20v20h-20z' data-sentry-element="path" data-sentry-source-file="index.tsx" />
        </clipPath>
        <mask id='c' height='21' maskUnits='userSpaceOnUse' width='21' x='0' y='0' data-sentry-element="mask" data-sentry-source-file="index.tsx">
            <path d='m20.5.5h-20v20h20z' fill='#d9d9d9' data-sentry-element="path" data-sentry-source-file="index.tsx" />
        </mask>
        <g clipPath='url(#a)' data-sentry-element="g" data-sentry-source-file="index.tsx">
            <g clipPath='url(#b)' data-sentry-element="g" data-sentry-source-file="index.tsx">
                <g mask='url(#c)' data-sentry-element="g" data-sentry-source-file="index.tsx">
                    <path d='m12.5 3.5h-5c-1.88562 0-2.82843 0-3.41421.58579-.58579.58578-.58579 1.52859-.58579 3.41421v6c0 1.8856 0 2.8284.58579 3.4142.58578.5858 1.52859.5858 3.41421.5858h6c1.8856 0 2.8284 0 3.4142-.5858s.5858-1.5286.5858-3.4142v-5.07692' stroke='#fff' strokeLinecap='round' strokeWidth='1.5' data-sentry-element="path" data-sentry-source-file="index.tsx" />
                    <path d='m14.5 1.43234h4.214c.2762 0 .5.22386.5.5v4.21405' stroke='#fff' strokeLinecap='round' strokeWidth='1.5' data-sentry-element="path" data-sentry-source-file="index.tsx" />
                    <rect fill='#fff' height='10' rx='.75' transform='matrix(.70710678 .70710678 -.70710678 .70710678 6.33126 -12.420334)' width='1.5' x='18.1583' y='1.43234' data-sentry-element="rect" data-sentry-source-file="index.tsx" />
                </g>
            </g>
        </g>
    </svg>;