import classNames from 'classnames';
import cookieCutter from 'cookie-cutter';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import type { FunctionComponent } from 'react';
import React from 'react';
import { useStores } from '../../../lib/store/useStores';
import { TEAM_ENVIRONMENT_COOKIE, TEAM_ENVIRONMENT_QUERY_PARAM } from '../../../lib/utils/team-env';
import { Button } from '../../button/button';
import { Close } from '../../icons/close';
import styles from './team-env-banner.module.scss';
export const TeamEnvironmentBanner: FunctionComponent = observer(() => {
  const {
    sdk: {
      teamEnvironment
    }
  } = useStores();
  const router = useRouter();
  const {
    query,
    pathname
  } = router;
  if (!teamEnvironment) return null;
  return <div className={classNames(styles['team-banner'])}>
            <span>
                Currently connected to team environment - {teamEnvironment}
            </span>
            <Button preset='plain' animatePress={false} size='medium' onClick={async () => {
      cookieCutter.set(TEAM_ENVIRONMENT_COOKIE, '', {
        expires: new Date(0)
      });
      delete query[TEAM_ENVIRONMENT_QUERY_PARAM];
      await router.replace({
        pathname,
        query
      });
      router.reload();
    }}>
                <Close className={classNames(styles['close-button'])} />
            </Button>
        </div>;
});