/**
 * Omit values from source that match predicate
 * @param source the source to scan
 * @param predicate return true from this function to omit values
 * This function mutates source
 */
export const omit = (
    source: any,
    predicate: (value: any, key?: string) => boolean
) => {
    if (!source) return
    if (Array.isArray(source)) {
        source.forEach((e, i) => {
            if (predicate(e)) {
                source.splice(i, 1)
            }
        })
        source.forEach((e) => {
            omit(e, predicate)
        })
        return
    }
    Object.keys(source).forEach((key) => {
        if (predicate(source[key], key)) {
            delete source[key]
            return
        }

        if (typeof source[key] === 'object') {
            omit(source[key], predicate)
        }
    })
}

/**
 * Recursively pick non-empty values from source.
 * This function does not mutate source.
 * Careful, this will eliminate all mobx observables, so don't use it in computeds.
 * @param allowEmpty if true, empty values, like [] or {} will be returned as well
 */
export function compact<T extends object>(data: T, allowEmpty?: boolean): T {
    const empty = (value: unknown): boolean => {
        if (typeof value === 'boolean' || typeof value === 'number') {
            return false
        }

        if (!value) {
            return true
        }

        if (Array.isArray(value) && !allowEmpty) {
            return !value.some((v) => !empty(v))
        }

        if (typeof value === 'object' && !allowEmpty) {
            return !Object.values(value).some((v) => !empty(v))
        }

        return !value
    }

    const params = JSON.parse(JSON.stringify(data))
    omit(params, empty)
    return params
}
