import ls from 'localstorage-slim'

import { isBrowser } from '../../../shared/util/env'

export const localStorageWithTTL = {
    get: <R>(key: string) => ls.get(key) as R,

    set: <V extends Record<string, unknown>>(
        key: string,
        value: V,
        ttl?: number
    ) => {
        const oldValue = localStorage.getItem(key)
        ls.set(key, value, { ttl: ttl && ttl * 60 })

        // we need to emit local event to sync storage within this tab
        // by default only other tabs will fire this
        dispatchEvent(key, oldValue, localStorage.getItem(key))
    },

    remove: (key: string) => ls.remove(key),

    addListener: (key: string, listener: (e: StorageEvent) => void) => {
        if (!isBrowser()) return

        const innerListener = (e: StorageEvent) => {
            if (e.key !== key) return
            if (!e.url) return // this tab events are emitted without url
            listener(e)
        }

        window.addEventListener('storage', innerListener)
        return () => window.removeEventListener('storage', innerListener)
    },
}

export function dispatchEvent(
    key: string,
    oldValue: string | null,
    newValue: string | null,
    url?: string
) {
    if (!isBrowser()) return
    window.dispatchEvent(
        new StorageEvent('storage', {
            key,
            oldValue,
            newValue,
            storageArea: localStorage,
            url,
        })
    )
}
