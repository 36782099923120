import { useEffect, useState } from 'react'

/**
 * Returns true if the component is mounted in browser.
 * Accepts optional callback to be executed on mount.
 */
export const useDidMount = <T extends CallableFunction>(onMount?: T) => {
    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        setMounted(true)
        onMount?.()
    }, [onMount])

    return mounted
}
