import type {
    GetServerSidePropsContext,
    NextComponentType,
    NextPageContext,
} from 'next'
import type { ReactNode } from 'react'

import { isBrowser } from '../../shared/util/env'

export type PageWithContext<
    P = Record<string, unknown>,
    IP = P
> = NextComponentType<NextPageContext, IP, P> & {
    getLayout?: (page: ReactNode, pageProps: P) => ReactNode
}

/**
 * Returns true if this context has been created when rendering a page,
 * and not during API or bundle requests.
 */
export const isDocumentContext = (
    ctx: NextPageContext | GetServerSidePropsContext
) => {
    const { req } = ctx
    if (isBrowser() || !req?.url) return true
    return !req.url.includes('_next')
}
