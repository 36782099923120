import { useEffect } from 'react'

import { isBrowser, isTests } from '../../../shared/util/env'
import { usePreviousValue } from '../../hooks/usePreviousValue'
import { nullTracker } from './NullTracker'
import { ProxyTracker } from './ProxyTracker'

export const tracker =
    isBrowser() && !isTests() ? new ProxyTracker() : nullTracker

export const useTrackPageView = (pageName: string, properties = {}): void => {
    const lastPageName = usePreviousValue(pageName)

    useEffect(() => {
        if (!pageName) return
        if (lastPageName === pageName) return
        tracker.pageView(pageName, properties)
    }, [pageName])
}
