import escapeRegExp from 'lodash/escapeRegExp'
import type { ReadonlyURLSearchParams } from 'next/navigation'
import { useSearchParams } from 'next/navigation'
import type { NextRouter } from 'next/router'
import { useRouter } from 'next/router'
import type { ParsedUrlQuery } from 'querystring'
import { format, parse } from 'url'

type Query = NextRouter['query']

const STEP_HOST = 'step.com'

/**
 * Allows for easier derivative URL creation with or without host/query params.
 * Preserves UTM params by default.
 */
export class LocalLink {
    static APP_REGEX = /^(\/)(app|client|webview)\/?/

    protocol = 'https'
    host: string
    hash: string
    pathname: string
    route: string
    query: Query

    constructor({ base, host, query, route, hash }: URLParts) {
        const parsedBase = parse(base, true, true)
        this.host = host ?? parsedBase.host ?? STEP_HOST
        this.pathname = removeLeadingSlash(parsedBase.pathname ?? '')
        this.hash = hash ?? ''
        this.route = route ?? this.pathname
        this.query = { ...parsedBase.query, ...query }
    }

    /**
     * Assigns query params to the url. Will replace existing values in case of dupes
     */
    addQueryParams(query: Query): LocalLink {
        this.query = { ...this.query, ...query }
        return this
    }

    /**
     * Assigns the host to the url.
     */
    setHostAndProtocol(host: string, protocol: string): LocalLink {
        this.host = host
        this.protocol = protocol
        return this
    }

    /** Serializes to string.
     *
     * __WARNING__: Trailing slashes WILL BE REMOVED to avoid duplication by search-engines.
     *
     * Please do not change this behaviour without:
     * - carefully considering your reasons for doing it;
     * - consulting SEO people;
     * - creating 301 redirects for all pages.
     */
    serialize(
        router?: { asPath: string; query: ParsedUrlQuery },
        {
            preserveFullQuery,
            preserveUTM = true,
            canonical = false,
        }: SerializeOptions = {}
    ): string {
        const { host, pathname, hash, protocol } = this

        const query = {}

        if (router) {
            for (const [key, value] of Object.entries(
                parse(router.asPath, true, true).query
            )) {
                if (
                    preserveFullQuery ||
                    (preserveUTM && key.startsWith('utm_'))
                ) {
                    query[key] = value
                }
            }
        }

        Object.assign(query, this.query)

        // `canonical` should be used for search engine readable links, which
        // don't need the query and hash
        // Additionally, our robots.txt disallows pages with query params and
        // hash only works in browsers
        if (canonical) {
            return removeLeadingSlash(
                format({
                    host,
                    pathname,
                    protocol,
                    query: preserveFullQuery ? query : {},
                })
            )
        }

        return addAnchor(
            removeLeadingSlash(format({ host: '', pathname, query })),
            hash
        )
    }

    asNextProps(router: NextRouter): {
        href: string
        active: boolean
        as?: string
    } {
        const { route, pathname } = this

        const active = pathname === router.asPath

        const serialized = this.serialize(router)

        if (route) return { href: route, as: serialized, active }
        return { href: serialized, active }
    }

    asRegExp(): RegExp {
        return new RegExp(escapeRegExp(this.serialize()))
    }
}

/**
 * Determines if the given URL is a local route.
 */
export const isLocal = (base: string): boolean => {
    const { host } = parse(base, false)
    return !host || host === STEP_HOST || host === `www.${STEP_HOST}`
}

/**
 * Determines if the given URL is rendered in a WebView inside the Step app.
 */
export const isAppPage = (
    pathname: string,
    params?: ReadonlyURLSearchParams
) => {
    const isShownInApp = params?.get('shown-in-app') != null
    return isShownInApp || LocalLink.APP_REGEX.test(pathname)
}

/**
 * Hook that determines if the given URL is rendered in a WebView inside the Step app.
 */
export const useIsAppPage = (pathname: string) => {
    const router = useRouter()
    const params = useSearchParams()
    return isAppPage(router.isReady ? router.asPath : pathname, params)
}

/**
 * Removes /app and /client from the pathname
 */
export const stripAppPrefix = (pathname: string): string => {
    return pathname.replace(LocalLink.APP_REGEX, '$1')
}

/**
 * Appends optional anchor to the path. Anchor may or may not contain the leading #.
 */
export const addAnchor = (path: string, anchor?: string): string => {
    if (!anchor) return path
    const pathname = new LocalLink({ base: path }).pathname
    return [pathname, anchor].join(anchor.startsWith('#') ? '' : '#')
}

/**
 * Removes leading slash from a path/url.
 */
export const removeLeadingSlash = (s: string) => s.replace(/\/+$/, '') || '/'

export interface URLParts {
    /** Base URL. Relative and absolute URLs with or without query params and protocol. */
    base: string
    /** Next.js route, used for dynamic links. If not set, pathname will be used */
    route?: string
    /** Overwrite query with this object. You can use this or concat stringified query to base param */
    query?: NextRouter['query']
    /** New hash to set. */
    hash?: string
    /** New host to set. Setting this option will result in a canonical URL. */
    host?: string
}

export interface SerializeOptions {
    /** Set this to merge current browser query with what you passed, otherwise it will be overwritten. */
    preserveFullQuery?: boolean
    /** Set this to false not to preserve UTM params such as campaign, when overwriting query. */
    preserveUTM?: boolean
    /** Set this to true to serialize to Canonical URL with host and without GET-params.  */
    canonical?: boolean
}
