import type { FunctionComponent } from 'react';
import { useEffectAsync } from '../hooks/useEffectAsync';
import { AppsFlyerLinker } from './linking/AppsFlyerLinker';
import type { LinkingStore } from './linking/LinkingStore';
import type { SdkClientStore } from './SdkClientStore';
import { useClickTracker } from './tracker/ClickTracker';

// Loads linker and initializes it. Client-side only.
export const LinkingLoader: FunctionComponent<Props> = ({
  store,
  sdk
}) => {
  useClickTracker();
  useEffectAsync(async () => {
    await store.setLinker(new AppsFlyerLinker(sdk));
  }, []);
  return null;
};
type Props = {
  store: LinkingStore;
  sdk: SdkClientStore;
};