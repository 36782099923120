import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import noop from 'lodash/noop'
import type { AnnotationsMap } from 'mobx'
import {
    autorun,
    computed,
    makeObservable,
    observable,
    runInAction,
} from 'mobx'

import { extendConsole } from '../../../shared/logging/console'
import { isBrowser } from '../../../shared/util/env'
import { compact } from '../../utils/omit'
import { localStorageWithTTL as storage } from './storage'

/**
 * Extend this to make a store persistent.
 * That store doesn't have to be a `DataStore` for this.
 *
 * Example usage:
 *
 * ```ts
 * class MyStore extends PersistentStore {
 *   static className = 'MyStore'

 *   persistentFields = ['persistantProperty2']
 *
 *   property1 = 1
 *
 *   persistentProperty2 = 2
 *
 *  ```
 */
export abstract class PersistentStore {
    static readonly className: string

    /**
     * Lifetime of the data in minutes. If not set, the data will be stored forever
     */
    static readonly ttl?: number

    /**
     * Only these fields will be persisted and rehydrated.
     */
    static readonly persistentFields: string[] = []

    hydrated = false

    constructor() {
        const { cls } = this
        if (!isBrowser()) {
            cls.readStorage = noop
            cls.clearStorage = noop
            cls.writeToStorage = noop
            return
        }
    }

    static get key() {
        return `storage.${this.className}`
    }

    static get logger() {
        return extendConsole(this.key)
    }

    get state() {
        const { persistentFields } = this.cls

        return Object.fromEntries(
            persistentFields.map((key) => [key, this[key]])
        )
    }

    get className() {
        return this.cls.className
    }

    get cls() {
        return this.constructor as typeof PersistentStore
    }

    static readStorage() {
        return storage.get(this.key)
    }

    static clearStorage() {
        const { logger, key } = this
        storage.remove(key)
        logger.debug('clear')
    }

    static writeToStorage(state: any) {
        runInAction(() => {
            const value = compact(state)
            const { logger, key, ttl } = this

            if (isEmpty(value)) return

            const fromStorage = this.readStorage()
            if (isEqual(value, fromStorage)) return

            storage.set(key, value, ttl)

            logger.diff(
                ttl ? `persisted for ${ttl} minutes` : 'persisted',
                fromStorage,
                value
            )
        })
    }

    clearStorage() {
        this.cls.clearStorage()
    }

    persist() {
        this.cls.writeToStorage(this.state)
    }

    rehydrate() {
        this.restore(this.cls.readStorage())
    }

    restore(source: unknown) {
        runInAction(() => {
            const {
                state: existing,
                cls: { logger },
            } = this

            if (!source) return

            if (typeof source !== 'object') {
                logger.warn('restore: source is not an object', source)
                return
            }

            const value = compact(source)

            if (isEqual(compact(existing), value)) return

            Object.assign(this, value)
            logger.diff('restored', existing, value)
            this.hydrated = true
        })
    }
}

/**
 * Makes the object observable and persistent.
 */
export function makePersistentObservable<T extends PersistentStore>(
    store: T,
    overrides?: AnnotationsMap<T, PropertyKey>
) {
    const config = {
        state: computed,
        hydrated: observable,
        ...Object.fromEntries(
            store.cls.persistentFields.map((key) => [key, observable])
        ),
        ...overrides,
    }
    const observed = makeObservable(store, config)
    observed.rehydrate()
    autorun(() => observed.persist())
    storage.addListener(observed.cls.key, () => observed.rehydrate())
    return observed
}
