import { ErrorMessage } from '@hookform/error-message';
import { animated } from '@react-spring/web';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import Link from 'next/link';
import type { FunctionComponent } from 'react';
import { createRef, useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import type { ButtonProps } from '../../../components/button/button';
import { Button } from '../../../components/button/button';
import type { ButtonTrackingProps } from '../../../components/button/props';
import { CountryCode, TelephoneInput } from '../../../components/input/telephone-input';
import { Tooltip } from '../../../components/tooltip/tooltip';
import type { ActionDownloadAppFragment } from '../../../graphql/fragments/ActionDownloadAppFragment.graphql';
import { CustomFieldId, useField } from '../../../graphql/hooks/field';
import { CustomFieldsetId } from '../../../graphql/hooks/form-page';
import { tracker } from '../../../lib/store/tracker/useTracker';
import { useStores } from '../../../lib/store/useStores';
import { waitlistStore } from '../../../lib/store/waitlist/WaitlistStore';
import type { WithTestId } from '../../../lib/utils/testid';
import { TEXT } from '../../../lib/utils/text';
import { telephoneToE164, validateTelephoneInput } from '../../../shared/util/phone';
import styles from './download-app.module.scss';
import { useButtonRolloutAnimation } from './hooks';
export const DownloadApp: FunctionComponent<React.PropsWithChildren<Props>> = observer(({
  preset,
  title,
  size = 'medium',
  buttonProps,
  analyticsKey,
  placement,
  containerElementId,
  loading,
  forceExpanded = false,
  'data-testid': testId
}) => {
  const [expanded, setExpanded] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const {
    errorTexts,
    placeholder
  } = useField(CustomFieldId.AppLinkPhone);
  const isExpanded = forceExpanded || expanded;

  // conditions in which we show the help text
  const showHelpText = placement !== 'header' && size !== 'small';
  const {
    linkingStore
  } = useStores();
  const {
    handleSubmit,
    reset,
    setError,
    control,
    formState: {
      errors,
      isDirty,
      isSubmitSuccessful,
      submitCount,
      isSubmitting,
      isSubmitted
    }
  } = useForm<{
    phone: string;
  }>({
    mode: 'onSubmit'
  });
  const buttonRef = createRef<HTMLButtonElement>();
  const {
    inputStyles,
    buttonStyles,
    formStyles
  } = useButtonRolloutAnimation(isExpanded ? 'focused' : 'idle', size, placement);
  const onSubmit = handleSubmit(async values => {
    const phone = await telephoneToE164(values.phone);
    if (!phone) {
      setError('phone', {
        message: errorTexts.required
      });
      return;
    }
    try {
      await linkingStore.sendDownloadLinkInSMS(phone);
      tracker.formSubmitSuccess(CustomFieldsetId.DownloadApp).then();
    } catch (e) {
      setError('phone', {
        message: errorTexts.adblocker
      });
      return;
    }

    // clear success status after 2 seconds, to allow resending
    setTimeout(() => {
      reset();
    }, 2000);
  });
  const onExpand = () => {
    setTimeout(() => setExpanded(true), 100);
  };
  useEffect(() => {
    // TODO: separate prop not relying on size
    if (placement !== 'header' && size !== 'small' || !isExpanded) return;
    const onCollapse = () => {
      reset();
      setExpanded(false);
    };
    window.addEventListener('scroll', onCollapse);
    return () => window.removeEventListener('scroll', onCollapse);
  }, [expanded, isExpanded, placement, reset, size]);
  const {
    field: controller
  } = useController({
    control,
    defaultValue: waitlistStore.contactInfo.phone,
    name: 'phone',
    rules: {
      validate: phone => validateTelephoneInput(phone) || errorTexts.validate,
      required: {
        value: true,
        message: errorTexts.required
      }
    }
  });
  const buttonLabel: string = (() => {
    if (!isExpanded) return title ?? '';
    if (isSubmitted) return TEXT.textMeAppLink.resend;
    return TEXT.textMeAppLink.getStarted;
  })();
  return <form onSubmit={onSubmit} data-testid={testId} data-expanded={isExpanded} className={classNames(styles.form, styles[size], styles[`placement--${placement}`])}>
                    <animated.div style={formStyles} className={styles.control}>
                        {isExpanded && <animated.div style={inputStyles} className={classNames(styles.field, styles[`field-${size}`])}>
                                <Tooltip show={isFocused} content={<div>
                                            By providing your phone number, you
                                            confirm you are 13 or over and you
                                            consent to receive SMS marketing
                                            messages from Step to the phone
                                            number provided. See{' '}
                                            <Link href='/policies/mobileterms' target='_blank'>
                                                Mobile Terms
                                            </Link>{' '}
                                            for more information. Message
                                            frequency varies and data rates
                                            apply. Reply HELP for help or STOP
                                            to cancel.
                                        </div>}>
                                    <TelephoneInput {...controller} autoFocus={!forceExpanded} data-testid='getApp.field' analyticsKey={CustomFieldId.AppLinkPhone} size={size} disabled={isSubmitting} validationCount={submitCount} valid={!errors.phone} placeholder={placeholder} adornment={<CountryCode />} onFocus={() => {
            setIsFocused(true);
          }} onBlur={() => {
            // wait for link click propagation if link in tooltip was clicked
            setTimeout(() => {
              setIsFocused(false);
            }, 100);
          }} />
                                </Tooltip>
                            </animated.div>}
                        <animated.div style={buttonStyles} className={classNames(styles.submit, styles[`submit-${size}`])}>
                            {isSubmitSuccessful ? <div className={styles.success}>
                                    {TEXT.textMeAppLink.sent}
                                </div> : <Button {...buttonProps} data-testid='getApp.button' analyticsKey={analyticsKey} placement={placement} containerElementId={containerElementId} loading={isExpanded && isSubmitting || loading} disabled={isSubmitting} type={isExpanded ? 'submit' : 'button'} preset={preset ? preset : 'white'} size={size} ref={buttonRef} animatePress={isExpanded && isDirty} onClick={!isExpanded ? onExpand : undefined}>
                                    {buttonLabel}
                                </Button>}
                        </animated.div>
                    </animated.div>

                    {isExpanded && showHelpText && (errors.phone ? <div className={styles.error} data-testid='getApp.error'>
                                <ErrorMessage errors={errors} name='phone' />
                            </div> : <div className={styles.why}>
                                {/*{TEXT.textMeAppLink.why}*/}
                            </div>)}
                </form>;
});
type Props = WithTestId<ButtonTrackingProps & Omit<ActionDownloadAppFragment, 'type' | 'sys'> & {
  size?: 'small' | 'medium';
  buttonProps?: Partial<ButtonProps>;
  loading?: boolean;
  forceExpanded?: boolean;
  inputColor?: string;
}>;