import type { NodeRenderer } from '@contentful/rich-text-react-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { AssetLinkBlock, Document, EntryLinkBlock, Heading1, Heading2, Heading3, Heading4, Hr, Hyperlink, ListItem, Node, OrderedList, Paragraph as ParagraphNode, Table, TableCell, TableRow, UnorderedList } from '@contentful/rich-text-types';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import merge from 'lodash/merge';
import type { FunctionComponent, ReactNode } from 'react';
import { Anchor, RawAnchor, ScrollAnchor } from '../../components/anchor';
import { Heading } from '../../components/heading';
import { Image } from '../../components/image';
import { List } from '../../components/list';
import { Paragraph } from '../../components/paragraph';
import type { ColorFragment } from '../../graphql/fragments/ColorFragment.graphql';
import type { Personalizations } from '../../lib/hooks/usePersonalizations';
import { usePersonalizations } from '../../lib/hooks/usePersonalizations';
import { hasSlugs, PAGE_OPTIONS } from '../../lib/utils/page';
import { renderTemplate } from '../../lib/utils/text';
import { isLocal } from '../../shared/util/link';
import { asCSSVariable } from '../../types/color';
import type { AssetMeta, EntryMeta } from '../../types/sys';
import { Receipt } from '../receipt';
const {
  PARAGRAPH,
  OL_LIST,
  UL_LIST,
  LIST_ITEM,
  HEADING_1,
  HEADING_2,
  HEADING_3,
  HEADING_4,
  HR,
  EMBEDDED_ENTRY,
  EMBEDDED_ASSET,
  TABLE,
  TABLE_ROW,
  TABLE_CELL
} = BLOCKS;
const {
  ITALIC,
  BOLD
} = MARKS;
const {
  HYPERLINK
} = INLINES;
const Text: FunctionComponent<{
  children: string;
  personalizations?: Personalizations;
}> = ({
  children,
  personalizations: personalizationsFromProps
}) => {
  const personalizations = usePersonalizations();
  const combinedPersonalizations = merge({}, personalizations, personalizationsFromProps);
  return <>{renderTemplate(children, combinedPersonalizations)}</>;
};
export const renderDocumentWithLinks = ({
  links,
  json
}: DocumentWithLinks, renderers?: CustomRenderers, color?: ColorFragment, personalizations?: Personalizations) => {
  const linkMap = {} as Record<string, EntryMeta>;
  const assetMap = {} as Record<string, AssetMeta>;
  for (const link of links?.entries.block || []) {
    linkMap[link.sys.id] = link;
  }
  for (const link of links?.assets?.block || []) {
    assetMap[link.sys.id] = link;
  }
  if (!json) return null;
  return <div style={color && {
    color: asCSSVariable(color)
  }} data-sentry-component="renderDocumentWithLinks" data-sentry-source-file="render.tsx">
            {documentToReactComponents(json, {
      renderText: (text: string) => {
        return renderers?.text ? renderers?.text(text) : <Text personalizations={personalizations}>{text}</Text>;
      },
      renderNode: {
        [PARAGRAPH]: typedNodeRenderer(PARAGRAPH, (_, children) => <Paragraph>{children}</Paragraph>, renderers?.paragraph),
        [OL_LIST]: typedNodeRenderer(OL_LIST, (_, children) => <List ordered>{children}</List>),
        [UL_LIST]: typedNodeRenderer(UL_LIST, (_, children) => <List>{children}</List>),
        [LIST_ITEM]: typedNodeRenderer(LIST_ITEM, (_, children) => <List.Item>{children}</List.Item>, renderers?.listItem),
        [HEADING_1]: typedNodeRenderer(HEADING_1, (_, children) => <Heading level={1}>{children}</Heading>),
        [HEADING_2]: typedNodeRenderer(HEADING_2, (_, children) => <Heading level={2}>{children}</Heading>),
        [HEADING_3]: typedNodeRenderer(HEADING_3, (_, children) => <Heading level={3}>{children}</Heading>),
        [HEADING_4]: typedNodeRenderer(HEADING_4, (_, children) => <Heading level={4}>{children}</Heading>),
        [HR]: typedNodeRenderer(HR, renderers?.hr ?? (() => <hr />)),
        [HYPERLINK]: typedNodeRenderer(HYPERLINK, (node: Hyperlink, children) => {
          const href = node.data.uri;
          if (href.startsWith('#')) {
            return <ScrollAnchor to={href}>
                                        {children}
                                    </ScrollAnchor>;
          }
          if (
          // local links are handled by the router,
          // they will open in the same window,
          // unless they're links to policies
          isLocal(href) && !hasSlugs(href, [PAGE_OPTIONS.POLICIES.slug])) {
            return <Anchor href={href}>{children}</Anchor>;
          }
          return (
            // external links are opened in a new window
            <RawAnchor href={href} target={href}>
                                    {children}
                                </RawAnchor>
          );
        }, renderers?.link),
        [TABLE]: typedNodeRenderer(TABLE, (_, children) => <div style={{
          overflowX: 'auto'
        }}>
                                <table>
                                    <tbody>{children}</tbody>
                                </table>
                            </div>, renderers?.table),
        [TABLE_ROW]: (node, children) => <tr>{children}</tr>,
        [TABLE_CELL]: (node, children) => <td>{children}</td>,
        [EMBEDDED_ENTRY]: ((node: EntryLinkBlock) => {
          const id = node.data.target.sys.id;
          const link = linkMap[id];
          if (link.type === 'Receipt') {
            if (renderers?.receipt) {
              return renderers.receipt(link);
            }
            return <Receipt {...link} />;
          }
          return null;
        }) as any,
        [EMBEDDED_ASSET]: ((node: AssetLinkBlock) => {
          const id = node.data.target.sys.id;
          const link = assetMap[id];
          if (link.contentType && ['image/png', 'image/jpeg'].includes(link.contentType)) {
            return <img alt={link?.title ?? ''} src={link?.url ?? ''} style={{
              width: link?.width ?? 'auto',
              maxWidth: '100%'
            }} />;
          }
          return null;
        }) as any,
        [CUSTOM_IMAGE]: typedNodeRenderer(CUSTOM_IMAGE, ({
          data: {
            alt,
            src
          }
        }) => {
          return src ? <Image alt={alt} source={src} /> : null;
        }),
        [SANITIZED_HTML]: typedNodeRenderer(SANITIZED_HTML, ({
          data: {
            html
          }
        }) => {
          return <span dangerouslySetInnerHTML={{
            __html: html
          }} />;
        })
      },
      renderMark: {
        [ITALIC]: (text: ReactNode) => {
          return renderers?.italic ? renderers?.italic(text) : <i>{text}</i>;
        },
        [BOLD]: (text: ReactNode) => {
          return renderers?.bold ? renderers?.bold(text) : <strong>{text}</strong>;
        }
      }
    })}
        </div>;
};
export const CUSTOM_IMAGE = 'custom-image';
export const SANITIZED_HTML = 'sanitized-html';
export type CustomImageBlock = Node & {
  nodeType: typeof CUSTOM_IMAGE;
  content: [];
  data: {
    alt: string;
    src: string;
  };
};
export type SanitizedHtmlBlock = Node & {
  nodeType: typeof SANITIZED_HTML;
  content: [];
  data: {
    html: string;
  };
};
type SupportedNodeTypes = {
  [HEADING_1]: Heading1;
  [HEADING_2]: Heading2;
  [HEADING_3]: Heading3;
  [HEADING_4]: Heading4;
  [HR]: Hr;
  [HYPERLINK]: Hyperlink;
  [LIST_ITEM]: ListItem;
  [OL_LIST]: OrderedList;
  [PARAGRAPH]: ParagraphNode;
  [UL_LIST]: UnorderedList;
  [CUSTOM_IMAGE]: CustomImageBlock;
  [SANITIZED_HTML]: SanitizedHtmlBlock;
  [TABLE]: Table;
  [TABLE_ROW]: TableRow;
  [TABLE_CELL]: TableCell;
};
type TypedNodeRenderer<T extends keyof SupportedNodeTypes> = (node: SupportedNodeTypes[T], children: ReactNode) => ReactNode;
function typedNodeRenderer<T extends keyof SupportedNodeTypes, N extends SupportedNodeTypes[T], R extends TypedNodeRenderer<T>>(type: T, renderer: R, customRenderer?: R) {
  return ((node: N, children: ReactNode) => {
    if (node.nodeType !== type) return null;
    if (customRenderer) return customRenderer(node, children);
    return renderer(node, children);
  }) as NodeRenderer;
}
export type DocumentWithLinks = {
  json?: Document;
  links?: {
    entries: {
      block: EntryMeta[];
    };
    assets?: {
      block: AssetMeta[];
    };
  };
};
export type CustomRenderers = {
  /**
   * Custom string rendered for text
   */
  text?: (text: string) => ReactNode;

  /**
   * Custom renderer for bold text
   */
  bold?: (text: ReactNode) => ReactNode;

  /**
   * Custom renderer for italic text
   */
  italic?: (text: ReactNode) => ReactNode;

  /**
   * Custom node renderer for @see BLOCKS.PARAGRAPH from @contentful/rich-text-types
   */
  paragraph?: TypedNodeRenderer<typeof PARAGRAPH>;

  /**
   * Custom node renderer for @see BLOCKS.OL_LIST from @contentful/rich-text-types
   */
  ol?: TypedNodeRenderer<typeof OL_LIST>;

  /**
   * Custom node renderer for @see BLOCKS.UL_LIST from @contentful/rich-text-types
   */
  ul?: TypedNodeRenderer<typeof UL_LIST>;

  /**
   * Custom node renderer for @see BLOCKS.HEADING_1 from @contentful/rich-text-types
   */
  heading1?: TypedNodeRenderer<typeof HEADING_1>;

  /**
   * Custom node renderer for @see BLOCKS.HEADING_2 from @contentful/rich-text-types
   */
  heading2?: TypedNodeRenderer<typeof HEADING_2>;

  /**
   * Custom node renderer for @see BLOCKS.HEADING_3 from @contentful/rich-text-types
   */
  heading3?: TypedNodeRenderer<typeof HEADING_3>;

  /**
   * Custom node renderer for @see BLOCKS.HEADING_4 from @contentful/rich-text-types
   */
  heading4?: TypedNodeRenderer<typeof HEADING_4>;

  /**
   * Custom node renderer for @see INLINES.HYPERLINK from @contentful/rich-text-types
   */
  link?: TypedNodeRenderer<typeof HYPERLINK>;

  /**
   * Custom node renderer for @see BLOCKS.LIST_ITEM from @contentful/rich-text-types
   */
  listItem?: TypedNodeRenderer<typeof LIST_ITEM>;

  /**
   * Custom node renderer for @see BLOCKS.HR from @contentful/rich-text-types
   */
  hr?: TypedNodeRenderer<typeof HR>;
  /**
   * Custom node renderer for @see BLOCKS.TABLE from @contentful/rich-text-types
   */
  table?: TypedNodeRenderer<typeof TABLE>;

  /**
   * Custom node renderer for @see BLOCKS.TABLE_ROW from @contentful/rich-text-types
   */
  tableRow?: TypedNodeRenderer<typeof TABLE_ROW>;

  /**
   * Custom node renderer for @see BLOCKS.TABLE_CELL from @contentful/rich-text-types
   */
  tableCell?: TypedNodeRenderer<typeof TABLE_CELL>;

  /**
   * Custom Receipt renderer
   */
  receipt?: (receipt: EntryMeta) => ReactNode;
};