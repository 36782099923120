import { IntercomScreen } from '@getstep/sdk/dist/store/intercom/Screens'
import { action, makeAutoObservable } from 'mobx'

export const helpCenterStore = makeAutoObservable(
    {
        screen: IntercomScreen.HOME,
        setScreen(screen: IntercomScreen) {
            this.screen = screen
        },
    },
    { setScreen: action },
    { autoBind: true }
)
