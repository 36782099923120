import { Receipt } from '../../contentful-components/receipt';
import type { CustomRenderers } from '../../contentful-components/rich-text/render';
import { renderDocumentWithLinks } from '../../contentful-components/rich-text/render';
import type { Personalizations } from '../../lib/hooks/usePersonalizations';
import { usePersonalizations } from '../../lib/hooks/usePersonalizations';
import type { ModalSize } from '../../lib/store/ui/modal';
import { renderTemplate } from '../../lib/utils/text';
import type { ContentfulClient } from '../client';
import { firstFromCollection } from '../collection';
import type { ModalFragment } from '../fragments/ModalFragment.graphql';
import { makeQueryFunction, makeUseQueryHook } from '../functions';
import { EMPTY_MODAL } from '../placeholders';
import { GetModalDocument } from '../queries/GetModal.graphql';
export type ModalForUI = {
  id: string;
  size: ModalSize;
  title: string;
  primary: string;
  secondary: string;
  body: JSX.Element | null;
  hasCloseButton?: boolean;
};
export enum CustomModalId {
  KycAlreadySignedUp = 'kyc.already-signed-up',
  LoginConfirmPhone = 'login.confirm-phone',
  TransactionFees = 'pay.fees',
  RequestExpired = 'pay.request-expired',
  PaymentFailed = 'pay.payment-failed',
  FundingSkipWarning = 'funding.skip-warning',
}
const modalToUI = ({
  sys: {
    id
  },
  title = '',
  primary = '',
  secondary = '',
  hasCloseButton,
  size,
  body
}: ModalFragment, personalizations: Personalizations): ModalForUI => {
  // setup a custom renderer for the receipt so we can pass in the personalizations
  const renderers: CustomRenderers = {
    receipt: receipt => <Receipt {...receipt} personalizations={personalizations} />
  };
  return {
    id,
    size: size !== undefined ? size as ModalSize : 'medium',
    hasCloseButton,
    title: renderTemplate(title, personalizations),
    primary: renderTemplate(primary, personalizations),
    secondary: renderTemplate(secondary, personalizations),
    body: body?.content ? renderDocumentWithLinks(body.content, renderers, undefined, personalizations) : null
  };
};
export const useModal = (customId: CustomModalId) => {
  const result = useGetModal({
    variables: {
      customId
    }
  });
  const personalizations = usePersonalizations();
  return {
    ...result,
    modal: modalToUI(firstFromCollection(result.data?.modals, EMPTY_MODAL), personalizations)
  };
};
export const getModal = async (client: ContentfulClient, customId: CustomModalId, personalizations: Personalizations) => {
  const result = await getModalInternal(client, {
    variables: {
      customId
    }
  });
  return {
    ...result,
    modal: modalToUI(firstFromCollection(result.data?.modals, EMPTY_MODAL), personalizations)
  };
};
const useGetModal = makeUseQueryHook({
  query: GetModalDocument
});
const getModalInternal = makeQueryFunction({
  query: GetModalDocument
});