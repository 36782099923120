import { makeAutoObservable } from 'mobx'
import Router from 'next/router'
import nProgress from 'nprogress'

Router.events.on('routeChangeStart', () => {
    progressStore.setRouting(true)
})

Router.events.on('routeChangeComplete', () => {
    progressStore.setRouting(false)
})

export const progressStore = makeAutoObservable(
    {
        routing: false,
        loading: false,

        get busy(): boolean {
            return this.loading || this.routing
        },

        setRouting(routing: boolean) {
            this.routing = routing
        },

        setLoading(loading: boolean) {
            this.loading = loading
        },

        withGlobalLoading<T>(action: () => Promise<T>) {
            return async () => {
                try {
                    nProgress.start()
                    this.setLoading(true)
                    await action()
                } finally {
                    this.setLoading(false)
                    nProgress.done()
                }
            }
        },
    },
    {},
    { autoBind: true }
)
