import invert from 'lodash/invert'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

import { CustomFields } from '../../lib/form/fields'
import type {
    CustomField,
    CustomFieldId,
    ValidationErrorCode,
} from '../../lib/form/fields'
import type { Personalizations } from '../../lib/hooks/usePersonalizations'
import { usePersonalizations } from '../../lib/hooks/usePersonalizations'
import { renderTemplate } from '../../lib/utils/text'
import { EMPTY_COLLECTION } from '../placeholders'

// re-export the types for all the things that reference this file
export { CustomFieldId } from '../../lib/form/fields'

export interface FieldForUI<T extends CustomFieldId = CustomFieldId> {
    label?: string
    placeholder: string
    customId: T
    errorTexts: { [key in ValidationErrorCode]: string }
}

export function useFields<T extends { [key: string]: CustomFieldId }>(
    config: T
): { [K in keyof T]: FieldForUI<T[K]> } {
    const inverted: Record<CustomFieldId, string> = invert(config) as any

    const personalizations = usePersonalizations()

    const fieldArray = Object.entries(CustomFields).map(([, value]) => value)

    const fields = fieldArray
        .filter((f) => f.customId && f.customId in inverted)
        .map((field) => [
            inverted[field.customId as CustomFieldId],
            toUIField(field.customId as CustomFieldId, personalizations, field),
        ])

    const defaults = Object.fromEntries(
        Object.keys(config).map((key) => [
            key,
            toUIField(config[key], personalizations),
        ])
    )

    return { ...defaults, ...Object.fromEntries(fields || []) }
}

/**
 * Like useFields, but for one id.
 */
export function useField<T extends CustomFieldId>(customId: T): FieldForUI<T> {
    const fields = useFields({ [customId]: customId })

    return fields[customId]
}

const EMPTY_UI_FIELD: Omit<FieldForUI, 'customId'> = {
    placeholder: '',
    label: '',
    errorTexts: {
        required: '',
        invalidCode: '',
        min: '',
        max: '',
        maxLength: '',
        minLength: '',
        pattern: '',
        validate: '',
        adblocker: '',
        connectionFailure: '',
        resourceExhausted: '',
    },
}

function toUIField<T extends CustomFieldId>(
    customId: T,
    personalizations: Personalizations,
    field?: CustomField
): FieldForUI<T> {
    if (!field) {
        return { ...EMPTY_UI_FIELD, customId }
    }

    const { errors = EMPTY_COLLECTION, placeholder = '', label = '' } = field

    const errorsByCode = keyBy(
        errors.items.filter((e) => e.code),
        (i) => i.code ?? ''
    )

    const errorTexts = mapValues(errorsByCode, (v) =>
        renderTemplate(v.message, personalizations)
    ) as FieldForUI['errorTexts']

    return {
        label: renderTemplate(label, personalizations),
        placeholder: renderTemplate(placeholder, personalizations),
        customId,
        errorTexts,
    }
}
