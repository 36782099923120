import classNames from 'classnames';
import Link from 'next/link';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useEffect, useRef } from 'react';
import { tracker } from '../../lib/store/tracker/useTracker';
import { scrollTo } from '../../lib/utils/scroll';
import type { WithTestId } from '../../lib/utils/testid';
import { addAnchor } from '../../shared/util/link';
import type { ButtonTrackingProps } from '../button/props';
import styles from './anchor.module.scss';
const track = ({
  analyticsKey,
  children
}: PropsWithChildren<CommonProps>) => tracker.clickLink(analyticsKey || (typeof children === 'string' ? children : ''));

/** Next.js link with styling. */
export const Anchor: FunctionComponent<PropsWithChildren<AnchorProps>> = props => {
  const {
    children,
    href,
    preset = 'default',
    as,
    onClick,
    className,
    ...restOfProps
  } = props;
  return <Link href={href} as={as} legacyBehavior data-sentry-element="Link" data-sentry-component="Anchor" data-sentry-source-file="index.tsx">
            <a className={classNames(styles[preset], className)} onClick={e => {
      onClick?.(e);
      track(props);
    }} {...restOfProps}>
                <Wrapper preset={preset} data-sentry-element="Wrapper" data-sentry-source-file="index.tsx">{children}</Wrapper>
            </a>
        </Link>;
};
interface CommonProps extends WithTestId<ButtonTrackingProps> {
  /** Extra classname for extra special cases. */
  className?: string;
  /** Other presets may be added here if the need arises. */
  preset?: 'plain' | 'default';
  /** If not provided, name will be used. */
  analyticsKey?: string;
}
interface AnchorProps extends Omit<JSX.IntrinsicElements['a'], 'className' | 'href'>, CommonProps {
  /** Url or path of the link. For dynamic routes this should be a route. */
  href: string;
  /** Actual href, applicable for dynamic routes only. */
  as?: string;
}

/** Non-Next.js link with styling. */
export const RawAnchor: FunctionComponent<PropsWithChildren<RawAnchorProps>> = props => {
  const {
    children,
    className,
    href,
    onClick,
    preset = 'default',
    ...restOfProps
  } = props;
  return <a className={classNames(styles[preset], className)} href={href} onClick={e => {
    onClick?.(e);
    track(props);
  }} {...restOfProps} data-sentry-component="RawAnchor" data-sentry-source-file="index.tsx">
            <Wrapper preset={preset} data-sentry-element="Wrapper" data-sentry-source-file="index.tsx">{children}</Wrapper>
        </a>;
};
const Wrapper: FunctionComponent<PropsWithChildren<Pick<CommonProps, 'preset'>>> = ({
  children,
  preset
}) => preset === 'default' ? <span className={styles.inner}>{children}</span> : <>{children}</>;
type RawAnchorProps = Omit<JSX.IntrinsicElements['a'], 'className'> & CommonProps;

/** Non-Next.js link within a page that scrolls to id. */
export const ScrollAnchor: FunctionComponent<PropsWithChildren<ScrollAnchorProps>> = props => {
  const {
    children,
    to,
    className,
    preset = 'default',
    ...restOfProps
  } = props;
  const target = useRef<HTMLElement>();
  useEffect(() => {
    if (target.current) return;
    const node = document.getElementById(to.replace('#', ''));
    if (node) target.current = node;
  }, []);
  return <a className={classNames(styles[preset], className)} href={to} onClick={e => {
    const root = target.current;
    if (!root) return;
    e.preventDefault();
    scrollTo(root, {
      top: root.offsetTop - 100,
      behavior: 'smooth'
    });
    track(props);
    setTimeout(() => window.location.replace(addAnchor(window.location.pathname, to)), 100);
  }} {...restOfProps} data-sentry-component="ScrollAnchor" data-sentry-source-file="index.tsx">
            <Wrapper preset={preset} data-sentry-element="Wrapper" data-sentry-source-file="index.tsx">{children}</Wrapper>
        </a>;
};
interface ScrollAnchorProps extends CommonProps {
  /** ID of the element with leading # */
  to: string;
}