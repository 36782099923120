import type { DefaultSeoProps, OrganizationJsonLdProps } from 'next-seo';
import { DefaultSeo, OrganizationJsonLd } from 'next-seo';
import type { Place } from 'next-seo/lib/jsonld/jobPosting';
import type { FunctionComponent } from 'react';
import { TEXT } from '../../lib/utils/text';
import { LocalLink } from '../../shared/util/link';

/**
 * Sets the default contents of the page's <title> and various <meta> tags.
 * To use in _app.tsx only. Use <PageMeta /> for dynamic stuff.
 */
export const DefaultMeta: FunctionComponent = () => <>
        <DefaultSeo {...DEFAULT_META} data-sentry-element="DefaultSeo" data-sentry-source-file="default-meta.tsx" />
        <OrganizationJsonLd {...organization} data-sentry-element="OrganizationJsonLd" data-sentry-source-file="default-meta.tsx" />
    </>;
export const DEFAULT_OG_IMAGE = new LocalLink({
  base: '/assets/step-og.jpg'
}).serialize(undefined, {
  canonical: true
});
export const DEFAULT_META: DefaultSeoProps = {
  title: TEXT.defaultTitle,
  description: TEXT.defaultDescription,
  openGraph: {
    type: 'website',
    locale: 'en_US',
    title: TEXT.defaultOpenGraphTitle,
    description: TEXT.defaultOpenGraphDescription,
    images: [{
      url: DEFAULT_OG_IMAGE,
      width: 1200,
      height: 630,
      alt: TEXT.defaultTitle
    }]
  },
  facebook: {
    appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID ?? ''
  },
  twitter: {
    cardType: 'summary_large_image',
    site: '@step'
  },
  additionalMetaTags: [{
    name: 'theme-color',
    content: '#222234'
  }, {
    name: 'viewport',
    content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
  }, {
    name: 'facebook-domain-verification',
    content: 'xoedfnjptcbqfjuuml3pb26v58g5vp'
  }],
  additionalLinkTags: [{
    rel: 'preconnect',
    href: 'https://consent.cookiebot.com'
  }, {
    rel: 'preconnect',
    href: 'https://assets.ctfassets.net'
  }, {
    rel: 'preconnect',
    href: 'https://images.ctfassets.net'
  }, {
    rel: 'dns-prefetch',
    href: 'https://graphql.contentful.com'
  }, {
    rel: 'preconnect',
    href: 'https://assets.step.com'
  }, {
    rel: 'icon',
    type: 'image/x-icon',
    href: '/favicon.ico'
  }, {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/apple-touch-icon.png'
  }, {
    rel: 'manifest',
    href: '/manifest.json'
  }
  /**
   * Preload the asset fonts
   */
  // {
  //     rel: 'preload',
  //     href: 'https://assets.step.com/fonts/F37Judge-RegularCompressed.woff2',
  //     as: 'font',
  //     type: 'font/woff2',
  //     crossOrigin: 'anonymous',
  // },
  // {
  //     rel: 'preload',
  //     href: 'https://assets.step.com/fonts/F37Judge-BoldCondensed.woff2',
  //     as: 'font',
  //     type: 'font/woff2',
  //     crossOrigin: 'anonymous',
  // },
  // {
  //     rel: 'preload',
  //     href: 'https://assets.step.com/fonts/CeraPro-Regular.woff2',
  //     as: 'font',
  //     type: 'font/woff2',
  //     crossOrigin: 'anonymous',
  // },
  // {
  //     rel: 'preload',
  //     href: 'https://assets.step.com/fonts/CeraPro-Medium.woff2',
  //     as: 'font',
  //     type: 'font/woff2',
  //     crossOrigin: 'anonymous',
  // },
  // {
  //     rel: 'preload',
  //     href: 'https://assets.step.com/fonts/CeraPro-Bold.woff2',
  //     as: 'font',
  //     type: 'font/woff2',
  //     crossOrigin: 'anonymous',
  // },
  // {
  //     rel: 'preload',
  //     href: 'https://assets.step.com/fonts/CeraPro-Black.woff2',
  //     as: 'font',
  //     type: 'font/woff2',
  //     crossOrigin: 'anonymous',
  // },
  ]
};
const address: Place = {
  addressCountry: 'US',
  addressRegion: 'CA',
  streetAddress: '120 Hawthorne Avenue',
  postalCode: '94301',
  addressLocality: 'Palo Alto'
};
const organization: OrganizationJsonLdProps = {
  id: 'step',
  type: 'Corporation',
  name: 'Step',
  url: 'https://step.com',
  legalName: 'Step Mobile, Inc.',
  logo: 'https://step.com/assets/logo-dark.png',
  address,
  sameAs: ['https://step.com', 'https://www.instagram.com/stepmobile/', 'https://twitter.com/step', 'https://www.facebook.com/step', 'https://www.linkedin.com/company/stepmobile'],
  contactPoints: [{
    email: 'hello@step.com'
  }]
};
export const DEFAULT_JSON = {
  address,
  organization
};