import type { RefObject } from 'react'
import { createRef, useEffect } from 'react'

import { scrollTo } from '../utils/scroll'

export const useAnchorRef = (
    anchor?: string,
    onMatch?: () => void
): RefObject<any> => {
    const ref = createRef<HTMLElement>()

    useEffect(() => {
        const root = ref.current
        if (!anchor || !root) return

        if (window.location.hash.replace('#', '') !== anchor) return

        if (onMatch) onMatch()

        requestAnimationFrame(() => {
            scrollTo(root, {
                top: root.offsetTop - root.clientHeight,
                behavior: 'smooth',
            })
        })
    }, [anchor])

    return ref
}
