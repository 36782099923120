/**
 * Stores instances of the same type in its closure, initializes the storage on first call.
 * Storage can be anything that implements the `get` and `set` methods.
 *
 * @returns a function that can be used to get or create instances of the given type.
 * It initializes the storage on first call and creates a new instance of the type, if it isn't memoized yet.
 *
 * @param createStorage - underlying storage that implements the `get` and `set` methods.
 * @param createObject - function that creates a new object to store and return.
 */
export function memoized<T, K, A extends unknown[] = unknown[]>(
    createStorage: () => KeyValueStorage<K, T>,
    createObject: (key: K, ...args: A) => T
) {
    let storage: KeyValueStorage<K, T>

    return function getOrCreate(...args: Parameters<typeof createObject>): T {
        const [key] = args
        if (!storage) {
            storage = createStorage()
        }

        const stored = storage.get(key)
        if (stored) return stored

        const fresh = createObject(...args)
        storage.set(key, fresh)
        return fresh
    }
}

/**
 * Simple key-value cache.
 */
export interface KeyValueStorage<Key, Value> {
    get(id: Key): Value | undefined
    set(id: Key, value: Value): void
}
