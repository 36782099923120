import { DownloadApp } from '../../../../../contentful-components/forms/download-app';
import { TEXT } from '../../../../../lib/utils/text';
import { GetStepButton } from '../../../../get-step-button';
import { Desktop, Mobile } from '../../../../responsive';
export const DownloadStepAction = () => {
  return <>
            <Mobile data-sentry-element="Mobile" data-sentry-source-file="index.tsx">
                <GetStepButton size='small' data-sentry-element="GetStepButton" data-sentry-source-file="index.tsx" />
            </Mobile>
            <Desktop data-sentry-element="Desktop" data-sentry-source-file="index.tsx">
                <DownloadApp analyticsKey='getApp.button' placement='header' title={TEXT.getStarted} size='medium' preset='futureFuscia' data-sentry-element="DownloadApp" data-sentry-source-file="index.tsx" />
            </Desktop>
        </>;
};