import { makeAutoObservable } from 'mobx'

export const webviewStore = makeAutoObservable(
    {
        isWebview: false,
        setIsWebview(value: boolean) {
            this.isWebview = value
        },
    },
    {},
    { autoBind: true }
)
