import { makeAutoObservable } from 'mobx'

import type { HeaderActionType } from '../../../components/layout/header/actions/header-action-button'

export const navigationStore = makeAutoObservable(
    {
        /**
         *  Header / CTA Button State
         */
        action: 'DOWNLOAD_STEP' as HeaderActionType,
        /**
         * Menu Open State
         */
        isOpen: false,
        /**
         * Navigation Links
         */
        showLinks: true,
        /**
         * Make all changes with one setter
         */
        set({
            isOpen,
            action,
            showLinks,
        }: {
            isOpen?: boolean
            action?: HeaderActionType
            showLinks?: boolean
        }) {
            this.isOpen = isOpen ?? this.isOpen
            this.action = action ?? this.action
            this.showLinks = showLinks ?? this.showLinks
        },

        /**
         * Reset to default state
         */
        reset() {
            this.set({
                isOpen: false,
                action: 'DOWNLOAD_STEP',
                showLinks: true,
            })
        },
    },
    {},
    { autoBind: true }
)
