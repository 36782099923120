import { useSpring } from '@react-spring/web'
import type { DependencyList } from 'react'
import { useEffect, useState } from 'react'

/**
 * Wrapper for shaking animation. Useful to indicate inputs in invalid state.
 */
export const useShakeAnimation = (
    { amplitude, enabled }: ShakeProps,
    dependencies: DependencyList
) => {
    const [shaking, setShaking] = useState(false)

    useEffect(() => {
        if (enabled) {
            setShaking(true)
            setTimeout(() => setShaking(false), 100)
        }
    }, dependencies)

    const shake = useSpring({ x: shaking ? 1 : 0 })

    const amplitudes = {
        medium: [0, 10, -10, 5, -5, 0],
        small: [0, 5, -5, 1, -1, 0],
    }

    return {
        transform: shake.x
            .to({
                range: [0, 0.2, 0.4, 0.6, 0.8, 1],
                output: amplitudes[amplitude],
            })
            .to((x) => `translate(${x}px)`),
    }
}

interface ShakeProps {
    /** Use 'small' for small objects, otherwise the animation will be...annoying. */
    amplitude: 'small' | 'medium'
    /** Whether to animate the object. NOTE: dependency change is required to restart the shake. */
    enabled: boolean
}
