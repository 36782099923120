import { makeAutoObservable } from 'mobx'

const noop = () => Promise.resolve()

export const nullTracker = makeAutoObservable({
    page: '',
    userId: '',
    sendSMS: noop,
    pageView: noop,
    clickButton: noop,
    clickLink: noop,
    click: noop,
    videoPlayed: noop,
    videoPaused: noop,
    videoEnded: noop,
    login: noop,
    familyJoined: noop,
    formSubmitSuccess: noop,
    kycSuccess: noop,
    inputFocused: noop,
    modalShown: noop,
    snackbarShown: noop,
    experimentsStarted: noop,
    setConsent: noop,
    setStore: noop,
    setAuthorizedStore: noop,
    linkShared: noop,
    typeformSubmitSuccess: noop,

    async identify(): Promise<void> {
        return
    },
})
