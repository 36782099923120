import type { IconComponent } from '../types';

/**
 * Renders an x icon.
 */
export const Close: IconComponent = ({
  width,
  height,
  ...restOfProps
}) => <svg width={width} height={height} viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...restOfProps} data-sentry-element="svg" data-sentry-component="Close" data-sentry-source-file="index.tsx">
        <path stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' d='M14.142 2.414 1.414 15.142M14.142 15.142 1.414 2.414' data-sentry-element="path" data-sentry-source-file="index.tsx" />
    </svg>;