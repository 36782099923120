import { AssertionFailure } from '@getstep/sdk/dist/util/Assert'

export type Collection<T = Record<string, unknown>> = { items: Array<T> }
export type SingleElementCollection<T = Record<string, unknown>> = {
    items: [T] | []
}

/**
 * Returns first element from Collection<>.
 * Make sure collection is loaded before calling this.
 * Missing element will cause an error.
 */
export function firstFromCollection<T, D = T>(
    collection?: Collection<T> | SingleElementCollection<T>,
    defaultValue?: D
): T | D {
    const value = collection?.items?.[0]
    if (typeof defaultValue !== 'undefined') return value ?? defaultValue

    if (!value) {
        throw new AssertionFailure('First element in collection is undefined')
    }
    return value
}
