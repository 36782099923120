import LRUCache from 'lru-cache'

import type { IsomorphicStore } from './IsomorphicStore'

export class CacheStore implements IsomorphicStore {
    cache = new LRUCache({ max: 100 })

    static create(): CacheStore {
        return new CacheStore()
    }

    get(key: string) {
        return this.cache.get(key)
    }

    has(key: string) {
        return this.cache.has(key)
    }

    set(key: string, value: any) {
        return this.cache.set(key, value)
    }

    restore(state: CacheState): void {
        this.cache.load(state.entries)
    }

    toJSON(): CacheState | undefined {
        return { entries: this.cache.dump() }
    }
}

export type CacheState = { entries: Array<[unknown, LRUCache.Entry<unknown>]> }
