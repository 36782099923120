import { inspect } from 'util'

export const inspectEnv = () => inspect(process.env, true, 2, true)

export const isPrd = () =>
    process.env.NEXT_PUBLIC_PUBLIC_URL === 'https://step.com'

export const isE2E = () => process.env.SENTRY_ENVIRONMENT === 'e2e'

export const isBrowser = () => typeof window !== 'undefined'

export const isTests = () => typeof jest !== 'undefined'

export const isPreviewMode = () =>
    process.env.NEXT_PUBLIC_CONTENTFUL_INCLUDE_PREVIEWS !== 'false'

export const isAStepDomain = (host: string) =>
    host.match(
        /^https:\/\/step\.com$|^https:\/\/(dev|stg)\.step\.com$|^http:\/\/local\.step\.com:3000$/
    ) !== null
