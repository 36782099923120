import classNames from 'classnames';
import type { BrandColor } from '../../../types/color';
import type { Direction } from '../../../types/direction';
import type { IconComponent } from '../types';
import styles from './chevron.module.scss';

/** `^` Symbol */
export const Chevron: IconComponent<Props> = ({
  color,
  width,
  animated,
  height,
  direction,
  ...restOfProps
}) => <svg className={classNames(styles.chevron, styles[direction], styles[`fill-${color}`], {
  [styles.animated]: animated
})} width={width} height={height} viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg' {...restOfProps} data-sentry-element="svg" data-sentry-component="Chevron" data-sentry-source-file="index.tsx">
        <path d='M1.707.293A1 1 0 00.293 1.707L1.707.293zM7.5 7.5l-.707.707a1 1 0 001.414 0L7.5 7.5zm7.207-5.793A1 1 0 0013.293.293l1.414 1.414zm-14.414 0l6.5 6.5 1.414-1.414-6.5-6.5L.293 1.707zm7.914 6.5l6.5-6.5L13.293.293l-6.5 6.5 1.414 1.414z' data-sentry-element="path" data-sentry-source-file="index.tsx" />
    </svg>;
interface Props {
  /** Apply animated transitions */
  animated?: boolean;
  /** Color of the chevron */
  color?: BrandColor | 'currentColor';
  /** Where the pointy side is directed */
  direction: Direction;
}