import classnames from 'classnames';
import type { FunctionComponent, HTMLAttributes } from 'react';
import type { BrandColor } from '../../types/color';
import type { VerticalSide } from '../../types/styles';
import { Skeleton } from '../skeleton';
import styles from './paragraph.module.scss';

/**
 * Displays paragraph content with appropriate margins.
 * Accepts all the usual props of `<p>`.
 */
export const Paragraph: FunctionComponent<React.PropsWithChildren<Props | SkeletonProps>> = props => {
  if ('loading' in props) {
    const {
      numberOfLines = 3,
      pad
    } = props;
    return <ParagraphSkeleton loading pad={pad} numberOfLines={numberOfLines} />;
  }
  const {
    children,
    color,
    pad = ['bottom'],
    size = 'medium',
    className,
    ...restOfProps
  } = props;
  return <p className={classnames(styles.paragraph, styles[color || ''], styles[size], className, ...pad.map(p => styles[`pad-${p}`]))} {...restOfProps} data-sentry-component="Paragraph" data-sentry-source-file="index.tsx">
            {children}
        </p>;
};
const ParagraphSkeleton: FunctionComponent<React.PropsWithChildren<SkeletonProps>> = ({
  numberOfLines,
  pad = []
}) => Array.from(Array(numberOfLines), (_, index) => <Skeleton tag='p' key={index} className={classnames(styles.paragraph, styles.skeleton, ...pad.map(p => styles[`pad-${p}`]))} />) as any;
interface SkeletonProps extends Partial<Props> {
  numberOfLines?: number;
  loading: true;
}
interface Props extends Omit<HTMLAttributes<HTMLParagraphElement>, 'className' | 'color'> {
  color?: BrandColor;
  pad?: VerticalSide[];
  size?: 'medium' | 'small';
  /** Extra classname for extra special cases. */
  className?: string;
}