import classNames from 'classnames';
import type { FunctionComponent, ImgHTMLAttributes } from 'react';
import { forwardRef, useCallback, useEffect, useRef } from 'react';
import type { WithTestId } from '../../lib/utils/testid';
import type { ImageContentType, ImageType } from '../../types/image';
import styles from './image.module.scss';

/** Displays an image with a placeholder in place. while loading.
 * Fits the image into provided container in various ways (see props).
 * Accepts all the usual props of `<img>`.
 */
export const Image: FunctionComponent<React.PropsWithChildren<Props>> = forwardRef<HTMLImageElement, Props>((props, ref) => {
  const {
    source,
    source2x,
    source3x,
    role,
    fit = 'cover',
    alt,
    contentType,
    lazy = true,
    className,
    width,
    height,
    'data-testid': testId,
    onLoad
  } = props;
  const imageRef = useRef<HTMLImageElement | null>(null);

  // on render, if images is cached, invoke the onLoad callback
  useEffect(() => {
    if (imageRef?.current?.complete && onLoad) onLoad();
  }, []);
  const setRef = useCallback((element: HTMLImageElement) => {
    imageRef.current = element;
    if (ref) {
      if ('current' in ref) ref.current = element;else ref(element);
    }
  }, []);
  const imgProps = {
    'data-testid': testId,
    alt,
    loading: lazy ? 'lazy' as const : undefined,
    ref,
    width,
    height,
    role
  };

  // check for source, if it is not provided, return null
  if (!source) {
    console.error('Image component requires a source');
    return null;
  }

  // Only render the <picture> tag if there's something to be gained from it.
  if (isSVG(props) || !(isConvertibleToWebP(props) || isMultiSource(props))) {
    return <img {...imgProps} src={source} ref={setRef} className={classNames(styles.image, styles[fit], className)} />;
  }
  return <picture style={{
    width,
    height
  }} className={classNames(styles.picture, className)}>
                {contentType && CONVERTIBLE_TO_WEBP.includes(contentType) && <source srcSet={asSourceSet({
      source,
      source2x,
      source3x
    }, true)} type='image/webp' />}
                <source srcSet={asSourceSet({
      source,
      source2x,
      source3x
    })} type={contentType} />
                <img {...imgProps} src={source} ref={setRef} onLoad={onLoad} className={classNames(styles.image, styles[fit])} />
            </picture>;
});
function isMultiSource({
  source2x,
  source3x
}: Props) {
  return Boolean(source2x || source3x);
}
function isConvertibleToWebP({
  contentType
}: Props) {
  if (!contentType) return false;
  return CONVERTIBLE_TO_WEBP.includes(contentType);
}
function isSVG({
  contentType
}: Props) {
  return contentType === 'image/svg+xml';
}
export function asSourceSet(sources: Pick<ImageType, 'source' | 'source2x' | 'source3x'>, asWebP = false): string | undefined {
  const {
    source,
    source2x,
    source3x
  } = sources;
  if (!source2x && !source3x) return;
  return [[source, '1x'], [source2x, '2x'], [source3x, '3x']].filter(([value]) => value).map(([key, value]) => {
    if (asWebP) {
      return `${key}${key?.includes('?') ? '&' : '?'}fm=webp ${value}`;
    }
    return `${key} ${value}`;
  }).join(', ');
}
interface Props extends WithTestId<ImageType>, Pick<ImgHTMLAttributes<HTMLImageElement>, 'width' | 'height' | 'onLoad'> {
  /** Extra className for extra styling. For emergencies only. */
  className?: string;
  /** How the image fills its container.  */
  fit?: 'cover' | 'contain' | 'stretch';
  /** If true, image is loaded in low prio and only when in view. Defaults to true. */
  lazy?: boolean;
  /** For accessibility */
  role?: string;
  /** callback invoked when image is loaded */
  onLoad?: () => void;
}
const CONVERTIBLE_TO_WEBP: ImageContentType[] = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];