// This file configures the initialization of Sentry for edge features (middleware, edge routes, and so on).
// The config you add here will be used whenever one of the edge features is loaded.
// Note that this config is unrelated to the Vercel Edge Runtime and is also required when running locally.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { BrowserOptions } from '@sentry/nextjs'

import { isDevelopment } from './src/shared/util'
import {  isE2E, isPrd } from './src/shared/util/env'

const dsn =
    process.env.SENTRY_DSN ||
    "https://8757ab63d5a842d69add259495c1327a@o177913.ingest.us.sentry.io/1385134"

export const config: BrowserOptions = {
  dsn,
  enabled: !isDevelopment() && !isE2E(),
  environment: process.env.SENTRY_ENVIRONMENT,
  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: isPrd() ? 0.05 : undefined,
  // ignore api routes
  ignoreTransactions: [/^\/api(\/.*)?$/],

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
}
