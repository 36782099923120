import material from './material.module.scss'
import rounded from './rounded.module.scss'

interface Preset {
    'container-medium': string
    'container-small': string
    'container-with-error': string
    'input-small': string
    'input-with-adornment': string
    'input-with-error': string
    'label-with-error': string
    adornment: string
    container: string
    error: string
    input: string
    label: string
    wrapper: string
    fullWidth: boolean
}

export const PRESETS = {
    material: material as any as Preset,
    rounded: rounded as any as Preset,
}
