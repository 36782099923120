import { extendConsole } from '../shared/logging/console'

const logger = extendConsole('intercom')

export class Intercom {
    static boot = (options?: Omit<IntercomOptions, 'app_id'>) => {
        const app_id = process.env.NEXT_PUBLIC_INTERCOM_APP_ID ?? ''

        if (!app_id) {
            logger.warn('Intercom app_id not set')
            return
        }

        const script = document.createElement('script')
        script.src = `https://widget.intercom.io/widget/${app_id}`
        document.body.appendChild(script)
        script.defer = true
        script.setAttribute('data-cookieconsent', 'ignore')

        script.onload = () => {
            window.Intercom('boot', {
                app_id,
                ...options,
            })
        }
    }
}

export interface IntercomOptions {
    app_id: string
    email?: string
    user_id?: string
    created_at?: number
}
