import noop from 'lodash/noop'
import * as polyfill from 'seamless-scroll-polyfill'

import { isBrowser } from '../../shared/util/env'

/**
 * Replacement for window.scrollTo
 */
export const scrollTo = isBrowser() ? polyfill.scrollTo : (noop as never)

/**
 * Replacement for window.scrollIntoView
 */
export const scrollIntoView = isBrowser()
    ? polyfill.scrollIntoView
    : (noop as never)
