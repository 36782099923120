import {
    ErrorCode,
    ErrorStatus,
    isHttpError,
} from '@getstep/sdk/dist/http/HttpError'
import { PublicApi } from '@getstep/sdk/dist/http/PublicApi'
import { captureException } from '@sentry/react'
import { observable } from 'mobx'

import { makeFetch } from '../../local-api'
import { digitsOnly } from '../../utils/number'
import { WaistlistRequestStatus } from '../../utils/waitlist'
import {
    makePersistentObservable,
    PersistentStore,
} from '../persistence/PersistentStore'
import { SdkClientStore } from '../SdkClientStore'

class WaitlistStore extends PersistentStore {
    static readonly className = 'WaitlistStore'
    static readonly persistentFields = ['joined', 'contactInfo']

    joined: Record<Waitlist, string[]> = {
        [Waitlist.SchoolRequest]: [],
        [Waitlist.StepBlack]: [],
    }

    contactInfo: ContactInfo = {
        name: '',
        email: '',
        phone: '',
    }

    api?: PublicApi

    constructor(readonly sdk: SdkClientStore) {
        super()

        makePersistentObservable(this, {
            joined: observable,
            contactInfo: observable.deep,
        })
    }

    get clients() {
        return {
            [Waitlist.SchoolRequest]: makeFetch(
                'https://step-school-request.firebaseio.com'
            ),
        }
    }

    private static createKeyFromContactInfo = (contactInfo: ContactInfo) => {
        return Object.values(contactInfo).join('-')
    }

    hasJoined(waitlist: Waitlist, key: string) {
        return this.joined[waitlist].includes(key)
    }

    setContactInfo(contactInfo: ContactInfo) {
        Object.assign(this, { contactInfo })
    }

    setJoined(waitlist: Waitlist, key: string) {
        this.joined[waitlist] = [...this.joined[waitlist], key]
    }

    async isWaitlistOpen(waitlist: Waitlist): Promise<boolean> {
        try {
            if (!this.api) {
                this.api = new PublicApi(await this.sdk.createHttpClient())
            }
            return await this.api.isWaitlistOpen(waitlist)
        } catch (error) {
            captureException(error)
            return false
        }
    }

    async joinWaitlist(waitlist: Waitlist, contactInfo: ContactInfo) {
        const key = WaitlistStore.createKeyFromContactInfo(contactInfo)

        if (this.hasJoined(waitlist, key)) return

        try {
            if (!this.api) {
                this.api = new PublicApi(await this.sdk.createHttpClient())
            }

            await this.api.unauthenticatedJoinWaitlist({
                waitlistId: waitlist,
                email: contactInfo.email.trim(),
                fullName: contactInfo.name,
                phoneNumber: contactInfo.phone
                    ? digitsOnly(contactInfo.phone)
                    : '',
            })

            this.setJoined(waitlist, key)
            this.setContactInfo(contactInfo)

            return {
                status: WaistlistRequestStatus.SUCCESS,
            }
        } catch (e) {
            if (!(e instanceof Error)) throw e

            if (isHttpError(e, { status: ErrorStatus.NOT_FOUND })) {
                return {
                    status: WaistlistRequestStatus.NOT_FOUND,
                }
            }

            if (
                isHttpError(e, {
                    status: ErrorStatus.RESOURCE_EXHAUSTED,
                    code: ErrorCode.RATE_LIMITED,
                })
            ) {
                return {
                    status: WaistlistRequestStatus.RATE_LIMITED,
                }
            }

            return {
                status: WaistlistRequestStatus.ERROR,
                error: e,
            }
        }
    }

    async joinWaitlistFirebase(waitlist: Waitlist, contactInfo: ContactInfo) {
        const key = WaitlistStore.createKeyFromContactInfo(contactInfo)

        if (this.hasJoined(waitlist, key)) return

        try {
            await this.clients[waitlist](`/waitlist.json`, {
                method: 'POST',
                body: {
                    ...contactInfo,
                    created_at: { '.sv': 'timestamp' },
                },
            })

            this.setJoined(waitlist, key)
            this.setContactInfo(contactInfo)
        } catch (e) {
            throw e
        }
    }
}

export enum Waitlist {
    SchoolRequest = 'school-request',
    StepBlack = 'STEP_BLACK',
}

/**
 * Store that manages sending contact info to the various waitlists.
 */
export const waitlistStore = new WaitlistStore(SdkClientStore.create())

export type ContactInfo = {
    name?: string
    email: string
    phone?: string
} & Record<string, string>
