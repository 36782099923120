import type { DependencyList } from 'react'
import { useEffect } from 'react'

/**
 * Add Async support to useEffect hook
 * Generally used for performing load operations etc,
 * this was created because useEffect does not allow you to return a promise
 *
 * @param operation the operation to perform async
 * @param deps If present, effect will only activate if the values in the list change.
 */
export const useEffectAsync = (
    operation: () => Promise<void>,
    deps?: DependencyList
) => {
    useEffect(() => {
        operation().then()
        // eslint-disable-next-line
    }, deps)
}
