import merge from 'lodash/merge';
import { observer } from 'mobx-react';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

/**
 * Values to use in various templates for various pages.
 */
export type Personalizations = {
  name?: string;
  sponseeName?: string;
  sponseeNamePossessive?: string;
  firstName?: string;
  amountFormatted?: string;
  creditCardFeeFormatted?: string;
  maskedCardNumber?: string;
  phoneSuffix?: string;
  expiresAt?: string;
  cardholderName?: string;
  email?: string;
  fullName?: string;
  displayName?: string;
  referredAwardFormatted?: string;
  referredAwardFormattedShort?: string;
  totalAmountFormatted?: string;
  pictureUrl?: string;
  username?: string;
  value?: string;
  phone?: string;
  jobTitle?: string;
  errorMessage?: string;
};

/**
 * Sets personalizations for various templates for everything inside it.
 * If there's another PersonalizationProvider above this one,
 * outer personalizations are merged with inner personalizations.
 */
export const PersonalizationProvider: FunctionComponent<PropsWithChildren<{
  value: Personalizations;
}>> = observer(({
  children,
  value
}) => {
  const outer = useContext(PersonalizationContext);
  return <PersonalizationContext.Provider value={merge({}, outer, value)}>
            {children}
        </PersonalizationContext.Provider>;
});

/**
 * Retrieves current personalizations.
 */
export const usePersonalizations = () => {
  return useContext(PersonalizationContext);
};
const PersonalizationContext = createContext<Personalizations>({});