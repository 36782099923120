import noop from 'lodash/noop'
import { makeAutoObservable } from 'mobx'
import type { ReactNode } from 'react'

import { getOrCreateContentfulClient } from '../../../graphql/client'
import type { CustomModalId } from '../../../graphql/hooks/modal'
import { getModal } from '../../../graphql/hooks/modal'
import type { Personalizations } from '../../hooks/usePersonalizations'
import { tracker } from '../tracker/useTracker'

const DEFAULT_NAME = 'modal'

export const DEFAULT_MODAL_CONTENT: ModalContent = {
    title: '',
    primary: '',
    secondary: '',
    body: '',
    hasCloseButton: true,
}

type ModalContent = {
    title: string
    body: ReactNode
    primary: string
    secondary?: string
    size?: ModalSize
    hasCloseButton?: boolean
}

export type ModalSize = 'small' | 'medium'

export type ModalState = {
    id: string
    name: string
    content: ModalContent
    visible: boolean
    onPrimary: () => void | Promise<void>
    onSecondary: () => void | Promise<void>
    onHide: () => void | Promise<void>
}

export const modalStore = makeAutoObservable({
    id: '',

    name: DEFAULT_NAME,

    content: DEFAULT_MODAL_CONTENT,

    visible: false,

    onPrimary: noop,

    onSecondary: noop,

    onHide: noop,

    reset() {
        this.visible = false
        this.name = DEFAULT_NAME
        this.content = DEFAULT_MODAL_CONTENT
        this.onPrimary = noop
        this.onSecondary = noop
        this.onHide = noop
    },

    async promptById(
        customId: CustomModalId,
        personalizations: Personalizations
    ) {
        const { modal } = await getModal(
            getOrCreateContentfulClient(),
            customId,
            personalizations
        )
        return this.prompt(modal, customId)
    },

    /**
     * Usage:
     *
     * ```ts
     * const boolean = await prompt(config)
     * if (boolean) doThis()
     * else doThat()
     * ```
     */
    async prompt(content: ModalContent, name: string) {
        return new Promise((resolve) => {
            this.visible = true
            this.content = content
            this.name = name
            tracker.modalShown(name)

            this.onPrimary = () => {
                this.reset()
                resolve(true)
            }

            this.onSecondary = () => {
                this.reset()
                resolve(false)
            }

            this.onHide = () => {
                this.reset()
                resolve(false)
            }
        })
    },
})
