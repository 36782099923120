import classNames from 'classnames';
import type { PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import type { AnimationStatus } from '../../../lib/hooks/useAnimation';
import { Spinner } from '../../spinner';
import styles from './content.module.scss';
export const ButtonContent = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(({
  animationStatus,
  loading,
  children
}, ref) => <>
        {animationStatus !== 'playing' && loading ? <Spinner size='small' /> : animationStatus !== 'playing' && children}
        <div ref={ref} className={classNames(styles.animation, animationStatus && styles[`animation-${animationStatus}`])} />
    </>);
interface Props {
  animationStatus?: AnimationStatus;
  loading?: boolean;
}