import produce from 'immer'
import merge from 'lodash/merge'

import { usePersonalizations } from '../../lib/hooks/usePersonalizations'
import { PaymentIntentStage } from '../../lib/store/p2p/PaymentIntentStage'
import { FundingStage } from '../../lib/utils/funding'
import { renderTemplate } from '../../lib/utils/text'
import { firstFromCollection } from '../collection'
import { makeUseQueryHook } from '../functions'
import { EMPTY_COLLECTION, EMPTY_FORM_PAGE } from '../placeholders'
import { GetFormPageDocument } from '../queries/GetFormPage.graphql'
import type { PageHookOptions } from './page'

export enum CustomFieldsetId {
    DownloadApp = 'download-app',
    LoginEnterPhone = 'login.enter-phone',
    LoginEnterCode = 'login.enter-code',
    LoginEnterEmail = 'login.enter-email',
    LoginEnterEmailCode = 'login.enter-email-code',
    LoginChooseSso = 'login.choose-sso',
    Kyc = 'kyc',
    KycEnterAddress = 'kyc.address',
    KycEnterPersonalInfo = 'kyc.personal',
    KycEnterLastFourSsn = 'kyc.last4ssn',
    KycEnterFullSsn = 'kyc.fullssn',
    PaymentContactInfo = 'payment.contact-info',
    PaymentCardInfo = 'payment.card-info',
    InvestingJoinWaitlist = 'investing.join-waitlist',
    SchoolRequestWaitlist = 'school-request.waitlist',
    StepBlackWaitlist = 'waitlist.step-black',

    FundingAmount = 'funding.amount',
    FundingCard = 'funding.card',
    FundingReview = 'funding.review',

    ProfilePayAmount = 'profile.pay.amount',
    ProfileConfirm = 'profile.pay.confirm',

    SavingsCalculator = 'savings-calculator',
}

/**
 * Fetches the right form for the payment intent stage.
 */
export const usePaymentFormPage = ({
    stage,
    enabled,
    personalizations,
}: Omit<PageHookOptions, 'slug'> & {
    stage?: PaymentIntentStage
}) =>
    useFormPage({
        slug: `pay/[tr]/${stage}`,
        includeBanner: false,
        enabled: enabled,
        personalizations,
        includeBody:
            stage &&
            [PaymentIntentStage.Success, PaymentIntentStage.Fail].includes(
                stage
            ),
    })

/**
 * Fetches the right form for the funding flow stage.
 */
export const useFundingFormPage = ({
    stage,
    isRecurring,
    enabled,
    personalizations,
}: Omit<PageHookOptions, 'slug'> & {
    stage: FundingStage
    isRecurring?: boolean
}) => {
    let slug = [FundingStage.Expired].includes(stage)
        ? `funding/${stage}`
        : `funding/[id]/${stage}`

    if (isRecurring) {
        slug = `${slug}/allowance`
    }

    return useFormPage({
        slug,
        includeBanner: false,
        enabled: enabled,
        personalizations,
        includeBody:
            stage &&
            [
                FundingStage.Success,
                FundingStage.Fail,
                FundingStage.Pending,
                FundingStage.Expired,
            ].includes(stage),
    })
}

/**
 * Returns postprocessed contents for a form page fo§r given slug.
 */
export const useFormPage = ({
    slug,
    enabled = true,
    includeBody = true,
    personalizations: personalizationsFromProps = {},
}: PageHookOptions) => {
    const personalizationsFromStore = usePersonalizations()

    const personalizations = merge(
        {},
        personalizationsFromStore,
        personalizationsFromProps
    )
    const result = useGetForm({
        variables: { slug, includeBody },
        pause: !enabled,
    })

    return {
        ...result,
        page: produce(
            firstFromCollection(result.data?.pages, EMPTY_FORM_PAGE),
            (draft) => {
                const {
                    banner,
                    receipt,
                    submit,
                    sections: { items: fragments } = EMPTY_COLLECTION,
                } = draft

                if (banner?.listWithIcons) {
                    for (const item of banner.listWithIcons.items) {
                        item.title = renderTemplate(
                            item.title,
                            personalizations
                        )
                    }
                }

                const sections = [
                    banner,
                    ...fragments.map((f) =>
                        f.type === 'Section' ? f : f.section
                    ),
                ]

                for (const section of sections) {
                    if (!section) continue

                    section.title = renderTemplate(
                        section.title,
                        personalizations
                    )

                    section.subtitle = renderTemplate(
                        section.subtitle,
                        personalizations
                    )
                }

                if (submit) {
                    submit.buttonText = renderTemplate(
                        submit.buttonText,
                        personalizations
                    )
                }

                if (receipt?.items) {
                    for (const item of receipt.items.items) {
                        item.term = renderTemplate(item.term, personalizations)
                        item.definition = renderTemplate(
                            item.definition,
                            personalizations
                        )
                    }
                }
            }
        ),
    }
}

const useGetForm = makeUseQueryHook({ query: GetFormPageDocument })
