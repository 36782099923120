import { makeAutoObservable } from 'mobx'

import type { ButtonTrackingProps } from '../../../components/button/props'
import type { ShareProps } from '../../../components/share-button/share-button'
import type { CustomFieldsetId } from '../../../graphql/hooks/form-page'
import { extendConsole } from '../../../shared/logging/console'
import type { AuthorizedStore } from '../authorized/AuthorizedStore'
import type { MobileOS } from '../DeviceStore'
import type { RootStore } from '../RootStore'
import { DataLayerTracker } from './DataLayer'
import { SegmentTracker } from './SegmentTracker'

export class ProxyTracker {
    static className = 'ProxyTracker'

    page = ''

    store?: RootStore

    authorizedStore?: AuthorizedStore

    private readonly segment = new SegmentTracker()
    private readonly dataLayer = new DataLayerTracker()

    constructor() {
        makeAutoObservable(this)
    }

    get className() {
        return ProxyTracker.className
    }

    get trackers() {
        return [this.segment, this.dataLayer]
    }

    get userId() {
        return this.segment.userId
    }

    set userId(userId: string | undefined) {
        this.segment.userId = userId
    }

    async pageView(page: string, properties?: Record<string, unknown>) {
        if (this.page === page) return
        this.page = page
        return this.run('pageView', page, properties)
    }

    sendSMS(phoneNumber: string, customMessage?: string) {
        let cleanedUpNumber = phoneNumber.replace(/[ ()-]/g, '')
        if (cleanedUpNumber.length === 10) {
            cleanedUpNumber = `+1${cleanedUpNumber}`
        }
        return this.run('sendSMS', cleanedUpNumber, customMessage)
    }

    familyJoined(familyId: string) {
        return this.run('familyJoined', familyId)
    }

    kycSuccess() {
        return this.run('kycSuccess')
    }

    formSubmitSuccess(
        name: CustomFieldsetId,
        properties?: Record<string, unknown>
    ) {
        return this.run('formSubmitSuccess', name, properties)
    }

    typeformSubmitSuccess(name: string) {
        return this.run('typeformSubmitSuccess', name)
    }

    modalShown(name: string) {
        return this.run('modalShown', name)
    }

    snackbarShown(name: string) {
        return this.run('snackbarShown', name)
    }

    inputFocused(name: string) {
        return this.run('inputFocused', name)
    }

    linkShared(properties: ShareProps) {
        return this.run('linkShared', properties)
    }

    clickButton(
        name: string,
        properties:
            | (ButtonTrackingProps & TrackerContext)
            | Record<string, unknown>
    ) {
        return this.run('clickButton', name, properties)
    }

    clickLink(key: string) {
        return this.run('clickLink', key)
    }

    click(
        type: TrackerClickType,
        name: string,
        properties?: Record<string, unknown>
    ) {
        return this.run('click', type, name, properties)
    }

    videoPlayed(name: string) {
        return this.run('videoPlayed', name)
    }

    videoPaused(name: string, secondsElapsed?: number) {
        return this.run('videoPaused', name, secondsElapsed)
    }

    videoEnded(name: string) {
        return this.run('videoEnded', name)
    }

    login(name: string) {
        return this.run('login', name)
    }

    experimentsStarted(experiments: Experiment[]) {
        return this.run('experimentsStarted', experiments)
    }

    async run<M extends keyof TrackerMethods>(
        method: M,
        ...args: Parameters<TrackerMethods[M]>
    ) {
        await this.each((tracker) =>
            (tracker[method] as any).apply(tracker, args)
        )
    }

    async identify(traits: TrackedTraits) {
        await this.each((tracker) => tracker.identify(traits))
    }

    async each(
        fn: (tracker: (typeof this)['trackers'][number]) => Promise<void>
    ) {
        try {
            await Promise.all(
                this.trackers.map(async (tracker) => {
                    if ('load' in tracker && !tracker.loaded) {
                        await tracker.load()
                    }
                    return fn(tracker)
                })
            )
        } catch (e) {
            logger.error(e)
        }
    }

    async setConsent(consent: boolean) {
        await this.each((tracker) => tracker.setConsent(consent))
    }

    setStore(store: RootStore) {
        this.store = store
        this.segment.setStore(store)
    }

    setAuthorizedStore(store: AuthorizedStore) {
        this.segment.setAuthorizedStore(store)
    }
}

const logger = extendConsole(ProxyTracker.className)

export interface TrackerContext {
    os: MobileOS
}

export type TrackerIdentifiers = Record<string, unknown>

/** Define any custom variables for trackers here.
 * This is commonly used for user or session-specific data.
 * You can set any amount of variables in one go.
 */
export type TrackedTraits = Partial<{
    user_id?: string
    phone: string
}>

export interface Experiment {
    'Experiment name': string
    'Variant name': string
}

interface TrackerMethods {
    clickButton(name: string, properties: ButtonTrackingProps): Promise<void>
    clickLink(key: string): Promise<void>
    click(
        type: TrackerClickType,
        name: string,
        properties?: Record<string, unknown>
    ): Promise<void>
    experimentsStarted(experiments: Experiment[]): Promise<void>
    familyJoined(familyId: string): Promise<void>
    formSubmitSuccess(
        name: CustomFieldsetId,
        properties?: Record<string, unknown>
    ): Promise<void>
    identify(traits: TrackedTraits): Promise<void>
    inputFocused(name: string): Promise<void>
    kycSuccess(): Promise<void>
    login(userId: string): Promise<void>
    modalShown(name: string): Promise<void>
    pageView(page: string, properties?: Record<string, unknown>): Promise<void>
    sendSMS(phoneNumber: string, customMessage?: string): Promise<void>
    linkShared(properties: ShareProps): Promise<void>
    snackbarShown(name: string): Promise<void>
    typeformSubmitSuccess(name: string): Promise<void>
    videoEnded(name: string): Promise<void>
    videoPaused(name: string, secondsElapsed?: number): Promise<void>
    videoPlayed(name: string): Promise<void>
}

export type TrackerClickType = 'button' | 'link' | 'faq'
