import type { SetRequired } from 'type-fest'

import { ThemeMode } from '../lib/utils/theme'
import type { Sys } from '../types/sys'
import type { AnnouncementFragment } from './fragments/AnnouncementFragment.graphql'
import type { ArticlePageFragment } from './fragments/ArticlePageFragment.graphql'
import type { CustomReferralInfoFragment } from './fragments/CustomReferralInfoFragment.graphql'
import type { FaqSectionFragment } from './fragments/FaqSectionFragment.graphql'
import type { FormPageFragment } from './fragments/FormPageFragment.graphql'
import type { InvestorSectionFragment } from './fragments/InvestorSectionFragment.graphql'
import type { ModalFragment } from './fragments/ModalFragment.graphql'
import type { PageMetaFragment } from './fragments/PageMetaFragment.graphql'
import type { ReceiptFragment } from './fragments/ReceiptFragment.graphql'
import type { ReviewSectionFragment } from './fragments/ReviewSectionFragment.graphql'
import type { SectionFragment } from './fragments/SectionFragment.graphql'
import type { SectionOfPressBannersFragment } from './fragments/SectionOfPressBannersFragment.graphql'
import type { SectionOfThumbnailsFragment } from './fragments/SectionOfThumbnailsFragment.graphql'
import type { StepsFragment } from './fragments/StepsFragment.graphql'
import type { SubmitFragment } from './fragments/SubmitFragment.graphql'
import type { TextFragment } from './fragments/TextFragment.graphql'
import type { ThumbnailFragment } from './fragments/ThumbnailFragment.graphql'
import type { PageFragment } from './queries/GetPage.graphql'

export const EMPTY_COLLECTION = { items: [] }

export const EMPTY_SYS: Sys = {
    id: '',
    publishedAt: new Date().toISOString(),
    publishedVersion: 1,
}

export const EMPTY_PAGE_META: PageMetaFragment = {
    sys: EMPTY_SYS,
    title: '',
    isIndexed: false,
}

export const EMPTY_SECTION: SectionFragment = {
    sys: EMPTY_SYS,
    type: 'Section',
    title: '',
    subtitle: '',
    image: { source: '', alt: '', sys: EMPTY_SYS },
}

export const EMPTY_SECTION_OF_THUMBNAILS: SectionOfThumbnailsFragment = {
    sys: EMPTY_SYS,
    type: 'SectionOfThumbnails',
    section: EMPTY_SECTION,
    thumbnails: EMPTY_COLLECTION,
}

export const EMPTY_SECTION_OF_STEPS: StepsFragment = {
    sys: EMPTY_SYS,
    type: 'Steps',
    section: EMPTY_SECTION,
    steps: EMPTY_COLLECTION,
}

export const EMPTY_SECTION_OF_REVIEWS: ReviewSectionFragment = {
    sys: EMPTY_SYS,
    type: 'SectionOfReviews',
    section: EMPTY_SECTION,
    reviews: EMPTY_COLLECTION,
}

export const EMPTY_SECTION_OF_FAQS: FaqSectionFragment = {
    sys: EMPTY_SYS,
    type: 'SectionOfFaQs',
    section: EMPTY_SECTION,
    faqs: EMPTY_COLLECTION,
}

export const EMPTY_SECTION_OF_INVESTORS: InvestorSectionFragment = {
    sys: EMPTY_SYS,
    type: 'SectionOfInvestors',
    section: EMPTY_SECTION,
    icons: EMPTY_COLLECTION,
}

export const EMPTY_SECTION_OF_PRESS_BANNERS: SectionOfPressBannersFragment = {
    sys: EMPTY_SYS,
    type: 'SectionOfPressBanners',
    icons: EMPTY_COLLECTION,
}

export const EMPTY_ANNOUNCEMENT: AnnouncementFragment = {
    sys: EMPTY_SYS,
    type: 'Announcement',
    section: EMPTY_SECTION,
}

export const EMPTY_PAGE: PageFragment = {
    banner: EMPTY_SECTION,
    sections: EMPTY_COLLECTION,
    mode: ThemeMode.Default,
    slug: '',
    sys: EMPTY_SYS,
}

export const EMPTY_ARTICLE_PAGE: ArticlePageFragment = {
    sys: EMPTY_SYS,
    type: 'Article',
    title: '',
    firstParagraph: '',
    subtitle: '',
    kind: 'Money 101',
    sections: EMPTY_COLLECTION,
    date: '',
}

export const EMPTY_SUBMIT: SubmitFragment = {
    sys: EMPTY_SYS,
    analyticsKey: '',
    buttonText: '',
}

export const EMPTY_FORM_PAGE: FormPageFragment = {
    sys: EMPTY_SYS,
    banner: { sys: EMPTY_SYS, title: '', subtitle: '' },
    sections: EMPTY_COLLECTION,
}

export const EMPTY_CUSTOM_REFERRAL_INFO: CustomReferralInfoFragment = {
    sys: EMPTY_SYS,
}

export const EMPTY_TEXT: SetRequired<TextFragment, 'content'> = {
    type: 'Text',
    sys: EMPTY_SYS,
    key: '',
    content: {
        links: {
            entries: { block: [] },
            assets: { block: [] },
        },
        json: {
            nodeType: 'document',
            data: {},
            content: [],
        },
    },
}

export const EMPTY_MODAL: SetRequired<ModalFragment, 'title' | 'primary'> = {
    sys: EMPTY_SYS,
    title: '',
    primary: '',
}

export const EMPTY_THUMBNAIL: ThumbnailFragment = {
    sys: EMPTY_SYS,
    type: 'Icon',
    title: '',
    subtitle: '',
    image: {
        sys: EMPTY_SYS,
        source: '',
        alt: '',
    },
    size: 'small',
}

export const EMPTY_ARTICLE_TOPIC = {
    sys: EMPTY_SYS,
    meta: EMPTY_PAGE_META,
    title: '',
    slug: '',
    linkedFrom: {
        articles: EMPTY_COLLECTION,
    },
    icon: {
        sys: EMPTY_SYS,
        source: '',
        alt: '',
    },
}

export const EMPTY_RECEIPT: SetRequired<ReceiptFragment, 'items'> = {
    sys: EMPTY_SYS,
    type: 'Receipt',
    items: EMPTY_COLLECTION,
}
