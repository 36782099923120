import type { ButtonTrackingProps } from '../../../components/button/props'
import type { ShareProps } from '../../../components/share-button/share-button'
import type { CustomFieldsetId } from '../../../graphql/hooks/form-page'
import { extendConsole } from '../../../shared/logging/console'
import type { MobileOS } from '../DeviceStore'
import type {
    TrackedTraits,
    TrackerClickType,
    TrackerContext,
} from './ProxyTracker'

export class DataLayerTracker {
    static className = 'DataLayerTracker'

    get className() {
        return DataLayerTracker.className
    }

    sendSMS(): void {
        return this.push({ event: 'sms_sent' })
    }

    pageView(page: string, properties?: Record<string, unknown>): void {
        return this.push({ event: 'quick_view', page, properties })
    }

    clickButton(
        name: string,
        properties: ButtonTrackingProps & TrackerContext
    ): void {
        const { os, placement = 'section', containerElementId } = properties

        return this.push({
            event: 'button_click',
            category: placement,
            action: name,
            properties: {
                os,
                container_element_id: containerElementId,
            },
        })
    }

    clickLink(name: string): void {
        return this.push({
            event: 'link_click',
            action: name,
        })
    }

    click(
        type: TrackerClickType,
        name: string,
        properties: Record<string, unknown> = {}
    ): void {
        return this.push({
            event: 'click',
            action: name,
            properties: {
                type,
                ...properties,
            },
        })
    }

    videoPlayed(name: string): void {
        return this.push({
            event: 'play',
            category: 'video',
            label: name,
        })
    }

    videoPaused(name: string, secondsElapsed?: number): void {
        return this.push({
            event: 'pause',
            category: 'video',
            action: secondsElapsed,
            label: name,
        })
    }

    videoEnded(name: string): void {
        return this.push({
            event: 'end',
            category: 'video',
            label: name,
        })
    }

    experimentsStarted() {
        // we don't track experiments in GTM
    }

    login(userId: string): void {
        this.identify({ user_id: userId })
    }

    familyJoined(familyId: string): void {
        this.push({ event: 'family_joined', label: familyId })
    }

    formSubmitSuccess(name: CustomFieldsetId): void {
        this.push({ event: 'form_submit', label: name })
    }

    kycSuccess(): void {
        this.push({ event: 'kyc_success' })
    }

    modalShown(name: string): void {
        this.push({ event: 'modal_shown', label: name })
    }

    snackbarShown(name: string): void {
        this.push({ event: 'snackbar_shown', label: name })
    }

    inputFocused(name: string): void {
        this.push({ event: 'input_focused', label: name })
    }

    linkShared(properties: ShareProps) {
        this.push({ event: 'share', properties })
    }

    async identify(traits: TrackedTraits = {}): Promise<void> {
        this.push(traits)
    }

    push(data: TrackedEvent | TrackedTraits): void {
        logger.debug('DataLayer', data)
        if (!window.dataLayer) {
            window.dataLayer = []
        }

        window.dataLayer.push(data)
    }

    typeformSubmitSuccess(name: string): void {
        this.push({ event: 'typeform_submit', label: name })
    }

    async setConsent(): Promise<void> {
        // no manual initialization needed, dataLayer is always available
    }
}

const logger = extendConsole(DataLayerTracker.className)

/**
 * These events will be propagated to any trackers defined within GTM.
 * We don't manage these trackers on the engineering side,
 * but can be asked to track some new event or add additional params.
 */
export type TrackedEvent =
    | { event: 'sms_sent' }
    | {
          event: 'play'
          category: 'video'
          label: string
      }
    | {
          event: 'pause'
          category: 'video'
          action?: number
          label: string
      }
    | {
          event: 'end'
          category: 'video'
          label: string
      }
    | {
          event: 'button_click'
          category: string
          action: string
          properties: {
              os: MobileOS
              container_element_id?: string
          }
      }
    | {
          event: 'link_click'
          action: string
      }
    | {
          event: 'click'
          action: string
          properties?: Record<string, unknown>
      }
    | {
          event: 'quick_view'
          page: string
          properties?: Record<string, unknown>
      }
    | { event: 'input_focused'; label: string }
    | { event: 'family_joined'; label: string }
    | { event: 'kyc_success' }
    | { event: 'modal_shown'; label: string }
    | { event: 'snackbar_shown'; label: string }
    | { event: 'form_submit'; label: string }
    | { event: 'qr_code_scanned'; label: string }
    | { event: 'share'; properties: ShareProps }
    | { event: 'typeform_submit'; label: string }
