import type { IncomingMessage } from 'http'
import { makeAutoObservable } from 'mobx'

import { isBrowser, isTests } from '../../shared/util/env'
import { AnalyticsStore } from './AnalyticsStore'
import type { CacheState } from './CacheStore'
import { CacheStore } from './CacheStore'
import type { DeviceStoreState } from './DeviceStore'
import { DeviceStore } from './DeviceStore'
import type { IsomorphicStore } from './IsomorphicStore'
import { LinkingStore } from './linking/LinkingStore'
import { LocalFeatureOverridesStorage } from './LocalFeatureOverrideStore'
import type { SdkStoreState } from './SdkClientStore'
import { SdkClientStore } from './SdkClientStore'
import { tracker } from './tracker/useTracker'

export class RootStore implements IsomorphicStore<RootStoreState> {
    isomorphicKeys = ['deviceStore', 'cacheStore', 'sdk'] as const
    linkingStore: LinkingStore

    private constructor(
        initialState: RootStoreState,
        readonly sdk: SdkClientStore,
        readonly analyticsStore: AnalyticsStore,
        readonly deviceStore: DeviceStore,
        readonly cacheStore: CacheStore,
        readonly featureOverrideStore: LocalFeatureOverridesStorage
    ) {
        this.linkingStore = new LinkingStore(deviceStore)
        if (initialState) this.restore(initialState)
        featureOverrideStore.load()
    }

    static fromState(state: RootStoreState) {
        const analyticsStore = AnalyticsStore.create()

        const store = new RootStore(
            state,
            SdkClientStore.create(),
            analyticsStore,
            DeviceStore.create(),
            CacheStore.create(),
            LocalFeatureOverridesStorage.create(analyticsStore)
        )

        tracker.setStore(store)

        return makeAutoObservable(store)
    }

    static fromRequest(request?: IncomingMessage, teamEnvironment?: string) {
        if (!request && !isBrowser() && !isTests()) {
            throw new Error(
                'To create RootStore during SSR, you have to pass in the request object'
            )
        }

        const analyticsStore = AnalyticsStore.create()

        const store = new RootStore(
            {},
            SdkClientStore.create(request, teamEnvironment),
            analyticsStore,
            DeviceStore.create(request),
            CacheStore.create(),
            LocalFeatureOverridesStorage.create(analyticsStore)
        )

        tracker.setStore(store)

        return makeAutoObservable(store)
    }

    restore(initialState: RootStoreState) {
        const keys = this.isomorphicKeys

        for (const key of keys) {
            const state = initialState[key]
            if (!state) continue
            this[key].restore(state as any)
        }
    }

    toJSON() {
        const keys = this.isomorphicKeys
        const result = {}

        for (const key of keys) {
            const state = this[key].toJSON()
            if (!state) continue
            result[key] = state
        }

        return result
    }
}

export interface RootStoreState {
    deviceStore?: DeviceStoreState
    cacheStore?: CacheState
    sdk?: SdkStoreState
}
