import { DataStore } from '@getstep/sdk/dist/store/common/DataStore'
import { makeLazyLoading } from '@getstep/sdk/dist/store/common/Observables'
import type { Analytics } from '@segment/analytics-next'
import capitalize from 'lodash/capitalize'
import isEqual from 'lodash/isEqual'

import type { ButtonTrackingProps } from '../../../components/button/props'
import type { ShareProps } from '../../../components/share-button/share-button'
import type { CustomFieldsetId } from '../../../graphql/hooks/form-page'
import { extendConsole } from '../../../shared/logging/console'
import { isPrd } from '../../../shared/util/env'
import type { AuthorizedStore } from '../authorized/AuthorizedStore'
import type { RootStore } from '../RootStore'
import type {
    Experiment,
    TrackedTraits,
    TrackerClickType,
    TrackerContext,
} from './ProxyTracker'
import { UserTraits } from './UserTraits'

const logger = extendConsole('SegmentTracker')

export class SegmentTracker extends DataStore {
    static className = 'SegmentTracker'

    userId: string | undefined

    traits: TrackedTraits = {}

    userTraits = new UserTraits()

    analytics?: Analytics

    consent?: boolean

    eventBuffer: BufferEvent[] = []

    store?: RootStore

    authorizedStore?: AuthorizedStore

    constructor() {
        super()
        makeLazyLoading(this)
    }

    get className() {
        return SegmentTracker.className
    }

    setStore(store: RootStore) {
        this.store = store
        this.userTraits?.setStore(store)
    }

    setAuthorizedStore(store: AuthorizedStore) {
        this.authorizedStore = store
        this.userTraits?.setAuthorizedStore(store)
    }

    pageView(page: string, properties?: Record<string, unknown>) {
        return this.page(page, properties)
    }

    sendSMS(phoneNumber: string, customMessage?: string) {
        return this.track('Sent app download link SMS.', {
            phone: phoneNumber,
            customMessage,
        })
    }

    clickButton(
        name: string,
        {
            os,
            placement,
            containerElementId,
            ...rest
        }: (ButtonTrackingProps & TrackerContext) | Record<string, unknown>
    ) {
        return this.track('Button Pressed', {
            name,
            os,
            placement,
            container_element_id: containerElementId,
            ...rest,
        })
    }

    clickLink(name: string) {
        return this.track('Link Clicked', {
            name,
        })
    }

    click(
        type: TrackerClickType,
        name: string,
        properties?: Record<string, unknown>
    ) {
        return this.track(`${capitalize(type)} Clicked`, {
            name,
            ...properties,
        })
    }

    async experimentsStarted(experiments: Experiment[]) {
        await Promise.all(
            experiments.map((experiment) =>
                this.track('$experiment_started', experiment as any)
            )
        )
    }

    videoPlayed(name: string) {
        return this.track('Video Played', { name })
    }

    videoPaused(name: string, secondsElapsed?: number) {
        return this.track('Video Paused', {
            name,
            seconds_elapsed: secondsElapsed,
        })
    }

    videoEnded(name: string) {
        return this.track('Video Ended', { name })
    }

    async login(userId: string) {
        const { analytics } = this

        if (!analytics) {
            logger.warn('called login() before analytics loaded')
            return
        }

        this.userId = userId
        await analytics.identify(userId)
        return this.track('Login')
    }

    familyJoined(familyId: string) {
        return this.track('Family Joined', { familyId })
    }

    formSubmitSuccess(
        name: CustomFieldsetId,
        properties?: Record<string, unknown>
    ) {
        return this.track('Successful Form Submit', { name, ...properties })
    }

    kycSuccess() {
        return this.track('KYC Success')
    }

    modalShown(name: string) {
        return this.track('Modal Shown', { name })
    }

    snackbarShown(name: string) {
        return this.track('Snackbar Shown', { name })
    }

    inputFocused(name: string) {
        return this.track('Input Focused', { name })
    }

    linkShared(properties: ShareProps) {
        return this.track('Link Shared', {
            share: {
                ...properties,
            },
        })
    }

    typeformSubmitSuccess(name: string) {
        return this.track('Successful Typeform Submit', { name })
    }

    async track(event: string, properties?: Record<string, unknown>) {
        const combinedProperties = {
            ...properties,
            ...this.userTraits?.traits,
        }

        if (!isPrd()) {
            logger.debug(`Track ${event}`, combinedProperties)
        }

        const { analytics, consent } = this

        if (!analytics || !consent) {
            logger.warn('called track() before analytics loaded')

            this.eventBuffer.push({
                type: 'track',
                event,
                properties: combinedProperties,
            })

            return
        }

        await analytics.track(event, combinedProperties)
    }

    async page(page: string, properties?: Record<string, unknown>) {
        const combinedProperties = {
            ...properties,
            ...this.userTraits?.traits,
        }

        logger.debug(`PageView: ${page}`, combinedProperties)

        const { analytics, consent } = this

        if (!analytics || !consent) {
            logger.warn('called page() before analytics loaded')

            this.eventBuffer.push({
                type: 'page',
                page,
                properties: combinedProperties,
            })

            return
        }

        await analytics.page(page, combinedProperties)
    }

    async identify(traits: TrackedTraits = {}): Promise<void> {
        if (!isPrd()) {
            logger.debug('Identify', { traits, ...this.userTraits?.traits })
        }

        const { analytics, consent } = this

        if (!analytics || !consent) {
            logger.warn('called identify() before analytics loaded')

            this.eventBuffer.push({
                type: 'identify',
                traits,
            })
            return
        }

        if (isEqual(traits, this.traits)) return

        const timeoutId = setTimeout(
            () => logger.warn('identification call timed out'),
            5_000
        )

        try {
            // https://segment-docs.netlify.app/docs/connections/spec/best-practices-identify/
            await analytics.identify(traits)
            this.traits = traits
            clearTimeout(timeoutId)
        } catch (e) {
            clearTimeout(timeoutId)
            logger.error(e)
        }
    }

    async setConsent(consent: boolean) {
        this.consent = consent

        if (!process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY) return

        // dont need to load analytics if consent is known false
        if (!consent) {
            this.analytics = undefined
            return
        }

        await this?.store?.analyticsStore.load(consent)

        this.analytics = this?.store?.analyticsStore.analytics

        this.replayBufferEvents()
    }

    protected async performLoad() {
        // do nothing
    }

    /**
     * Replay all events in the buffer
     * This is called when analytics is loaded and consent is set
     */
    private replayBufferEvents() {
        // just to be safe, check these again in case consent or analytics were changed
        if (!this.analytics || !this.consent) return

        // replay all events in the buffer
        this.eventBuffer.forEach((event) => {
            switch (event.type) {
                case 'page':
                    this.page(event.page!, event.properties)
                    break
                case 'track':
                    this.track(event.event!, event.properties)
                    break
                case 'identify':
                    this.identify(event.traits)
                    break
            }
        })

        // empty the buffer
        this.eventBuffer = []
    }
}

interface BufferEvent {
    type: 'page' | 'track' | 'identify'
    page?: string
    event?: string
    properties?: Record<string, unknown>
    traits?: TrackedTraits
}
