import { extendConsole } from '../../../shared/logging/console'
import type { AppLinker } from './AppLinker'
import { DEFAULT_PARAMETERS } from './parameters'

const logger = extendConsole('NullLinker')

/**
 * Linking stub for server-side rendering and adblocked browsers.
 */
class NullLinker implements AppLinker {
    name = 'None'

    async init() {
        logger.error("init call, either it's SSR or linker is blocked")
        return DEFAULT_PARAMETERS
    }

    async link(): Promise<string | void> {
        logger.error("link call, either it's SSR or linker is blocked")
    }

    async sendSMS() {
        return Promise.reject(
            new Error('Cannot send link SMS during SSR, or if blocked')
        )
    }
}

export const nullLinker = new NullLinker()
