import type { UnauthenticatedTransferRequest } from '@getstep/proto/transfer'
import { fetch } from 'cross-undici-fetch'
import type { JsonValue } from 'type-fest'

import { extendConsole } from '../shared/logging/console'

/** Creates a fetch function. That's it. */
export const makeFetch =
    (baseUrl: string, headers?: HeadersInit) =>
    async <T>(
        url: string,
        init: Omit<RequestInit, 'body'> & { body?: JsonValue } = {
            method: 'GET',
        }
    ): Promise<T> => {
        const location = baseUrl + url
        const logger = extendConsole(`fetch~${location}`)
        logger.debug(init?.method, init?.body)

        const response = await fetch(location, {
            ...init,
            headers: {
                'Content-Type': 'application/json',
                ...headers,
                ...init?.headers,
            },
            body: init?.body ? JSON.stringify(init.body) : undefined,
        })

        logger.debug(response.status, response.statusText)
        if (response.status === 200) {
            return response.json()
        }
        throw new FetchError(response.status, response.statusText)
    }

const fetchJSON = makeFetch('/api')

/**
 * Wrappers for local api routes from src/pages/api
 */
export const localApi = {
    // TODO (@cataline): replace this with PublicApi
    async createPaymentIntent(request: UnauthenticatedTransferRequest) {
        const r = await fetchJSON<{ secret: string }>(
            `/transfer/${request.id}/intent`,
            {
                method: 'POST',
            }
        )

        return typeof r?.secret === 'string'
            ? (r as { secret: string })
            : undefined
    },
}

export class FetchError extends Error {
    constructor(public status: number, public statusText: string) {
        super()
    }
}
