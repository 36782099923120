import { useSprings } from '@react-spring/web'
import type { LiteralUnion } from 'type-fest'

import {
    controlWidthMedium,
    controlWidthSmall,
} from '../../../styles/variables.module.scss'

// TODO: move the rest of magic numbers to dimens
const widthMedium = parseInt(controlWidthMedium) || 250
const widthSmall = parseInt(controlWidthSmall) || 180

const STYLES = {
    form: {
        medium: {
            focused: { width: 360 },
            idle: { width: widthMedium },
        },
        small: {
            focused: {},
            idle: {},
        },
    },
    button: {
        medium: {
            focused: { left: 260, width: 100 },
            idle: { left: 0, width: widthMedium },
        },
        small: {
            focused: { right: 0, width: 75 },
            idle: { right: 0, width: 100 },
        },
    },

    input: {
        medium: {
            focused: {
                width: widthMedium,
                opacity: 1,
                transform: 'scale(1)',
                transformOrigin: 'right',
            },
            idle: {
                opacity: 0,
                transform: 'scale(0)',
            },
        },
        small: {
            focused: {
                opacity: 1,
                transform: 'scale(1)',
                width: widthSmall,
                transformOrigin: 'center',
                right: 85,
            },
            idle: {
                opacity: 0,
                width: 0,
                right: 0,
                transform: 'scale(0)',
            },
        },
    },
}

export const useButtonRolloutAnimation = (
    status: 'focused' | 'idle',
    size: 'small' | 'medium',
    placement?: LiteralUnion<'footer' | 'section' | 'header' | 'banner', string>
) => {
    // override closed form width if placement is header
    const formValues =
        placement === 'header' && status === 'idle'
            ? {
                  ...STYLES.form[size][status],
                  width: 116,
              }
            : STYLES.form[size][status]

    const [inputStyles, buttonStyles, formStyles] = useSprings(3, [
        {
            ...STYLES.input[size][status],
            config: { tension: 170, friction: 26 },
        },
        {
            ...STYLES.button[size][status],
            config: { tension: 210, friction: 20 },
        },
        {
            ...formValues,
            config: { tension: 200, friction: 20 },
        },
    ])

    return { inputStyles, buttonStyles, formStyles }
}
