import { ErrorCode } from '@getstep/sdk/dist/http/HttpError'
import lodashTemplate from 'lodash/template'
import Typograf from 'typograf'

import { extendConsole } from '../../shared/logging/console'

const logging = extendConsole('text')

const typograf = new Typograf({ locale: ['en-US'] })

typograf.disableRule('common/html/*')
typograf.enableRule('common/nbsp/*')
typograf.enableRule('common/space/delRepeatSpace')
typograf.enableRule('en-US/dash/main')

/** Fixes common micro-typography issues to increase text readability.
 * Most rules are language-specific so only most basic
 * and universal rules are supported. For now. */
export const microtypograph = (text: string): string => typograf.execute(text)

// TODO: I18n, CMS
export const TEXT = {
    defaultTitle: 'Build Credit & Get Rewards - Step',
    defaultDescription:
        'Step offers a free FDIC insured bank account and Visa card designed for the next generation.',
    defaultOpenGraphTitle: 'Build Credit & Get Rewards - Step',
    defaultOpenGraphDescription:
        'No monthly or subscription fees, no overdraft fees, no interest, and the best way to build credit even before you turn 18.',
    footer: {
        legal: 'The Step Card is issued by Evolve Bank & Trust, Member FDIC. Pursuant to license by Visa.',
        copyright: `©️ 2020 - ${new Date().getFullYear()} Step Mobile, Inc. All rights reserved.`,
        disclaimer:
            'Visa’s Zero Liability policy does not apply to certain commercial card and anonymous prepaid card transactions or transactions not processed by Visa. Cardholders must use care in protecting their card and notify their issuing financial institution immediately of any unauthorized use. Contact your issuer for more detail.',
        investingDisclaimer:
            'Investment advisory services offered through the app will be provided Step Advisers, LLC (“Step Advisers”). To receive advisory services as a client of Step Advisers, you must be 18 or older. Clients of Step Advisers may enable teens under 18 to effect securities transactions on their behalf, subject to a written trading authorization and the clients’ monitoring and control.',
        creditDisclaimer:
            'Positive history is reported on and related only to your Step Visa card activity, subject to your account remaining in good standing. On-time payment history can have a positive impact on your credit score, and late payment history may negatively impact your credit score. The Step Visa Card, when set up with SmartPay, is designed to keep accounts in good standing to help build positive credit history. Step will report your activity to Transunion®, Experian®, and/or Equifax®. Even when we report positive credit history relating to your Step Visa card activity, your credit history and score may be impacted by your activity outside of your Step Visa card (including any other products offered through Step and any activity external to Step), as credit histories and credit scores are built by credit bureaus based on a number of factors, including the financial decisions you make with products outside of the Step Visa Card.',
        defaultDisclaimer:
            'Step is a financial technology company, not a bank. Banking services provided by Evolve Bank & Trust, Member FDIC. The Step Visa Card is issued by Evolve Bank & Trust pursuant to a license from Visa U.S.A., Inc. Visa is a registered trademark of Visa International Service Association.',
    },
    yourEmail: 'your email',
    careers: 'Careers',
    required: 'Required',
    expiry: 'Expiry',
    cvc: 'CVC',
    lastUpdated: 'Last Updated',
    effective: 'Effective',
    noComment: '',
    invitedYouToJoinStep: 'Invited you to join Step',
    joinStep: 'Join Step',
    // TODO(jamesm): Revert after campaign ends
    getUSDWhenYouSignUp:
        'Get {{referredAwardFormatted}} when you sign up and set up direct deposit',
    learnMore: 'Learn more',
    learnMoreAboutStep: 'learn more about Step',
    downloadStep: 'Download Step',
    getStep: 'Get Step',
    getStarted: 'Get Started',
    openRoles: 'Open roles',
    remindMeLater: 'Remind Me Later',
    continue: 'Continue',
    cancel: 'Cancel',
    close: 'Close',
    next: 'Next',
    skip: 'Skip',
    help: 'Help',
    edit: 'Edit',
    money: 'money',
    money101: 'Money 101',
    press: 'Press',
    relatedArticles: 'Related Articles',
    relatedVideos: 'Related Videos',
    someone: 'someone',
    yourTeen: 'your teen',
    yourParent: 'your parent',
    yourPhone: 'your phone',
    carousel: {
        nextSlide: 'Next slide',
        previousSlide: 'Previous slide',
    },
    createAccount: 'Create An Account',
    approve: 'Approve {{name}}',
    phoneNumber: 'Phone Number',
    resendCode: 'Resend code',
    emailVerified: 'Email has been verified.',
    oneToThreeBusinessDays: '1-3 business days',
    after7Days: 'after 7 days',
    amount: 'Amount',
    to: 'To',
    from: 'From',
    when: 'When',
    for: 'For',
    them: 'them',
    or: 'or',
    expiresAt: 'This request expires {{expiresAt}}',
    expired:
        'Sorry, this request no longer exists.\nYou can still send money on Step.',
    sendMoneyOnStep: 'Send Money on Step',
    payOnStep: 'Pay on Step',
    payUserWithCard: 'Pay {{displayName}} with Card',
    claimMoneyOnStep: 'Claim {{amountFormatted}} on Step',
    payMoneyOnStep: 'Pay {{amountFormatted}} on Step',
    requestedMoneyOnStep: 'Requested money on Step',
    sentMoneyOnStep: 'Sent you money on Step',
    joinedAt: '#StepFam since {{date}}',
    scanToPay: 'Scan to pay',
    pay: 'Pay',
    policies: {
        pagetitle: 'Policies',
        sidebarTitle: 'Legal / Policies',
    },
    tableOfContents: {
        title: 'Contents',
    },
    enterCardInfo: 'Enter card information',
    zipCodeRequired: 'Zip code is required',
    pleaseSelect: 'Please select',
    pleaseSelectDebitCard: 'Please select a debit card only',
    onlyDebitCard: 'Only debit cards are accepted',
    onlyCreditAndDebit: 'Only credit, debit and prepaid cards are accepted',
    cardMask: {
        debit: 'Debit Card •••• {{last4}}',
        credit: 'Credit Card •••• {{last4}}',
        prepaid: 'Prepaid •••• {{last4}}',
        other: 'Card •••• {{last4}}',
    },
    wallets: {
        applePay: 'Apple Pay',
        googlePay: 'Google Pay',
        link: 'Link',
    },
    cardNumber: 'Card number',
    oops: 'Oops, something went wrong. Please try again.',
    missingCard: 'There is an isse with your card. Please try again.',
    missingSponsor: 'Please login in again to complete this action.',
    payWithCard: 'Pay with Card',
    creditCardFee: 'There is a fee to pay with a credit card',
    paymentDescriptor: 'Payment to {{name}}',
    creditCardFeeDescriptor: 'Processing Fee (Credit Card Only)',
    // TODO (@cataline): copy
    paymentError: 'There was an error while processing your transaction',
    linkCopied: 'Link copied!',
    textMeAppLink: {
        sent: 'Sent!',
        resend: 'Resend',
        send: 'Send',
        getStarted: 'Get Started',
        success: 'We sent a link to your phone.',
        why: 'We’ll text you a link to download Step',
    },
    error: 'Oh no, something went wrong!',
    sso: {
        button: {
            google: 'Sign in with Google',
            apple: 'Sign in with Apple',
            email: 'Verify Your Email Manually',
        },
        invalidCode: 'Invalid or expired code',
        unknownAccount:
            'You need to have a Step Account before you can login. Download the Step App by scanning the QR code below to create one.',
        unknownAccountMobile:
            'You need to have a Step Account before you can login. Download the Step App by clicking the Get Started button to create one.',
    },
    requestedSponsorship: {
        subtitle: 'Requested sponsorship on Step',
        schoolBanner: {
            families: ' families from ',
            helping:
                ' are helping their teens get ahead on their financial journey with Step.',
        },
    },
    stepDescription: 'Step is a bank account and Visa card designed for teens',
    doNotSellMyPersonalInformation: 'Do Not Sell My Personal Information',
    addNote: 'Add a note',
    fundingAmountError: 'Please enter an amount between ${{min}} and ${{max}}',
    kycAlreadyPerformed: 'Oops, your identity is already verified.',
    kycSuccessFunding:
        'Your account has been verified! 🎉 Now get {{firstName}} started with some money!',
    kycInvalid:
        'Sorry, we are unable to verify some information. Please make sure you provide the correct information.',
    kycNeedMoreInformation: 'We need more information to verify your identity.',
    kycFullSsn: 'Full Social Security Number',
    kycPictureOfGovId: 'A picture of your government ID',
    asSeenOn: 'As seen on',
    showFaqs: 'Show FAQs',
    hideFaqs: 'Hide FAQs',
    transfers: {
        progress: {
            message1: 'Your transfer is being protected with 3D Secure.',
            message2: 'Connecting to your bank...',
            message3: 'Verifying details with your bank...',
            message4: 'Double-checking transfer details...',
            message5: "Hang on tight! We're almost done...",
            needMoreInforamtion: 'We need some more information',
            success:
                'Your transfer was successfully authenticated. Your money should be available shortly.',
        },
    },
    waitlist: {
        closed: 'We are not currently accepting new waitlist signups. Please check back later.',
        title: 'Sign up for the waitlist',
        join: 'Join the Waitlist',
        success: 'Success! You’ve been added to the waitlist!',
        successSnackbar: 'Success! You’re on the waitlist! 🎉',
        error: 'Oops, something went wrong. Please try again.',
        notFound: 'Oops, this waitlist is not currently available.',
        rateLimited: 'Please wait a few minutes before trying again.',
        STEP_BLACK: {
            heroTitle: 'Introducing The Step Black Card',
            shareMessage:
                'I just joined the waitlist for Step Black, you should too!',
            shareTitle: 'Join the waitlist for Step Black',
        },
    },
    share: 'Share',
    shareThisPage: 'Share this page',
    tapHere: 'Tap here',
    alreadyHaveStep: 'Already have Step?',
    httpErrors: {
        [ErrorCode.EXCEEDS_AMOUNT_LIMIT]:
            'The amount exceeds the limit. Please try a smaller amount.',
        [ErrorCode.UNSUPPORTED_CARD]: 'We do not support this type of card.',
        [ErrorCode.UNSUPPORTED_COUNTRY]: 'Non-US cards are not supported.',
    },
    schedule: {
        day: {
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
        },
        frequency: {
            weekly: 'Weekly',
            monthly: 'Monthly',
            monthlyTwice: 'Twice a month',
        },
        interval: {
            firstOfTheMonth: {
                short: '1st',
                long: '1st of the month',
            },
            firstAndFifteenth: {
                short: '1st & 15th',
                long: '1st and 15th of the month',
            },
            fifteenthOfTheMonth: {
                short: '15th',
                long: '15th of the month',
            },
            fifteenthAndLast: {
                short: '15th & last',
                long: '15th and last day of the month',
            },
            lastOfTheMonth: {
                short: 'Last day',
                long: 'Last day of the month',
            },
        },
    },
    upcomingPayments: 'Upcoming Payments',
    mostParentsChooseAmount: 'Most parents choose ${{amount}}',
    other: 'Other',
    otherAmount: 'Other amount',
    frequentlyAskedQuestions: 'Frequently Asked Questions',
    faqTopics: 'FAQ Topics',
    faqSubTitle:
        'Whether you are a teen, a sponsor, or an adult on your own, we have answers to your questions.',
    savingsCalculator: {
        principal: {
            label: 'How much do you already have in savings?',
            requiredError: 'Please enter a valid starting savings amount',
        },
        monthlyContribution: {
            label: 'How much are you planning to save each month?',
            requiredError: 'Please enter a valid monthly contribution amount',
        },
        months: {
            label: 'How long do you plan to save for?',
            requiredError: 'Please enter a valid duration',
        },
        interestRate: {
            label: 'Annual Savings Rate %',
            requiredError: 'Please enter a valid interest rate',
        },
        submit: 'Show me the money',
        results: {
            amountYouContribute: 'Amount you contribute',
            amountYouEarn: 'Amount you earn',
            totalEstimatedSavings: 'Total estimated savings',
        },
        cta: {
            heading: 'Step up your savings game',
        },
        disclaimer:
            'Estimated amount earned based on variable annual percentage yield basis of your savings balances.',
    },
} as const

/**
 * Returns true if the string does not contain {{ }} template literals.
 */
export function isFilled(source: string) {
    return !/\{\{.*\}\}/.test(source)
}

export type TemplateParams = Record<string, any>

export function renderTemplate<D extends TemplateParams>(
    raw = '',
    data: D
): string {
    if (!raw) return ''

    try {
        const executor = lodashTemplate(raw.replace('\\n', '\n'), {
            interpolate: /{{([\s\S]+?)}}/g,
        })

        return executor(data)
    } catch (e) {
        if (e instanceof Error) {
            logging.debug(`Error rendering template: ${e.message}`)
        }

        return ''
    }
}

/**
 * strip out all non-alphanumeric characters
 * @param string
 * @returns {string}
 */
export const stripNonAlphaNumeric = (str: string) => {
    if (!str) return ''
    return str.replace(/[^a-zA-Z0-9 ]/g, '')
}

/**
 * strip out HTML tags
 * @param string
 * @returns {string}
 */
export const stripHTML = (str: string) => str.replace(/<?[^<>]*>|<[^>]*/gim, '')

export const sanitizeText = (str: string) =>
    stripNonAlphaNumeric(stripHTML(str.trim()))
