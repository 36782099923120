import type { Feature } from '@getstep/proto/feature'
import { DataStore } from '@getstep/sdk/dist/store/common/DataStore'
import { computed, makeObservable } from 'mobx'

import type { RootStore } from '../../store/RootStore'
import type { AuthorizedStore } from '../authorized/AuthorizedStore'

/**
 * Generate user traits for various stores (e.g. feature flags, family info, etc...)
 */
export class UserTraits extends DataStore {
    static readonly className = 'UserTraits'

    private store?: RootStore
    private authorizedStore?: AuthorizedStore
    private appVersion = ''

    constructor() {
        super()

        makeObservable(this, {
            traits: computed,
            features: computed,
        })

        void this.load()
    }

    get traits(): Record<string, unknown> {
        return { ...this.features, appVersion: this.appVersion }
    }

    get features(): Record<string, unknown> {
        // If store is not loaded, fallback to local feature overrides.
        if (!this.authorizedStore?.featureStore.loaded) {
            const entries = Object.entries(
                this.store?.featureOverrideStore?.featureOverrides ?? {}
            )
            const enabledFeatures = entries
                .filter((value) => value[1])
                .map((value) => value[0])
            return {
                features_enabled: enabledFeatures,
            }
        }

        const {
            featureStore: { enabledFeatures, enabledVariants },
        } = this.authorizedStore

        // Map variants
        const variantAssignment: Partial<Record<Feature, string>> = {}
        for (const variant of enabledVariants.values()) {
            variantAssignment[variant.feature] = variant.name
        }

        // Return the traits
        return {
            features_enabled: enabledFeatures,
            variants_enabled: variantAssignment,
        }
    }

    setStore(store: RootStore) {
        this.store = store
        void this.refresh()
    }

    setAuthorizedStore(stores: AuthorizedStore) {
        this.authorizedStore = stores
        void this.refresh()
    }

    async performLoad() {
        this.appVersion = process.env.npm_package_version ?? ''
    }
}
