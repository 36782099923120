import type { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { captureException } from '@sentry/nextjs'
import produce from 'immer'
import merge from 'lodash/merge'
import { useContext } from 'react'
import type { OperationResult, UseQueryArgs, UseQueryState } from 'urql'
import { Context, useQuery } from 'urql'

import { BadRequestError, ServerError } from '../lib/errors/PageError'
import { omit } from '../lib/utils/omit'
import type { ContentfulClient } from './client'

export async function requestQuery<D, V>(
    client: ContentfulClient,
    staticOpts: {
        query: TypedDocumentNode<D, V>
        variables?: V
    }
) {
    const result = await client
        .query(staticOpts.query, merge({}, staticOpts.variables))
        .toPromise()

    handleErrors(result, client)

    return { ...result, data: omitNullValues(result.data) }
}

/**
 * Creates a new query function with some preconfigured default options.
 * Do not use extends in the type parameters, as it will break the type checker :/
 */
export function makeQueryFunction<D, V>(staticOpts: {
    query: TypedDocumentNode<D, V>
    variables?: V
}) {
    return async function fetchQuery(
        client: ContentfulClient,
        opts?: { variables: V }
    ) {
        const result = await client
            .query(
                staticOpts.query,
                merge({}, staticOpts.variables, opts?.variables)
            )
            .toPromise()

        handleErrors(result, client)

        return { ...result, data: omitNullValues(result.data) as D }
    }
}

/**
 * Creates a new useQuery hook with some preconfigured default options.
 * Do not use extends in the type parameters, as it will break the type checker :/
 */
export function makeUseQueryHook<D, V>(
    staticOpts?: Omit<UseQueryArgs<V, D>, 'query'> & {
        query: TypedDocumentNode<D, V>
    }
) {
    return function useQueryInner(opts?: Omit<UseQueryArgs<V, D>, 'query'>) {
        const client = useContext(Context) as ContentfulClient
        const [result] = useQuery<D, V>(
            merge({}, staticOpts, opts) as UseQueryArgs<V, D>
        )

        handleErrors(result, client)
        return { ...result, data: omitNullValues(result.data) as D }
    }
}

function handleErrors<D, V>(
    result: UseQueryState<D, V> | OperationResult<D>,
    client: ContentfulClient
) {
    if (!result.error) return

    const { graphQLErrors, networkError, name, message, response } =
        result.error

    if (networkError) {
        captureException(result.error, response)
        client.reset(result.error)
        throw new ServerError(name, message)
    }

    if (graphQLErrors.length > 0) {
        const body = `GraphQL Errors: ${JSON.stringify(graphQLErrors, null, 4)}`
        client.reset(body)
        throw new BadRequestError(body)
    }

    captureException(result.error)
}

function omitNullValues<T>(data: T): T {
    if (!data) return data
    return produce(data, (draft) => {
        // omit null/undefined values from tree,
        // making it easier to work with in React
        omit(draft, (value) => value == null)
    })
}
