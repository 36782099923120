import { ThemeOptions } from './withTheme'

export const THEME_COLORS = [
    'theme-background',
    'theme-border',
    'theme-box-shadow',
    'theme-input-border',
    'theme-input-focus-border',
    'theme-input-text',
    'theme-inverse-background',
    'theme-inverse-text',
    'theme-negative',
    'theme-positive',
    'theme-progress',
    'theme-card-backdrop',
    'theme-card-background',
    'theme-secondary-background',
    'theme-secondary-text',
    'theme-skeleton-shimmer',
    'theme-skeleton',
    'theme-text',
    'theme-warning',
    'theme-primary-down',
    'theme-primary',
    'theme-tap-highlight',
] as const

export enum ThemeMode {
    Default = 'default',
    Black = 'black',
}

export type ThemeColor = (typeof THEME_COLORS)[number]

// get the correct alternating theme for the thumbnail
export const getAlternatingTheme = (
    index: number,
    order = ['cyanora', 'future-fuscia', 'coal-mine-canary']
) => {
    const colors = [
        {
            key: ThemeOptions[order[0]],
        },
        {
            key: ThemeOptions[order[1]],
        },
        {
            key: ThemeOptions[order[2]],
        },
    ]
    return colors[index % colors.length]
}
