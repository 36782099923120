export const PHONE_FORMAT = '(###) ###-####'
export const COUNTRY_CODE = 'US'
export const COUNTRY_PREFIX = '+1'

/**
 * Uses libphonenumber-js to validate phone.
 */
export const validateTelephoneInput = async (
    text: string
): Promise<boolean> => {
    const { parsePhoneNumberFromString } = await import(
        'libphonenumber-js/mobile'
    )

    return !!parsePhoneNumberFromString(text, COUNTRY_CODE)?.isValid()
}

/**
 * Uses libphonenumber-js to format phone to E164.
 */
export const telephoneToE164 = async (
    text: string
): Promise<string | undefined> => {
    const { parsePhoneNumberFromString } = await import(
        'libphonenumber-js/mobile'
    )

    return parsePhoneNumberFromString(text, COUNTRY_CODE)?.format('E.164')
}

/**
 * Uses libphonenumber-js to format phone to E164 and strips to digits only.
 */
export const digitsOnly = async (text: string): Promise<string | undefined> => {
    const formatted = await telephoneToE164(text)
    return formatted?.replace(/\D/, '') || ''
}
